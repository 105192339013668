import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generateClient } from "aws-amplify/api"
import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBIcon, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"

export default function AllVideoProgress() {
    const { t, i18n } = useTranslation()
    const client = generateClient()
    const [isLoading, setIsLoading] = useState(true)
    const [allVideoProgress, setAllVideoProgress] = useState([])

    const fetchAllVideoProgress = async () => {
        setIsLoading(true)
        let allVideoProgresses = [];
        let nextToken = null;
       
        try {
            do {
                const { data, nextToken: newNextToken } = await client.graphql({ 
                    query: /* GraphQL */ `
                    query ListVideoProgresses(
                      $filter: ModelVideoProgressFilterInput
                      $limit: Int
                      $nextToken: String
                    ) {
                      listVideoProgresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                          id
                          userId
                          user {
                            id
                            title
                            firstName
                            lastName
                            image {
                              fileName
                            }
                            contact {
                                address {
                                    company
                                }
                            }
                          }
                          lecture {
                            id
                            title {
                                language
                                text
                            }
                            company {
                                name
                            }
                          }
                          videoId
                          progressInSeconds
                          totalWatchedInSeconds
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                      }
                    }
                  `,
                    variables: { nextToken }
                })
                allVideoProgresses = allVideoProgresses.concat(data.listVideoProgresses.items)
                nextToken = newNextToken
            } while (nextToken)
            setAllVideoProgress(allVideoProgresses)
            
        }
        catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchAllVideoProgress()
    }, [])

  return (
<>
<MDBCard>
      <MDBCardBody>
          <MDBCardTitle>
              <h2>{t("Video Views")}</h2>
          </MDBCardTitle>
          {/* Temp info on total tickets, total tickets each day (23.04.24, 24.04.24, 25.04.24) */}
          <MDBCardText>
          <h5>{t("Total Views")}: {allVideoProgress.length}</h5>
          
          </MDBCardText>
            

          <MDBTable>
              <MDBTableHead>
                  <tr>
                  <th>{t("Video")}</th>
                      <th>{t("User")}</th>
                      <th>{t("Watched Length")}</th>
                  </tr>
              </MDBTableHead>
              <MDBTableBody>
                    {allVideoProgress.map((videoProgress) => (
                        <tr key={videoProgress.id}>
                            <td>
                                {videoProgress.lecture?.title.find(t => t.language === i18n.language)?.text || videoProgress.lecture?.title[0].text}
                                <br />
                                <small>{videoProgress.lecture?.company?.name}</small>
                                </td>
                            <td>
                            <div className='d-flex align-items-center'>
                                {videoProgress.user?.image?.fileName ?
                                    <img
                                        src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+videoProgress.user.image.fileName}
                                        className="rounded-circle border"
                                        style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                        alt={videoProgress.user.firstName + " " + videoProgress.user.lastName}
                                    />
                                :
                                    <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user" />
                                            
                                }
                                <div className='ms-3'>
                                <p className='fw-bold mb-1'>{(videoProgress.user?.title ? videoProgress.user.title + " " : "") + videoProgress.user?.firstName + " " + videoProgress.user?.lastName}</p>
                                <p className='text-muted mb-0'>{videoProgress.user?.contact?.address?.company || ""}</p>
                                </div>
                                </div>
                            </td>
                            <td>{videoProgress.totalWatchedInSeconds} {t("Seconds")}</td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
        </MDBCardBody>
    </MDBCard>
</>
  )
}
