import React, { useState, useEffect, useCallback } from "react";

import { diff } from 'deep-object-diff';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBChip, MDBTextArea,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
  MDBTabsPane
   } from "mdb-react-ui-kit";
import { updateCompany } from "../../graphql/mutations";
import { useTranslation } from "react-i18next";
import adjustChanges from "../../utils/adjustDiffChanges";

import TabGeneral from "../../components-user-admin/manage-company/General";
import TabBilling from "../../components-user-admin/manage-company/Billing";

import TabLectures from "../../pages-user/event-management/lectures/AllLectures";
import TabPromotions from "../../pages-user/event-management/actions/AllLectures";
import TabNews from "../../pages-user/news/AllNews";
import TabProducts from "../../pages-user/products/AllProducts";
import TabRecruiting from "../../pages-user/recruiting/AllRecruitingLectures";
import TabEvents from "../../components-dev-admin/manage-company/CompanyEvents";
import TabEventGuests from "../../pages-user/event-management/EventGuests";


import TabPersons from "../../components-user-admin/manage-company/Persons";
import TabDepartments from "../../components-user-admin/manage-company/Departments";
import TabImages from "../../components-user-admin/manage-company/Images";

import { fullCompanyQuery, fullCompanyMutation } from "../../graphql-custom/companyQueryies";

import { generateClient } from 'aws-amplify/api';
const client = generateClient();
export default function EditCompany() {
    const { t, i18n } = useTranslation();

    const companyid = window.location.pathname.includes("mycompany") ? window.location.pathname.split("/")[2] : window.location.pathname.split("/")[3];

    // Initial data
    const [initialCompany, setInitialCompany] = useState({});
    
    const [loading, setLoading] = useState(true);

    // Get data
    const getCompany = async () => {
        try {
            const company = await client.graphql({
                variables: { id: companyid },
                query: fullCompanyQuery
            });
            console.log("company",company);
            return company.data.getCompany;
        } catch (error) {
            console.warn("error fetching company", error);
        }
    }

    
    // Changes and form handling
    const [company, setCompany] = useState({});
    const [changes, setChanges] = useState({}); // use object diff 

    const handleChange = (e) => {
        // Get values from input
        const datapath = e.target.attributes.datapath.value;
        const value = e.target.value;
        // Update company object
        let newObject = {...company}
        const deepUpdateObject = (object, path, value) => path.split('.').reduce((o,p,i) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object)
        deepUpdateObject(newObject, datapath, value)
        setCompany(newObject);
        setChanges(diff(initialCompany, newObject));
    }

    // Update company
    const updateCompany = async () => {
        try {
            const res = await client.graphql({
                variables: { input: adjustChanges(changes, company) },
                query: updateCompany
            });
            console.log("res",res);
        } catch (error) {
            console.warn("error fetching company", error);
        }
    }

    useEffect(() => {
        if(!companyid) return;
        setLoading(true);
        getCompany().then(company => {
            setInitialCompany(company);
            setCompany(company);
            setLoading(false);
        });
    }, []);

    const [basicActive, setBasicActive] = useState('tab_general');

    const handleBasicClick = (value) => {
        if (value === basicActive) {
        return;
        }

        setBasicActive(value);
    };

  return (
<>
<MDBTabs fill className='mb-3'>
    <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('tab_general')} active={basicActive === 'tab_general'}>
    <MDBIcon fas icon='sliders-h' className='me-2' /> {t("Company Profile")}
    </MDBTabsLink>
    </MDBTabsItem>
    <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('tab_billing')} active={basicActive === 'tab_billing'}>
    <MDBIcon fas icon='file-invoice-dollar' className='me-2' /> {t("Billing Settings")}
    </MDBTabsLink>
    </MDBTabsItem>
    <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('tab_employees')} active={basicActive === 'tab_employees'}>
    <MDBIcon fas icon='users' className='me-2' /> {t("Employees")}
    </MDBTabsLink>
    </MDBTabsItem>
    <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('tab_lectures')} active={basicActive === 'tab_lectures'}>
    <MDBIcon fas icon='chalkboard-teacher' className='me-2' /> {t("Lectures")}
    </MDBTabsLink>
    </MDBTabsItem>
    <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('tab_promotions')} active={basicActive === 'tab_promotions'}>
    <MDBIcon fas icon='chalkboard-teacher' className='me-2' /> {t("Promotions")}
    </MDBTabsLink>
    </MDBTabsItem>
    <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('tab_news')} active={basicActive === 'tab_news'}>
    <MDBIcon fas icon='newspaper' className='me-2' /> {t("News")}
    </MDBTabsLink>
    </MDBTabsItem>
    <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('tab_products')} active={basicActive === 'tab_products'}>
    <MDBIcon fab icon='product-hunt' className='me-2' /> {t("Products")}
    </MDBTabsLink>
    </MDBTabsItem>
    <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('tab_recruiting')} active={basicActive === 'tab_recruiting'}>
    <MDBIcon fas icon='user-tie' className='me-2' /> {t("Recruiting")}
    </MDBTabsLink>
    </MDBTabsItem>
    <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('tab_events')} active={basicActive === 'tab_events'}>
    <MDBIcon fas icon='calendar-day' className='me-2' /> {t("Events")}
    </MDBTabsLink>
    </MDBTabsItem>
    <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('tab_event_guests')} active={basicActive === 'tab_event_guests'}>
    <MDBIcon fas icon='calendar-day' className='me-2' /> {t("Event Guests")}
    </MDBTabsLink>
    </MDBTabsItem>
    {/* <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('tab_departments')} active={basicActive === 'tab_departments'}>
    <MDBIcon fas icon='building' className='me-2' /> {t("Departments")}
    </MDBTabsLink>
    </MDBTabsItem> */}
   
    {/* <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('tab_images')} active={basicActive === 'tab_images'}>
    <MDBIcon fas icon='images' className='me-2' /> {t("Images")}
    </MDBTabsLink>
    </MDBTabsItem> */}
</MDBTabs>
<MDBTabsContent>
    <MDBTabsPane open={basicActive === 'tab_general'}><TabGeneral company={company} handleChange={handleChange} loading={loading} tabActive={basicActive === 'tab_general'} adminActions={true} /></MDBTabsPane>
    <MDBTabsPane open={basicActive === 'tab_billing'}><TabBilling company={company} handleChange={handleChange} loading={loading} tabActive={basicActive === 'tab_billing'} adminActions={true} /></MDBTabsPane>
    <MDBTabsPane open={basicActive === 'tab_employees'}><TabPersons company={company} handleChange={handleChange} loading={loading} tabActive={basicActive === 'tab_persons'} adminActions={true} /></MDBTabsPane>
    <MDBTabsPane open={basicActive === 'tab_lectures'}><TabLectures company={company} handleChange={handleChange} loading={loading} tabActive={basicActive === 'tab_lectures'} adminActions={true} /></MDBTabsPane>
    <MDBTabsPane open={basicActive === 'tab_promotions'}><TabPromotions company={company} handleChange={handleChange} loading={loading} tabActive={basicActive === 'tab_promotions'} adminActions={true} /></MDBTabsPane>
    <MDBTabsPane open={basicActive === 'tab_news'}><TabNews company={company} handleChange={handleChange} loading={loading} tabActive={basicActive === 'tab_news'} adminActions={true} /></MDBTabsPane>
    <MDBTabsPane open={basicActive === 'tab_products'}><TabProducts company={company} handleChange={handleChange} loading={loading} tabActive={basicActive === 'tab_products'} adminActions={true} /></MDBTabsPane>
    <MDBTabsPane open={basicActive === 'tab_recruiting'}><TabRecruiting company={company} handleChange={handleChange} loading={loading} tabActive={basicActive === 'tab_recruiting'} adminActions={true} /></MDBTabsPane>
    <MDBTabsPane open={basicActive === 'tab_event_guests'}><div className="mt-5"><TabEventGuests company={company} handleChange={handleChange} loading={loading} tabActive={basicActive === 'tab_event_guests'} /></div></MDBTabsPane>
    <MDBTabsPane open={basicActive === 'tab_events'}>
    <MDBCol size="12" xl="4" className="mx-auto mt-xl-5">
    <TabEvents companyId={company.id} handleChange={handleChange} loading={loading} tabActive={basicActive === 'tab_events'} adminActions={true} />
    </MDBCol>
    
    </MDBTabsPane>

    {/* <MDBTabsPane open={basicActive === 'tab_images'}><TabImages company={company} handleChange={handleChange} loading={loading} tabActive={basicActive === 'tab_images'} /></MDBTabsPane>
    <MDBTabsPane open={basicActive === 'tab_departments'}><TabDepartments company={company} handleChange={handleChange} loading={loading} tabActive={basicActive === 'tab_departments'} /></MDBTabsPane>
     */}
</MDBTabsContent>
</>
   
  )
}
