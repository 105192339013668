
import React, { useEffect, useState } from 'react';
import EmployeeSelector from "../../components-user/news/EmployeeSelector";
import { MDBInput, MDBCheckbox, MDBBtn, MDBRow, MDBCol, MDBTypography, MDBInputGroup, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBChip, MDBCard, MDBCardBody, MDBCardTitle, MDBFile, MDBSpinner, MDBIcon, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBSelect, MDBTextArea, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertFromHTML} from 'draft-js';
import {convertHTMlToRaw} from "../../utils/HTMLDraft";
import SelectTopics from "../../components-user/topics/SelectTopics";
import { generateClient } from 'aws-amplify/api';
import { useNavigate, useParams } from "react-router-dom";


const client = generateClient();

const CreateProductPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const { courseId } = useParams();

    const { t, i18n } = useTranslation();

    const [courseData, setCourseData] = useState({});

    const [activeTab, setActiveTab] = useState("general");

    const handleClickTab = (tab) => {
        if(tab !== activeTab) {
            setActiveTab(tab);
        }
    }

    const getCourseData = async () => {
        try {
            const courseData = await client.graphql({
                variables: { id: courseId },
                query: /* GraphQL */ `
                query GetCourse($id: ID!) {
                  getCourse(id: $id) {
                        id
                        name {
                            language
                            text
                        }
                        description {
                            language
                            text
                        }
                        lectures {
                            items {
                                id
                                lectureId
                            }
                        }
                        type
                        price
                        createdAt
                        updatedAt
                    }
                }
                `,
            });
            //console.log(courseData);
            return courseData.data.getCourse
        } catch (error) {
            console.warn("Error getting course data", error);
        }
    };

  const intialLoadCourse = async () => {
    //console.log("loading course");
    setIsLoading(true);
    const dbCourse = await getCourseData();
    setCourseData(dbCourse);
    if(dbCourse.description) {
      const deContent = dbCourse.description.find(c => c.language === "de")?.text;
      const enContent = dbCourse.description.find(c => c.language === "en")?.text;
      setEditorState({
        de: deContent ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(deContent)
                        )
                      ) : EditorState.createEmpty(),
        en: enContent ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(enContent)
                        )
                      ) : EditorState.createEmpty(),
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    //console.log("courseId", courseId)
    if (!courseId) {
      console.log("No course id provided");
      return;
    } else {
        intialLoadCourse();
    }
  }, []);

  const [validationErrors, setValidationErrors] = useState([]);

  const createCourse = async () => {
    try {
        setIsLoading(true);
        const response = await client.graphql({
            query: courseId ?
            /* GraphQL */ `
            mutation UpdateCourse(
                $input: UpdateCourseInput!
                $condition: ModelCourseConditionInput
            ) {
                updateCourse(input: $input, condition: $condition) {
                    id
                }
            }
            ` :/* GraphQL */ `
            mutation CreateCourse(
                $input: CreateCourseInput!
                $condition: ModelCourseConditionInput
            ) {
                createCourse(input: $input, condition: $condition) {
                    id
                }
            }
            `,
            variables: {
                input: {
                    name: courseData.name,
                    description: courseData.description,
                    type: courseData.type,
                    price: courseData.price,
                    ...(courseId ? {id: courseId} : {})
                }
            }
        });
       
        let dataForLectures = [];
        // Only if lectures were loaded and possible changes were made
        if(lectures.length > 0) {
        if(selectedLectures.length > 0 && !courseId) {
            selectedLectures.forEach(lecture => {
                dataForLectures.push({
                    id: lecture.id,
                    courseLectureId: response.data.createCourse.id
                })
            })
            //console.log("dataForLectures", dataForLectures)
            await Promise.all(dataForLectures.map(async lecture => {
                console.log("lecture", lecture)
                const lectureData = await client.graphql({
                    variables: { input: {
                        courseId: lecture.courseLectureId,
                        lectureId: lecture.id
                    } },
                    query: /* GraphQL */ `
                    mutation CreateLecturesInCourses(
                        $input: CreateLecturesInCoursesInput!
                        $condition: ModelLecturesInCoursesConditionInput
                    ) {
                        createLecturesInCourses(input: $input, condition: $condition) {
                            id
                            courseId
                            lectureId
                        }
                    }
                `,
                });


            }
        ))} else if(courseId) {
            //console.log("updating course")
            async function updateCourseLectures(courseData, courseId, client) {
                const existingLectures = await fetchExistingLectures(courseId, client);
                const newLectureIds = selectedLectures.map(l => l.id) || [];
                const existingLectureIds = existingLectures.map(l => l.lectureId);
                // console.log("existingLectures", existingLectures)
                // console.log("existingLectureIds", existingLectureIds)
                // console.log("newLectureIds", newLectureIds)
                const lecturesToAdd = newLectureIds.filter(id => !existingLectureIds.includes(id));
                const lecturesToRemove = existingLectureIds.filter(id => !newLectureIds.includes(id));
            
                // Add new lectures
                await Promise.all(lecturesToAdd.map(async id => {
                  await addLecture({ id, courseLectureId: courseId }, client);
                }));
            
                // Remove unnecessary lectures
                await Promise.all(lecturesToRemove.map(async lectureId => {
                  await removeLecture(existingLectures.find(l => l.lectureId === lectureId)?.id, client);
                }));
            
            }

            async function fetchExistingLectures(courseId, client) {
                const query = /* GraphQL */ `
                query ListLecturesInCourses(
                    $filter: ModelLecturesInCoursesFilterInput
                    $limit: Int
                    $nextToken: String
                ) {
                    listLecturesInCourses(
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                    ) {
                    items {
                        id
                        courseId
                        lectureId
                    }
                    }
                }
                `;

                const response = await client.graphql({
                query,
                variables: { filter: { courseId: { eq: courseId } }, limit: 5000 }
                });

                return response.data.listLecturesInCourses?.items || [];
            }

            function compareLectures(newLectures, existingLectures) {
                const lecturesToAdd = newLectures.filter(lecture => !existingLectures.includes(lecture));
                const lecturesToRemove = existingLectures.filter(lecture => !newLectures.includes(lecture));
                return { lecturesToAdd, lecturesToRemove };
            }

            async function addLecture(lecture, client) {
                // GraphQL-Mutation to add a lecture
                const mutation = /* GraphQL */ `
                mutation CreateLecturesInCourses(
                    $input: CreateLecturesInCoursesInput!
                    $condition: ModelLecturesInCoursesConditionInput
                ) {
                    createLecturesInCourses(input: $input, condition: $condition) {
                        id
                    }
                }
                `;
                await client.graphql({
                query: mutation,
                variables: { input: { courseId: lecture.courseLectureId, lectureId: lecture.id } }
                });
            }

            async function removeLecture(courseLectureId, client) {
                const mutation = /* GraphQL */ `
                mutation DeleteLecturesInCourses(
                    $input: DeleteLecturesInCoursesInput!
                ) {
                    deleteLecturesInCourses(input: $input) {
                        id
                    }
                }
                `;

                await client.graphql({
                query: mutation,
                variables: { input: { id: courseLectureId } }
                });
            }

            await updateCourseLectures(courseData, courseId, client);
        }
        }


        setTimeout(() => {
            navigate(`/admin/insight/courses`);
            setIsLoading(false);
        }, 2000);
    }
    catch (error) {
        setIsLoading(false);
        console.error(error)
    }
    };
  
// Editor
const [editorState, setEditorState] = useState({
    de: EditorState.createEmpty(),
    en: EditorState.createEmpty(),
})
const onEditorStateChange = (editorState, language ) => {
  setEditorState(oldState => ({...oldState, [language]: editorState}));
  setCourseData(oldData => ({...oldData, description: [...(oldData.description?.length > 0 ? oldData.description.filter(c => c.language !== language) : []), {language, text: convertHTMlToRaw(editorState)}] }));

} 

// Image and Script

const [isUploadingLectureFiles, setIsUploadingLectureFiles] = useState(false);
const [uploadingLecturesErrors, setUploadingLecturesErrors] = useState(null);

const handleSetFile = (file) => {
  // upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
if(file) {
  try {
    setIsUploadingLectureFiles(true);
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onerror = error => {
    console.log('Error: ', error);
    setUploadingLecturesErrors(error);
  }
  reader.onload = async () => {
   try {
    const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
    const response = await client.graphql({
      query: /* GraphQL */ `
        mutation UploadLectureFile($base64FileBuffer: String!, $companyId: String!) {
          uploadLectureFile(base64FileBuffer: $base64FileBuffer, companyId: $companyId) {
            message
            fileName
          }
        }
      `,
      variables: {
        base64FileBuffer,
      },
    });
    //console.log("response upload file", response);
    setCourseData(oldData => ({...oldData, file: {fileName: response.data.uploadLectureFile.fileName, type: "productFile"}}));
    
   } catch (error) {
    console.log("error", error);
    setIsUploadingLectureFiles(false);
    setUploadingLecturesErrors(error.errors[0].message);
   }
  };
  
  setIsUploadingLectureFiles(false);
  setUploadingLecturesErrors(null);
  } catch (error) {
    console.log("error", error);
    setIsUploadingLectureFiles(false);
    setUploadingLecturesErrors(error.errors[0].message);
  }
  
}
  
}

const [isUploadingLectureImage, setIsUploadingLectureImage] = useState(false);
const [uploadingLectureImageError, setUploadingLectureImageError] = useState(null);

const handleSetImage = (file) => {
  // upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
  if(file) {
    try {
      setIsUploadingLectureImage(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = error => {
      console.log('Error reader: ', error);
      setUploadingLectureImageError(error);
    }
    reader.onload = async () => {
      try {
        //console.log(reader.result)
        const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
        const response = await client.graphql({
          query: /* GraphQL */ `
          mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
            uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
          }
        `,
          variables: {
            imageBuffer: base64FileBuffer,
            imageType: "productImage",
          },
        });
        //console.log("response", response);
        const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
        setCourseData(oldData => ({...oldData, image: {main:{fileName, alt: "Lecture Image"}}}));
        
        } catch (error) {
          console.log("error", error);
          setIsUploadingLectureImage(false);
          setUploadingLectureImageError(error.errors[0].message);
        }
    }
    setIsUploadingLectureImage(false);
    setUploadingLectureImageError(null);
    } catch (error) {
      console.log("error", error);
      setIsUploadingLectureImage(false);
      setUploadingLectureImageError(error.errors[0].message);
    }
  }
}

const [orderWithProduct, setOrderWithProduct] = useState([]);


// Lectures

const [lectures, setLectures] = useState([]);
const [topicGroups, setTopicGroups] = useState([]);
const [topics, setTopics] = useState([]);
const [shownLectures, setShownLectures] = useState([]);
const [selectedLectures, setSelectedLectures] = useState([]);

const getLectures = async () => {
    try {
        setIsLoading(true);

        const lectures = await client.graphql({
            variables: {
                filter: {eventLecturesId : {eq: "882c49b6-ba4b-4c66-8a35-987d04af08a5"}, status: {eq: "checked"}, startTime: {exists: true}},
                limit: 2000
            },
            query: /* GraphQL */ `
            query SearchLectures(
              $filter: SearchableLectureFilterInput
              $sort: [SearchableLectureSortInput]
              $limit: Int
              $nextToken: String
              $from: Int
              $aggregates: [SearchableLectureAggregationInput]
            ) {
              searchLectures(
                filter: $filter
                sort: $sort
                limit: $limit
                nextToken: $nextToken
                from: $from
                aggregates: $aggregates
              ) {
                items {
                    title {
                        language
                        text
                    }
                    speakers {
                        items {
                            employee {
                                image {
                                fileName
                                alt
                                }
                            }
                        }
                    }
                    subjects {
                        id
                        name {
                            language
                            text
                        }
                    } 
                    topics {
                        items {
                          topic {
                            id
                            name {
                              language
                              text
                            }
                          }
                        }
                    }        
                    content {
                        language
                        text
                    }
                    conditions {
                        name
                        accepted
                    }
                    image {
                        fileName
                        alt
                    }
                    pdfDe {
                        url
                        fileName
                        s3Path
                      }
                    pdfEn {
                        url
                        fileName
                        s3Path
                    }
                    company {
                        id
                        name
                        image {
                            main {
                                fileName
                                alt
                            }
                        }
                    }
                    conditions {
                        name
                        accepted
                    }
                    courses {
                      items {
                        course {
                          id
                          name {
                            language
                            text
                          }
                        }
                      }
                    }
                    id
                    approvedInsight
                    room
                    type
                    presentationLanguage
                    createdAt
                    updatedAt
                    companyLecturesId
                    eventLecturesId
                    lectureMainSpeakerId
                    adminTopic {
                        id
                    }
                    approved
                    status
                    startTime
                    endTime
                    __typename
                }
                nextToken
                total
                aggregateItems {
                  name
                  result {
                    ... on SearchableAggregateScalarResult {
                      value
                    }
                    ... on SearchableAggregateBucketResult {
                      buckets {
                        key
                        doc_count
                        __typename
                      }
                    }
                  }
                  __typename
                }
                __typename
              }
            }
          `
        });
        // Filter Lectuers with videoS3Key
        const resLectures = lectures.data.searchLectures.items.filter(l => l.approvedInsight).sort((a, b) => a.startTime.localeCompare(b.startTime));
        setLectures(resLectures);
        setShownLectures(courseId && courseData.lectures?.items?.length > 0 ? resLectures.filter(l => courseData.lectures?.items?.find(c => c.lectureId !== l.id)) : resLectures);
        setSelectedLectures(resLectures.filter(l => courseData.lectures?.items?.find(c => c.lectureId === l.id)));
        setIsLoading(false);
    } catch (error) {
        console.log("error on fetching lectures", error);
        if (error.data?.searchLectures?.items) {
            setLectures(error.data.searchLectures.items.filter(l => l?.approvedInsight));
        }
        setIsLoading(false);
    }
};

const getTopics = async () => {
  try {
    const topicGroupsWithTopics = await client.graphql({
      query: /* GraphQL */ `
      query ListTopicGroups(
        $filter: ModelTopicGroupFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listTopicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            sortKey
            color
            name {
              language
              text
            }
            topics {
              items {
                id
                name {
                  language
                  text
                }
                topicGroup {
                  color
                }
                topicGroupTopicsId
              }
            }
          }
          nextToken
          __typename
        }
      }
    `
      });
      setTopicGroups(topicGroupsWithTopics.data.listTopicGroups.items);
      const topics = topicGroupsWithTopics.data.listTopicGroups.items.map((topicGroup) => topicGroup.topics.items).flat();
      setTopics(topics);

  } catch (error) {
      console.log("error on fetching topics", error);
  }
}

useEffect(() => {
    if(activeTab === "lectures") {
        getLectures();
        if (topics.length === 0) {
          getTopics();
      }
    }
}, [activeTab]);


// Filters
const [filterTopicGroup, setFilterTopicGroup] = useState(null);
const [searchTerm, setSearchTerm] = useState("");

// Filter if values changed
useEffect(() => {
  let filteredLectures = lectures;
  if (filterTopicGroup) {
      filteredLectures = filteredLectures.filter(lecture => topics.find(topic => topic.id === lecture.adminTopic?.id)?.topicGroupTopicsId === filterTopicGroup);
  }
  if (searchTerm.length > 0) {
      filteredLectures = filteredLectures.filter(lecture => {
          if (lecture.company?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
              return true;
          }
          if (lecture.title?.find(t => t.language === i18n.language)?.text?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
              return true;
          }
          return false;
      });
  }

  setShownLectures(filteredLectures);
}, [filterTopicGroup, searchTerm]);

return (
<>
<MDBRow className="h-auto d-flex justify-content-center">
<MDBCol size="12" xl="12" xxl="11">
<MDBCard>
<MDBCardBody className="px-1 px-md-5">
<MDBBtn onClick={() => createCourse()} color="primary" className="float-end">
  {t("Save")}
</MDBBtn>
<MDBCardTitle>{courseId ? t("Edit Course") : t("Create Course")} {courseData.name?.find(n => n.language === i18n.language)?.text || courseData.name?.[0]?.text}</MDBCardTitle>
<MDBTabs className='mb-3'>
  <MDBTabsItem>
    <MDBTabsLink onClick={() => handleClickTab('general')} active={activeTab === 'general'}>
      <MDBIcon fas icon="cogs" className='me-2' /> {t("General")}
    </MDBTabsLink>
  </MDBTabsItem>
  <MDBTabsItem>
    <MDBTabsLink onClick={() => handleClickTab('lectures')} active={activeTab === 'lectures'}>
      <MDBIcon fas icon='image' className='me-2' /> {t("Lectures")}
    </MDBTabsLink>
  </MDBTabsItem>
 
</MDBTabs>

<MDBTabsContent>

<MDBTabsPane open={activeTab === 'general'}>

{/* Name */}
<MDBTypography tag="h5">{t("Name")}</MDBTypography>
<MDBInputGroup className="mb-1" textAfter={<span style={courseData.name?.find((t) => t.language === "de")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((courseData?.name?.length > 0? courseData.name.find((t) => t.language === "de")?.text: "")?.length || "0") + "/60"}</span>}>
  <div className="input-group-text">
  <ReactCountryFlag
      countryCode="de"
      svg
      style={{
      width: "20px",
      height: "20px",
      }}
      title={i18n.language}
  /> 
  </div>
  <MDBInput
  label={t("German")}
  type="text"
  maxLength="60"
  value={courseData?.name?.find((t) => t.language === "de")?.text || ""}
  onChange={(e) => setCourseData({...courseData, name: [{language: "de", text: e.target.value}, ...(courseData.name?.length > 0 ? courseData.name.filter((t) => t.language !== "de") : [])]})}
  />
</MDBInputGroup>
<MDBInputGroup className="mb-1" textAfter={<span style={courseData.name?.find((t) => t.language === "en")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((courseData?.name?.length > 0? courseData.name.find((t) => t.language === "en")?.text: "")?.length || "0") + "/60"}</span>}>
  <div className="input-group-text">
  <ReactCountryFlag
      countryCode="gb"
      svg
      style={{
      width: "20px",
      height: "20px",
      }}
      title={i18n.language}
  />
  </div>
  <input
  className="form-control"
  placeholder={t("English")}
  type="text"
  maxLength="60"
  value={courseData?.name?.find((t) => t.language === "en")?.text || ""}
  onChange={(e) => setCourseData({...courseData, name: [{language: "en", text: e.target.value}, ...(courseData.name?.length > 0 ? courseData.name.filter((t) => t.language !== "en") : [])]})}
  />
</MDBInputGroup>
{/* Name End */}

{/* Description */}
<div>
<MDBTypography tag="h5" className="mt-3">{t("Description")}</MDBTypography>
<ReactCountryFlag
    countryCode="de"
    svg
    style={{
    width: "20px",
    height: "20px",
    }}
    title={i18n.language}
/> 
<Editor
    editorState={editorState["de"]}
    editorClassName="md-textarea form-control"
    onEditorStateChange={e => onEditorStateChange(e, "de")}
  />
  <br></br>
  <ReactCountryFlag
    countryCode="gb"
    svg
    style={{
    width: "20px",
    height: "20px",
    }}
    title={i18n.language}
/>
<Editor
    editorState={editorState["en"]}
    editorClassName="md-textarea form-control"
    onEditorStateChange={e => onEditorStateChange(e, "en")}
  />
</div>
{/* Description End */}


</MDBTabsPane>
<MDBTabsPane open={activeTab === 'lectures'}>
<MDBCard className="w-auto mx-auto mb-4" style={{maxWidth: "1200px"}}>
    <MDBCardBody className="flex-column">
            <MDBRow>
                <MDBCol size="12">
                <MDBInput type="text" onChange={e => setSearchTerm(e.target.value)} label={t("Search")} />
                </MDBCol>
            </MDBRow>
            
            
         
            <div className="d-flex justify-content-between mt-3">
            <MDBChip key="actions" className="me-2 mb-2" style={{backgroundColor: !filterTopicGroup ? "#C8E6C9" : ""}} onClick={() => setFilterTopicGroup(null)} >
                {t("All")}
                </MDBChip>
            {topicGroups.sort((a, b) => a.sortKey - b.sortKey).map((topicGroup, index) => (
                    <MDBChip key={index} className="me-2 mb-2" style={{backgroundColor: filterTopicGroup === topicGroup.id ? "#C8E6C9" : ""}} onClick={() => setFilterTopicGroup(topicGroup.id)} >
                    {topicGroup.name.find((name) => name.language === i18n.language)?.text || topicGroup.name[0].text}
                    </MDBChip>
                ))}
                
            </div>
    </MDBCardBody>
</MDBCard>
<MDBRow>
<MDBCol size="6">
<MDBTypography tag="h5">{t("All Lectures")}</MDBTypography>

<MDBTable>
<MDBTableHead>
  <tr>
    <th>{t("Lecture")}</th>
    <th>{t("Company & Speaker")}</th>
    <th>{t("Actions")}</th>
  </tr>
</MDBTableHead>
<MDBTableBody>
  {shownLectures.map((lecture) => (
    <tr key={lecture.id}>
      <td>
        {lecture.title.find(t => t.language === i18n.language)?.text || lecture.title[0].text}
        <br></br>
        <div className='d-flex align-items-center'>
        {lecture.courses?.items.length > 0 && lecture.courses.items.map((course) => (
          <MDBChip key={course.course.id} className="me-2 mb-2" style={{width: "fit-content", backgroundColor: courseId === course.course.id ? "#C8E6C9" : ""}}>
          {course.course.name.find((name) => name.language === i18n.language)?.text || course.course.name[0].text}
          </MDBChip>
        ))  
        }
        </div>
      </td>
      <td>
        {lecture.company?.name}
        <br></br>
        <div className='d-flex align-items-center'>
        {lecture.speakers.items.map((speaker) => (
          <div key={speaker.employee.id}>
            {speaker.employee.image?.fileName ?
              <img
                src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+speaker.employee.image.fileName}
                className="rounded-circle border"
                style={{ height: "30px", width: "30px", objectFit: "contain" }}
                alt={speaker.employee.firstName + " " + speaker.employee.lastName}
              />
              :
              <MDBIcon className="" style={{ height: "30px", width: "30px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user" />
            }
            
          </div>
        ))}
        </div>
      </td>
      <td>
        <MDBBtn color='primary' size='sm' onClick={() => setSelectedLectures([...selectedLectures, lecture])}>{t("Add")}</MDBBtn>
      </td>
    </tr>
  ))}
</MDBTableBody>
</MDBTable>

</MDBCol>
<MDBCol size="6">
<MDBTypography tag="h5">{t("Selected Lectures")}</MDBTypography>

<MDBTable>
<MDBTableHead>
  <tr>
    <th>{t("Lecture")}</th>
    <th>{t("Company & Speaker")}</th>
    <th>{t("Actions")}</th>
  </tr>
</MDBTableHead>
<MDBTableBody>
  {selectedLectures.map((lecture) => (
    <tr key={lecture.id}>
      <td>
        {lecture.title.find(t => t.language === i18n.language)?.text || lecture.title[0].text}
        <br></br>
        <div className='d-flex align-items-center'>
        {lecture.courses?.items.length > 0 && lecture.courses.items.map((course) => (
          <MDBChip key={course.course.id} className="me-2 mb-2" style={{width: "fit-content", backgroundColor: courseId === course.course.id ? "#C8E6C9" : ""}}>
          {course.course.name.find((name) => name.language === i18n.language)?.text || course.course.name[0].text}
          </MDBChip>
        ))  
        }
        </div>
      </td>
      <td>
        {lecture.company?.name}
        <br></br>
        <div className='d-flex align-items-center'>
        {lecture.speakers.items.map((speaker) => (
          <div key={speaker.employee.id}>
            {speaker.employee.image?.fileName ?
              <img
                src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+speaker.employee.image.fileName}
                className="rounded-circle border"
                style={{ height: "30px", width: "30px", objectFit: "contain" }}
                alt={speaker.employee.firstName + " " + speaker.employee.lastName}
              />
              :
              <MDBIcon className="" style={{ height: "30px", width: "30px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user" />
            }
            
          </div>
        ))}
        </div>
      </td>
      <td>
        <MDBBtn color='danger' size='sm' onClick={() => setSelectedLectures(selectedLectures.filter(l => l.id !== lecture.id))}>{t("Remove")}</MDBBtn>
      </td>
    </tr>
  ))}
</MDBTableBody>
</MDBTable>

</MDBCol>

</MDBRow>

</MDBTabsPane>

</MDBTabsContent>

</MDBCardBody>
</MDBCard>
</MDBCol>
</MDBRow>

</>
);
};

export default CreateProductPage;
