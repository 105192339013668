import { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { UserContext } from "../../App";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { generateClient } from "aws-amplify/api";
import { getUrl } from "aws-amplify/storage";
import ReactPlayer from 'react-player'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { parse } from "uuid";



export default function LectureVideo() {
    const { t, i18n } = useTranslation();
    const { user } = useContext(UserContext);
    // Get lecture if from URL https://www.example.com/insight/lecture-video/:lectureId with react router params

    const { lectureId } = useParams();

    // Get lecture from API with the id
    const client = generateClient();
    const [lecture, setLecture] = useState(null);

    const getLecture = async () => {
        try {
            const res = await client.graphql({
                query: /* GraphQL */ `
                query GetLecture($id: ID!) {
                  getLecture(id: $id) {
                    id
                    type
                    startTime
                    endTime
                    dateTimes
                    event {
                      id
                      name {
                        language
                        text
                      }
                      image {
                        fileName
                      }
                      date
                      startDate
                      endDate 
                    }
                    company {
                      id
                      name
                      contact {
                        phone
                        mobile
                        fax
                        email
                      }
                      website
                      image {
                        main {
                            fileName
                        }
                      }
                      banner {
                        fileName
                      } 
                    }
                    companyBooth
                    speakers {
                      items {
                        id
                        lectureId
                        employeeId
                        createdAt
                        updatedAt
                        employee {
                            id
                            image {
                            fileName
                            }
                            user {
                                firstName
                                lastName
                                image {
                                fileName
                                }
                            }
                            }
                      }
                    }
                    session
                    mainSpeaker {
                      id
                      userEmployeeProfilesId
                    }
                    room
                    title {
                      language
                      text
                    }
                    subjects {
                      id
                      name {
                        language
                        text
                      }
                    }
                    topics {
                      items {
                        topic {
                            id
                            name {
                                language
                                text
                            }
                            }
                      }
                    }
                    content {
                      language
                      text
                    }
                    presentationLanguage
                    image {
                      fileName
                    }
                    pdfDe {
                      url
                      s3Path
                      fileName
                      type
                      name
                    }
                    pdfEn {
                      url
                      s3Path
                      fileName
                      type
                      name
                    }
                    videoS3Key {
                      s3Path
                    }
                    approved
                    lengthInMinutes
                    status
                    createdAt
                    updatedAt
                    companyLecturesId
                    eventLecturesId
                    lectureMainSpeakerId
                    lectureAdminTopicId
                  }
                }
              `,
                variables: { id: lectureId }
            });
            setLecture(res.data.getLecture);
            getVideoUrl(res.data.getLecture.videoS3Key.s3Path);

        }
        catch (error) {
            console.error(error);
        }
    }

    // Get video URL from S3
    const [videoUrl, setVideoUrl] = useState(null);
    const getVideoUrl = async (s3Path) => {
        try {
            const url = await getUrl({
                key: s3Path,
                level: 'public',
                options: {
                    expires: 60
                }
            });
            setVideoUrl(url.url);
        }
        catch (error) {
            console.error(error);
        }
    }

    // Video Play progress
    // Get video progress from API and start the video player from the last time. Track the progress and update the progress in the API every 10 seconds. Track the last timestamp and the total time watched.
    // type VideoProgress
    // {
    // id: ID!
    // userId: String @index(name: "byUser", queryField: "videoProgressByUser")
    // videoId: String @index(name: "byVideo", sortKeyFields: ["userId"], queryField: "videoProgressByVideoAndUser")
    // progressInSeconds: Int
    // totalWatchedInSeconds: Int
    // }
    const playerRef = useRef(null);
    const [progressId, setProgressId] = useState(null);
    const [lastTime, setLastTime] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalWatched, setTotalWatched] = useState(0);

    const getProgress = async () => {
        try {
            const res = await client.graphql({
                query: /* GraphQL */ `
                query VideoProgressByVideoAndUser(
                  $videoId: String!
                  $userId: ModelStringKeyConditionInput
                ) {
                  videoProgressByVideoAndUser(
                    videoId: $videoId
                    userId: $userId
                  ) {
                    items {
                      id
                      userId
                      videoId
                      progressInSeconds
                      totalWatchedInSeconds
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                }
              `,
                variables: { userId: {eq: user.sub}, videoId: lecture.id }
            });
            if (res.data.videoProgressByVideoAndUser.items.length > 0) {
                const progress = res.data.videoProgressByVideoAndUser.items[0];
                setProgressId(progress.id);
                setLastTime(progress.progressInSeconds);
                setCurrentTime(progress.progressInSeconds);
                setTotalWatched(progress.totalWatchedInSeconds);
                playerRef.current.seekTo(progress.progressInSeconds);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const updateProgress = async (progress, totalWatched) => {
        console.log("Update Progress", progress, totalWatched)
        try {
            if(progressId) {
                const res = await client.graphql({
                    query: /* GraphQL */ `
                    mutation UpdateVideoProgress(
                        $input: UpdateVideoProgressInput!
                        $condition: ModelVideoProgressConditionInput
                        ) {
                        updateVideoProgress(input: $input, condition: $condition) {
                            id
                            userId
                            videoId
                            progressInSeconds
                            totalWatchedInSeconds
                            createdAt
                            updatedAt
                            __typename
                        }
                    }
                    `,
                    variables: {
                        input: {
                            id: progressId,
                            userId: user.sub,
                            videoId: lecture.id,
                            lectureVideoProgressId: lecture.id,
                            userVideoProgressId: user.id,
                            progressInSeconds: parseInt(progress),
                            totalWatchedInSeconds: parseInt(totalWatched)
                        }
                    }
                });
            } else {
                const res = await client.graphql({
                    query: /* GraphQL */ `
                    mutation CreateVideoProgress(
                        $input: CreateVideoProgressInput!
                        ) {
                        createVideoProgress(input: $input) {
                            id
                            userId
                            videoId
                            progressInSeconds
                            totalWatchedInSeconds
                            createdAt
                            updatedAt
                            __typename
                        }
                    }
                    `,
                    variables: {
                        input: {
                            userId: user.sub,
                            videoId: lecture.id,
                            progressInSeconds: parseInt(progress),
                            totalWatchedInSeconds: parseInt(totalWatched)
                        }
                    }
                });
                setProgressId(res.data.createVideoProgress.id);
            }
            
        }
        catch (error) {
            console.error(error);
        }
    }

    const handleProgress = (state) => 
{
    setCurrentTime(state.playedSeconds);

    // Berechne die tatsächlich angeschauten Sekunden
    if (state.playedSeconds > lastTime + 5) {
        // Update lastTime und füge den Wert zur Gesamtzeit hinzu
        const newTotalWatched = totalWatched + (state.playedSeconds - lastTime);
        setTotalWatched(newTotalWatched);
        setLastTime(state.playedSeconds);
        updateProgress(state.playedSeconds, newTotalWatched);
    }
}

    useEffect(() => {
        getLecture();
    }, [lectureId]);



    const downloadFile = async (fileName, path) => {
        const regex = /protected\/(eu-central-1:[a-z0-9-]+)\//i;
        const match = path.match(regex);
        const identity = match[1];
        const getUrlResult = await getUrl({
          key: fileName,
          options: {
            accessLevel: 'protected' ,
            targetIdentityId: identity,
            expires: 60,
          },
        });
        console.log('signed URL: ', getUrlResult.url);
        window.open(getUrlResult.url, '_blank');
      }

  return (
    lecture &&
<>

<MDBCard>
    <MDBCardBody>
        <MDBCardTitle>{lecture.title.find(t => t.language === i18n.language)?.text || lecture.title[0]?.text}</MDBCardTitle>
        {
            user.hasInsightAccess ?
            <ReactPlayer
            ref={playerRef}
            url={videoUrl}
            controls={true}
            width="100%"
            height="100%"
            config={{
                file: {
                    attributes: {
                        controlsList: "nodownload",
                        onContextMenu: e => e.preventDefault()
                    }
                }
    
            }}
            onReady={() =>  {if(user.sub && !progressId) getProgress()}}
            onProgress={handleProgress}
            onSeek={e => {
                setLastTime(e)
            }}
            />
            :
            <div className="text-center">
                <MDBIcon fas icon="lock" size="3x" className="text-danger" />
                <p className="text-danger">{t("You do not have access to this video")}</p>
            </div>
        }
       
       

        <MDBRow className="mt-2 mt-md-4">
            <MDBCol md="4">
                <h5>{t("Subjects")}</h5>
                <ul>
                    {lecture.subjects.map(subject => (
                        <li key={subject.id}>{subject.name.find(n => n.language === i18n.language)?.text || subject.name[0]?.text}</li>
                    ))}
                </ul>
                {(lecture?.pdfDe?.fileName || lecture?.pdfEn?.fileName) && <h5>{t("Documents")}</h5>}
                {
                lecture?.pdfDe?.fileName && 
                <div className="text-center" style={{maxWidth: "200px"}}>
                <MDBBtn color="link" onClick={() => downloadFile(lecture.pdfDe.fileName, lecture.pdfDe.s3Path)} size="sm" className="mb-0">
                    {lecture.pdfDe.fileName}
                </MDBBtn>
                </div>
                
                }
                {
                lecture?.pdfEn?.fileName &&
                <div className="text-center" style={{maxWidth: "200px"}}>
                <MDBBtn color="link" onClick={() => downloadFile(lecture.pdfEn.fileName, lecture.pdfEn.s3Path)} size="sm" className="mb-0">
                    {lecture.pdfEn.fileName}
                </MDBBtn>
                </div>
                }
               
            </MDBCol>
            <MDBCol md="8">
                <h5>{t("Content")}</h5>
                <p>{lecture.content.find(c => c.language === i18n.language)?.text || lecture.content[0]?.text}</p>
            </MDBCol>
        </MDBRow>
        <hr></hr>
        <MDBRow>
            <MDBCol md="6">
                <h5>{t("Speakers")}</h5>
                <ul style={{listStyleType: "none"}}>
                    {lecture.speakers.items.map(speaker => (
                        <li key={speaker.id}>
                            <div className='d-flex align-items-center text-start'>
                            {speaker.employee.image?.fileName || speaker.employee.user?.image?.fileName ?
                                        <img
                                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+ (speaker.employee.image?.fileName ||speaker.employee.user.image.fileName)}
                                            className="rounded-circle border"
                                            style={{ height: "50px", width: "50px", objectFit: "cover" }}
                                            alt={speaker.employee.image?.alt || speaker.employee.user.image.alt}
                                        />
                                        :
                                        <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                                        
                            }
                            <div className='ms-3'>
                            <p className='fw-bold mb-1'>{speaker.employee.user.title ? speaker.employee.user.title +" " : ""}{speaker.employee.user?.firstName} {speaker.employee.user?.middleName} {speaker.employee.user?.lastName}</p>
                            {/* <p className='text-muted mb-0'><MDBIcon className="me-2" fas icon="comments" /> {t("Connect")}</p> */}
                            </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </MDBCol>
            <MDBCol md="6">
                <h5>{t("Company")}</h5>
                <div className='d-flex align-items-center'>
                    {lecture.company.image?.main?.fileName ?
                        <img
                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+lecture.company.image.main.fileName}
                            className=""
                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                            alt={lecture.company.image.alt}
                        />
                    :
                        <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                
                    }
                    <div className='ms-3'>
                      <p className='fw-bold mb-1'>{lecture.company.name}</p>
                      <p className='text-muted mb-0'><a href={lecture.company.website} target="_blank" rel="noreferrer">{lecture.company.website}</a></p>
                    </div>
                    </div>
            </MDBCol>
        </MDBRow>
    </MDBCardBody>
</MDBCard>
</>
  )
}
