import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTable, 
    MDBTableBody, MDBTableHead, MDBBtnGroup, MDBIcon, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBModalTitle, MDBCardText, MDBCardImage
     } from "mdb-react-ui-kit"
    import { useState, useEffect, useContext } from "react"
    import { useTranslation } from "react-i18next"
    import { useParams } from "react-router-dom"
    import { Link } from "react-router-dom"
    import { generateClient } from "aws-amplify/api"
    import { UserContext } from "../../App"
    import { isoToGermanDate } from "../../utils/dateTools"
    import { deleteTicket } from "../../graphql/mutations"
    import ShowTicket from "../../components-user/tickets/ShowTicket"
    
    const client = generateClient()
    
    export default function AllTickets() {
        const { user } = useContext(UserContext);
        const { t, i18n } = useTranslation()
    
        const { companyid } = useParams()
        const [tickets, setTickets] = useState([])
        const [orders, setOrders] = useState([])
    
        const getOrders = async (companyId) => {
            try {
            const data = await client.graphql({
                query: /* GraphQL */ `
                query SearchOrders( $filter: SearchableOrderFilterInput ) {
                    searchOrders(filter: $filter) {
                        items {
                            id
                            createdAt
                            paymentStatus
                            status
                            financial {
                                invoiceFile {
                                    s3Path
                                }
                            }
                            lineItems {
                                items {
                                    id
                                    product {
                                        id
                                        name {
                                            language
                                            text
                                        }
                                        icon
                                        type
                                    }
                                    bookedBooth {
                                        id
                                        boothNumber
                                    }
                                }
                            }
                        }
                    }
                }
                `,
                variables: { filter: { companyOrdersId: { eq: companyId} } },
            })
            //console.log(data)

                setOrders(data.data.searchOrders.items)
            } catch (error) {
            console.error(error)
            }
        }
    
    
        useEffect(() => {   
            if(companyid) getOrders(companyid);
            
        }, [companyid])
    
    
        const handleDeleteTicket = async (id) => {
            try {
              const data = await client.graphql({
                query: deleteTicket,
                variables: { input: { id } },
                })
                console.log(data)
                setTickets(tickets.filter(ticket => ticket.id !== id))
            }
            catch (error) {
              console.error(error)
            }
        }
    
        const [viewTicketModal, setViewTicketModal] = useState(false);
        const toggleViewTicketModal = () => setViewTicketModal(!viewTicketModal);
    
        const [selectedTicket, setSelectedTicket] = useState(null);
    
        const [viewOrderModal, setViewOrderModal] = useState(false);
        const toggleViewOrderModal = () => setViewOrderModal(!viewOrderModal);
    
        const [selectedOrder, setSelectedOrder] = useState(null);
    
      return (
    <>
    <h2>{t("Products")}</h2>
    <MDBRow className="mb-3 mb-lg-5">
        {tickets.length > 0  && !orders.some(
            order => order.lineItems?.items.some(item => item.product.id === "09512d04-8e19-4957-848e-f6b1d3d076c4")
        ) && (
            <MDBCol size="12" lg="3" className="h-100">
                <MDBCard className="mb-2">
                    <MDBCardImage src="https://cleanroom-processes.de/wp-content/uploads/2024/03/CP-Insight-PreDesign.jpg" position='top' alt='...' 
                    style={{
                        height: '150px',
                        width: "auto",
                        objectFit: "cover",
                        objectPosition: "top"
                    }} />
                        <MDBCardBody>
                            <MDBCardTitle>Premium Ticket Upgrade</MDBCardTitle>
                            <MDBCardText>{t("Upgrade your visitor ticket to premium to get access to all lectures")}</MDBCardText>
                            <Link to={`/checkout?productId=09512d04-8e19-4957-848e-f6b1d3d076c4`}>
                                <MDBBtn>{t("Upgrade Ticket")}</MDBBtn>
                            </Link>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            )}
        {/* {tickets.length < 1 && (
        <MDBCol size="12" lg="3" className="h-100">
                <MDBCard className="mb-2 h-100">
                <MDBCardImage src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/eventImages/lounges_ka_04_2024.jpeg" position='top' alt='...' 
                style={{
                    height: '150px',
                    width: "auto",
                    objectFit: "cover"
                }} />
                    <MDBCardBody>
                        <MDBCardTitle>Ticket - Lounges 2024 Karlsruhe</MDBCardTitle>
                        <MDBCardText>
                        Besucher oder Premium Ticket
                        <br />
                        23.04.2024 - 25.04.2024</MDBCardText>
                        <Link to={`/book-ticket?eventId=882c49b6-ba4b-4c66-8a35-987d04af08a5`}>
                            <MDBBtn>{t("Book ticket")}</MDBBtn>
                        </Link>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        )} */}
        {(tickets.length < 1 || !tickets.some(ticket => ticket.type === "premium")) && (
        <MDBCol size="12" lg="3">
                <MDBCard className="mb-2 h-100">
                <MDBCardImage src="https://cleanroom-processes.de/wp-content/uploads/2024/03/CP-Insight-PreDesign.jpg" position='top' alt='...' 
                style={{
                    height: '150px',
                    width: "auto",
                    objectFit: "cover",
                    objectPosition: "top"
                }} />
                    <MDBCardBody>
                        <MDBCardTitle>CP-INSIGHT</MDBCardTitle>
                        <MDBCardText>Exklusiver Zugang aller Fach- und Schulungsvorträge</MDBCardText>
                        <Link to={`/checkout?productId=d7ce0201-959a-4d13-802e-e44a0b5e6af3`}>
                            <MDBBtn>{t("Book access")}</MDBBtn>
                        </Link>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        )}
        {
            // Not show if user has order with lineItem type "event_booth" or "event_booth_partner"
            !orders.some(
                order => order.lineItems?.items.some(item => item.product.type === "event_booth" || item.product.type === "event_booth_partner")
            ) && (
            <MDBCol size="12" lg="3">
                <MDBCard className="mb-2 h-100">
                <MDBCardImage src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/cp_spotlight.jpg" position='top' alt='...' 
                style={{
                    height: '150px',
                    width: "auto",
                    objectFit: "cover",
                    objectPosition: "top"
                }} />
                    <MDBCardBody>
                        <MDBCardTitle>CP-Company</MDBCardTitle>
                        <MDBCardText>{t("Present your company and products on our industry platform and trade fair.")}</MDBCardText>
                        <Link to={`/checkout?productId=ebe3f9da-433e-4db6-bd2e-f06c5d598da1`}>
                            <MDBBtn>{t("Book")}</MDBBtn>
                        </Link>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            )
        }
            
            {user.employeeProfiles?.items?.[0] && (
                <MDBCol size="12" lg="3">
                <MDBCard className="mb-2 h-100">
                <MDBCardImage src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/kachel_708x398_loungess_ka_2025.jpg" alt='...' 
                style={{
                    height: '150px',
                    width: "auto",
                    objectFit: "cover",
                    objectPosition: "center"
                }} />
                    <MDBCardBody>
                        <MDBCardTitle>{t("Booth")} Lounges 2025 Karlsruhe</MDBCardTitle>
                        <MDBCardText>{t("Book your booth for the fair Lounges 2025 in Karlsruhe")}</MDBCardText>
                        <Link to={`/mycompany/${user.employeeProfiles?.items?.[0]?.company.id}/select-booth/81f8ba3d-61b2-48c0-8d99-d2b785920450`}>
                            <MDBBtn>{t("Book")}</MDBBtn>
                        </Link>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            )
                }
            {user.employeeProfiles?.items?.[0] && (
            <MDBCol size="12" lg="3">
            <MDBCard className="mb-2 h-100">
            <MDBCardImage src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/2500x1406px_lounges_wi_2024_300.jpg" alt='...' 
            style={{
                height: '150px',
                width: "auto",
                objectFit: "cover",
                objectPosition: "center"
            }} />
                <MDBCardBody>
                    <MDBCardTitle>{t("Booth")} Lounges 2024 Wien</MDBCardTitle>
                    <MDBCardText>{t("Book your booth for the fair Lounges 2024 in Wien")}</MDBCardText>
                    <Link to={`/mycompany/${user.employeeProfiles?.items?.[0]?.company.id}/select-booth/db09ba25-a2ad-4aa0-a164-9c2cf5f1eb09`}>
                        <MDBBtn>{t("Book")}</MDBBtn>
                    </Link>
                </MDBCardBody>
            </MDBCard>
            </MDBCol>
            )}
            
    </MDBRow>
    
    <MDBCard>
        <MDBCardBody>
            <MDBCardTitle>
                <h2>{t("Bookings")}</h2>
            </MDBCardTitle>
            <MDBTable align="middle">
                <MDBTableHead>
                    <tr>
                    <th>{t("Booking date")}</th>
                        <th>{t("Details")}</th>
                        
                        <th>{t("Payment status")}</th>
                        <th>{t("Actions")}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {tickets.map((ticket, index) => (
                        <tr key={index}>
                            <td className=""><MDBIcon fas icon="ticket" color="secondary" size="2x" className="me-3" />
                            <span style={{verticalAlign: "super"}}>{t("Ticket")} {ticket.event.name.find(name => name.language === i18n.language)?.text || ticket.event.name[0]?.text}</span>
                            </td>
                            <td>
                            {/* {ticket.type === "default" ?
                            t("Visitor Ticket") : t("Premium ticket")
                            } <br/> */}
                            {ticket.personalAddress?.firstName} {ticket.personalAddress?.lastName} <br/>
                            {ticket.eventDays.map((day, index) => (index !== 0 ? ", " : "" ) +isoToGermanDate(day))}
                            <div className="d-lg-none">
                                <MDBBtn color="primary" size="sm"
                                onClick={() => {
                                    setSelectedTicket(ticket)
                                    toggleViewTicketModal()
                                }}
                                >{t("View")}</MDBBtn>
                            </div>
                            </td>
                            <td>{isoToGermanDate(ticket.createdAt)}</td>
                            <td><div className="text-success"><MDBIcon icon='check' /> {t("Paid")}</div></td>
                            <td>
                                <MDBBtnGroup>
                                    <MDBBtn color="primary" size="sm"
                                        onClick={() => {
                                            setSelectedTicket(ticket)
                                            toggleViewTicketModal()
                                        }}
                                        >{t("View")}</MDBBtn>
    {/*                             
                                        <MDBBtn color="danger" size="sm"
                                        onClick={() => {
                                            if (window.confirm(t("Are you sure you want to delete this ticket?"))) {
                                                handleDeleteTicket(ticket.id)
                                            }
                                        }}
                                        >{t("Delete")}</MDBBtn> */}
    
                                </MDBBtnGroup>
                            </td>
                        </tr>
                    ))
                    }
                    {orders.map((order, index) => (
                        <tr key={index} className={order.status === "canceled" ? "text-danger" : ""}>
                            <td>{isoToGermanDate(order.createdAt)}{order.status === "canceled" ? " - " + t("canceled") : ""}</td>
                            <td>
                            {order.lineItems?.items.find(item => item.product.type === "event_booth" || item.product.type === "event_booth_partner") ?
                            <span>
                                {order.lineItems?.items.find(item => item.product.type === "event_booth" || item.product.type === "event_booth_partner").product.name.find(name => name.language === i18n.language)?.text || order.lineItems?.items.find(item => item.product.type === "event_booth" || item.product.type === "event_booth_partner").product.name[0]?.text}
                            </span>
                            :
                            order.lineItems?.items.map((product, index) => (
                                <div key={index}>{product.product.name.find(name => name.language === i18n.language)?.text || product.product.name[0]?.text}</div>
                            ))
                            }
                            
                            </td>
                            
                            <td>{order.paymentStatus === "paid" ? <div className="text-success"><MDBIcon icon='check' /> {t("Paid")}</div> : <div className="text-danger">{t(order.paymentStatus)}</div>}</td>
                            <td>
                                <MDBBtnGroup>
                                    <MDBBtn color="primary" size="sm"
                                        onClick={() => {
                                            setSelectedOrder(order)
                                            toggleViewOrderModal()
                                        }}
                                        >{t("View Order")}
                                    </MDBBtn>
                                   

                                </MDBBtnGroup>
                                <br />
                                {order.lineItems?.items.filter(item => item.product.type === "event_booth" || item.product.type === "event_booth_partner").map((item, index) => (
                                        <Link to={`/mycompany/${companyid}/edit-booth/${item.bookedBooth?.id}`} key={index}>
                                            <MDBBtn color="primary" className="mt-2" outline size="sm">{t("Configure booth")}</MDBBtn>
                                        </Link>
                                    ))}
                            </td>
                        </tr>
                    ))
                    }
                </MDBTableBody>
            </MDBTable>
        </MDBCardBody>
    </MDBCard>
    
    <MDBModal open={viewTicketModal} onClose={() => setViewTicketModal(false)} position="top">
        <MDBModalDialog>
            <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Your ticket")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleViewTicketModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <ShowTicket ticket={selectedTicket} handleDeleteTicket={handleDeleteTicket} />
            </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
    <MDBModal open={viewOrderModal} onClose={() => setViewOrderModal(false)} position="top">
        <MDBModalDialog>
            <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Your order")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleViewOrderModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <h4>{t("Order details")}</h4>
            <p>{t("Order date")}: {isoToGermanDate(selectedOrder?.createdAt)}</p>
            <h5>{t("Invoice")}</h5>
            {selectedOrder?.financial?.invoiceFile?.s3Path ? (
                <a href={"https://iec-cp-public.s3.amazonaws.com/" + selectedOrder?.financial?.invoiceFile?.s3Path} target="_blank" rel="noreferrer">{t("Download invoice")}</a>
            ) : (
                <p>{t("No invoice available yet")}</p>
            )}
            </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
    
    </>
      )
    }
    