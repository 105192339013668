import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTypography, MDBIcon } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next"
import { transformMoney } from "../../../utils/money"

export default function SideCart({params, products = [], customerType, hideDescription = false, hideImage = false, taxRate}) {
  const { t, i18n } = useTranslation()
  return (
<>
<MDBCard>
  <MDBCardBody>
    <MDBCardTitle>{t("Cart")}</MDBCardTitle>
    <MDBRow className="mt-3">
      {products.length > 0 && products.map((product, index) => (
        <MDBCol key={index} md='12' className='mb-4'>
          <MDBRow>
            <MDBCol md='2'>
              {product?.image?.main?.fileName ? 
              <MDBCol md='12'><img src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/" + product?.image?.main.fileName} alt={product?.name.find(n => n.language === i18n.language)?.text || product?.name[0]?.text} className='img-fluid' /></MDBCol>
              : <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon={product.icon} />}
            </MDBCol>
            <MDBCol md='6'>
              <MDBTypography tag='h6' className="fw-bold">
                {product.qty && product.qty > 1 && <span className="me-2">{product.qty}x</span>}
                {product?.name.find(n => n.language === i18n.language)?.text || product?.name[0]?.text}
                </MDBTypography>
              {!hideDescription && <MDBTypography tag='p'>{product?.shortDescription?.find(n => n.language === i18n.language)?.text || product?.shortDescription?.[0]?.text}</MDBTypography>}
            </MDBCol>
            <MDBCol md='4'>
              <MDBTypography tag='h6' className='text-end'>
                {customerType === "private" ? 
                transformMoney(product.qty && product.qty > 1 ? (product?.price * (1 + taxRate / 100)) * product.qty : product?.price * (1 + taxRate / 100))
                :
                transformMoney(product.qty && product.qty > 1 ? product?.price * product.qty : product?.price)
                
                }
                </MDBTypography>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      ))}
    </MDBRow>
    <hr />
    <MDBRow>
      <MDBCol md='6'>
        <MDBTypography tag='h6'>{t("Total net")}</MDBTypography>
      </MDBCol>
      <MDBCol md='6'>
        <MDBTypography tag='h6' className='text-end'>{(products.reduce((acc, product) => acc + (product.qty && product.qty > 1 ? product?.price * product.qty : product?.price) , 0)).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</MDBTypography>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol md='6'>
        <MDBTypography tag='h6'>{t("VAT")}</MDBTypography>
      </MDBCol>
      <MDBCol md='6'>
        <MDBTypography tag='h6' className='text-end'>{(products.reduce((acc, product) => acc + (product.qty && product.qty > 1 ? product?.price * product.qty : product?.price) , 0) * taxRate / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</MDBTypography>
      </MDBCol>
    </MDBRow>
    <hr></hr>
    <MDBRow>
      <MDBCol md='6'>
        <MDBTypography tag='h6'>{t("Total incl. vat")}</MDBTypography>
      </MDBCol>
      <MDBCol md='6'>
        <MDBTypography tag='h6' className='text-end fw-bold'>{(products.reduce((acc, product) => acc + (product.qty && product.qty > 1 ? product?.price * product.qty : product?.price) , 0) * (1 + taxRate / 100)).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</MDBTypography>
      </MDBCol>
    </MDBRow>
  </MDBCardBody>
</MDBCard>

</>

  )
}
