import React, { useState, useEffect, useCallback } from "react";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTable, MDBTableBody, 
    MDBTableHead, MDBChip, MDBTextArea, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,   
    MDBSelect,
    MDBTypography} from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';


const client = generateClient();
export default function EventGuests({company}) {
    const { t, i18n } = useTranslation();
    const companyid = company ? company.id : window.location.pathname.includes("mycompany") ? window.location.pathname.split("/")[2] : window.location.pathname.split("/")[3];

    const [isLoading, setIsLoading] = useState(false);
    const [codes, setCodes] = useState([]);
    const [events, setEvents] = useState([]);
    const [allVisisions, setAllVisisions] = useState([]);
    const [guests, setGuests] = useState([]);

    const getCodes = async () => {
        try {
          const company = await client.graphql({
            variables: { id: companyid },
            query: /* GraphQL */ `
            query GetCompany($id: ID!) {
                getCompany(id: $id) {
                    id
                    invitationCodes {
                        items {
                            id
                            code
                            event {
                                id
                                name {
                                    text
                                    language
                                }
                                startDate
                                endDate
                            
                            }
                        }
                    }
                }
            }
            `
            });
            setCodes(company.data.getCompany.invitationCodes.items);

            console.log("codes", codes);
            return codes;

        } catch (error) {
            console.log("error on fetching departments", error);
            
        }
    }

    const getVisitors = async (codes) => {
        console.log("Loading tickets", codes)
        try {
            let allTickets = [];  // This will hold all tickets across all codes.
            let nextToken = null;
                do {
                    const ticketsRes = await client.graphql({
                        variables: { 
                            limit: 1000,
                            nextToken: nextToken
                            
                        },
                        query: /* GraphQL */ `
                        query SearchTickets($filter: SearchableTicketFilterInput,  $limit: Int, $nextToken: String) {
                            searchTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                items {
                                    id
                                    invitationCode
                                    createdAt
                                    eventDays
                                    personalAddress {
                                        company
                                        firstName
                                        lastName
                                        salutation
                                        title
                                    }
                                   
                                }
                                nextToken
                            }
                        }
                        `
                    });
    
                    nextToken = ticketsRes.data.searchTickets.nextToken;
                    allTickets.push(...ticketsRes.data.searchTickets.items.map(i => ({...i, invitationCode: i.invitationCode?.toUpperCase() || ""})));
                } while (nextToken);
            
            setAllVisisions(allTickets);

            setGuests(allTickets.filter((ticket) => codes?.map(code => code?.code)?.includes(ticket.invitationCode)));
    
        } catch (error) {
            console.log("Error on fetching tickets:", error);
        }
    }

    const getEvents = async () => {
        try {
            const events = await client.graphql({
                query: /* GraphQL */ `
                query ListEvents {
                    listEvents {
                        items {
                            id
                            name {
                                text
                                language
                            }
                            startDate
                            endDate
                            status
                        }
                    }
                }
                `,
                variables: {
                    filter: {
                        startDate: {
                            ge: new Date().toISOString().substring(0, 10)
                        }
                    }
                }
            });
            setEvents(events.data.listEvents.items.filter(event => event.status === "active" && new Date(event.startDate) >= new Date()));
            return events.data.listEvents.items;
        } catch (error) {
            console.log("error on fetching events", error);
        }
    }
    

    const loadData = async () => {
        setIsLoading(true);
        try {
            const codes = await getCodes();
            await getEvents();
            await getVisitors(codes)
            
        } catch (error) {
            console.log("Error on loading data", error);
        }
        setIsLoading(false);
    }



    useEffect(() => {
        loadData();
    }, [company]);

    

   


    const [newCodeInput, setNewCodeInput] = useState("");
    const [newCodeEventId, setNewCodeEventId] = useState("");

    const handleAddCode = async () => {
        try {
            const newCode = await client.graphql({
                variables: {
                    input: {
                        code: newCodeInput,
                        companyInvitationCodesId: companyid,
                        eventInvitationCodesId: newCodeEventId
                    }
                },
                query: /* GraphQL */ `
                mutation CreateInvitationCode($input: CreateInvitationCodeInput!) {
                    createInvitationCode(input: $input) {
                        id
                        code
                        event {
                            id
                            name {
                                text
                                language
                            }
                            startDate
                            endDate
                        }
                    }
                }
                `
            });
            setNewCodeInput("");
            setNewCodeEventId("");
            setCodes([...codes, newCode.data.createInvitationCode]);
        } catch (error) {
            console.log("Error on adding code", error);
        }
    }

    const handleDeleteCode = async (codeId) => {
        try {
            await client.graphql({
                variables: {
                    input: {
                        id: codeId
                    }
                },
                query: /* GraphQL */ `
                mutation DeleteInvitationCode($input: DeleteInvitationCodeInput!) {
                    deleteInvitationCode(input: $input) {
                        id
                    }
                }
                `
            });
            setCodes(codes.filter(code => code.id !== codeId));
        } catch (error) {
            console.log("Error on deleting code", error);
        }
    }






    const [basicActive, setBasicActive] = useState('tabGuests');

    const handleBasicClick = (value) => {
      if (value === basicActive) {
        return;
      }
  
      setBasicActive(value);
    };

  return (
<>
<MDBTabs className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tabGuests')} active={basicActive === 'tabGuests'}>
            {t("Guests")}
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tabAllVisitors')} active={basicActive === 'tabAllVisitors'}>
            {t("All Visitors")}
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane open={basicActive === 'tabGuests'}>
            <MDBTable>
                <MDBTableHead>
                    <tr>
                    <th>{t("Code")}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {codes.map((code) => (
                    <tr>
                        <td>
                            {code.code.toUpperCase()} - {code.event.name.find(n => n.language === i18n.language)?.text || code.event.name[0].text} ({new Date(code.event.startDate).toLocaleDateString("de-DE")} - {new Date(code.event.endDate).toLocaleDateString("de-DE")})
                            <span style={{float: "right"}}> <MDBIcon fas icon="trash" onClick={() => handleDeleteCode(code.id)} /></span>
                            </td>
                    </tr>
                    ))}
                    <tr>
                        <MDBTypography tag="h6" className="my-0">{t("Add Code")}</MDBTypography>
                        <span>
                            {t("Please choose a code that is either random or not too general. It is best if the code also includes the name of the company.")}
                        </span>
                        <MDBRow>
                            <MDBCol>
                                <MDBInput label={t("Code")} value={newCodeInput} onChange={(e) => setNewCodeInput(e.target.value)} />
                            </MDBCol>
                            <MDBCol>
                                <MDBSelect 
                                    label={t("Event")}
                                    data={[
                                        { text: t("Select Event"), value: "" },
                                        ...events.map(event => ({ text: event.name.find(n => n.language === i18n.language)?.text || event.name[0].text, value: event.id }))
                                    ]}
                                    value={newCodeEventId}
                                    onChange={(e) => setNewCodeEventId(e.value)}
                                />
                            </MDBCol>
                            <MDBCol>
                                <MDBBtn onClick={handleAddCode}>{t("Add Code")}</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </tr>
                </MDBTableBody>
            </MDBTable>
            

            <MDBTable>
                <MDBTableHead>
                    <tr>
                    <th>{t("Code")}</th>
                    <th>{t("Guest")}</th>
                    <th>{t("Company")}</th>
                    <th>{t("Visiting Days")}</th>
                    <th>{t("Booking Date")}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                        guests.map((ticket) => (
                            <tr>
                                <td>{ticket.invitationCode}</td>
                                <td>{ticket.personalAddress.salutation} {ticket.personalAddress.firstName} {ticket.personalAddress.lastName}</td>
                                <td>{ticket.personalAddress.company || ""}</td>
                                <td>{ticket.eventDays && ticket.eventDays.length > 0 ? ticket.eventDays.map(day => day ? new Date(day).toLocaleDateString("de-DE") : "").join(", ") : ""}</td>
                                <td>{ticket.createdAt && new Date(ticket.createdAt).toLocaleDateString("de-DE")}</td>
                            </tr>
                        ))
                    }
                    {
                        isLoading && <tr><td colSpan="5">Loading...</td></tr>
                    }
                </MDBTableBody>
            </MDBTable>
        </MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tabAllVisitors'}>
            <MDBTable style={{
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                msUserSelect: "none",
                userSelect: "none",
                
            }}>
                <MDBTableHead>
                    <tr>
                    <th>{t("Guest")}</th>
                    <th>{t("Company")}</th>
                    <th>{t("Visiting Days")}</th>
                    <th>{t("Booking Date")}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                        allVisisions.map((ticket) => (
                            <tr>
                                <td>{ticket.personalAddress?.salutation} {ticket.personalAddress?.firstName} {ticket.personalAddress?.lastName}</td>
                                <td>{ticket.personalAddress?.company || ""}</td>
                                <td>{ticket.eventDays && ticket.eventDays.length > 0 ? ticket.eventDays.map(day => day ? new Date(day).toLocaleDateString("de-DE") : "").join(", ") : ""}</td>
                                <td>{ticket.createdAt && new Date(ticket.createdAt).toLocaleDateString("de-DE")}</td>
                            </tr>
                        ))
                    }
                    {
                        isLoading && <tr><td colSpan="5">Loading...</td></tr>
                    }
                </MDBTableBody>
            </MDBTable>
        </MDBTabsPane>
      </MDBTabsContent>


</>
  )
}
