import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generateClient } from "aws-amplify/api"
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBBtnGroup, MDBIcon} from "mdb-react-ui-kit"
import { Link } from "react-router-dom"

export default function AllCourses() {
    const { t, i18n } = useTranslation()
    const client = generateClient()
    const [isLoading, setIsLoading] = useState(true)

    const [allCourses, setAllCourses] = useState([])

    const fetchAllCourses = async () => {
        setIsLoading(true)
        let allCourses = [];
        let nextToken = null;
        
        try {
            do {
                const { data, nextToken: newNextToken } = await client.graphql({
                    query: /* GraphQL */ `
                    query ListCourses(
                      $filter: ModelCourseFilterInput
                      $limit: Int
                      $nextToken: String
                    ) {
                      listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                          id
                          name {
                            language
                            text
                          }
                          description {
                            language
                            text
                          }
                          type
                          price
                          createdAt
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    `,
                    variables: {
                        limit: 1000,
                        nextToken
                    }
                })
                allCourses = allCourses.concat(data.listCourses.items)
                nextToken = newNextToken
            }
            while (nextToken)
        }
        catch (error) {
            console.error(error)
        }
        finally {
            setAllCourses(allCourses)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchAllCourses()
    }, [])

    // Actions
    const handleDeleteCourse = async (id) => {
        try {
            await client.graphql({
                query: /* GraphQL */ `
                mutation DeleteCourse($input: DeleteCourseInput!) {
                    deleteCourse(input: $input) {
                        id
                    }
                }
                `,
                variables: {
                    input: {
                        id
                    }
                }
            })
            setAllCourses(allCourses.filter(course => course.id !== id))
        }
        catch (error) {
            console.error(error)
        }
    }


  return (
<>
<MDBRow>
    <MDBCol>
        <MDBCard>
            <MDBCardBody>
                <MDBCardTitle>{t("All Courses")}<Link to={`/admin/insight/course/create`}><MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light'>{t("Create course")}</MDBBtn></Link></MDBCardTitle>
                <MDBTable align="middle">
                    <MDBTableHead>
                        <tr>
                            {/* <th>{t("Image")}</th> */}
                            <th>{t("Name")}</th>
                            <th>{t("Type")}</th>
                            <th>{t("Actions")}</th>
                            
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {allCourses?.map((course, index) => (
                            <tr key={index}>
                                <td>{course.name.find(n => n.language === i18n.language)?.text || course.name[0]?.text}</td>
                                <td>{course.type}</td>
                                <td>
                                    <MDBBtnGroup>
                                        <Link to={`/admin/insight/course/${course.id}`}><MDBBtn color='info' size='sm'><MDBIcon fas icon='edit' /></MDBBtn></Link>
                                        <MDBBtn color='danger' size='sm' onClick={() => handleDeleteCourse(course.id)}><MDBIcon fas icon='trash-alt' /></MDBBtn>
                                    </MDBBtnGroup>
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
</MDBRow>
</>
  )
}
