import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTable, 
  MDBTableBody, MDBTableHead, MDBBtnGroup, MDBIcon, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBModalTitle, MDBCardText,
  MDBSelect
   } from "mdb-react-ui-kit"
  import { useState, useEffect, useContext } from "react"
  import { useTranslation } from "react-i18next"
  import { generateClient } from "aws-amplify/api"
  import { UserContext } from "../../App"
  import { isoToGermanDate } from "../../utils/dateTools"
  import { deleteTicket } from "../../graphql/mutations"
  import ShowTicket from "../../components-user/tickets/ShowTicket"
  
  const client = generateClient()
  
  export default function AdminAllTickets() {
      const { user } = useContext(UserContext);
      const { t, i18n } = useTranslation()

      const [events, setEvents] = useState([]);
      const [selectedEventId, setSelectedEventId] = useState(null);
  
      const [isLoading, setIsLoading] = useState(true)
      const [tickets, setTickets] = useState([])
  
      const getEvents = async () => {
        try {
            const events = await client.graphql({
                query: /* GraphQL */ `
                query ListEvents {
                    listEvents {
                        items {
                            id
                            name {
                                text
                                language
                            }
                            startDate
                            endDate
                            status
                        }
                    }
                }
                `,
                variables: {
                    filter: {
                        startDate: {
                            ge: new Date().toISOString().substring(0, 10)
                        }
                    }
                }
            });
            setEvents(events.data.listEvents.items.filter(event => event.status === "active" && new Date(event.startDate) >= new Date()));
            return events.data.listEvents.items;
        } catch (error) {
            console.log("error on fetching events", error);
        }
    }
      const getTickets = async () => {
        let allTickets = [];
        let nextToken = null;
          try {
            do {
              
              try {
                const { data } = await client.graphql({
                    query: /* GraphQL */ `
                    query SearchTickets(
                      $filter: SearchableTicketFilterInput
                      $sort: [SearchableTicketSortInput]
                      $limit: Int
                      $nextToken: String
                      $from: Int
                      $aggregates: [SearchableTicketAggregationInput]
                    ) {
                      searchTickets(
                        filter: $filter
                        sort: $sort
                        limit: $limit
                        nextToken: $nextToken
                        from: $from
                        aggregates: $aggregates
                      ) {
                        items {
                          id
                          
                          createdByUserId
                          eventDays
                          status
                          paymentStatus
                          type
                          visitorType
                          addons

                          ticketFile {
                            url
                            s3Path
                            fileName
                            type
                            name
                          }
                          notes
                          personalAddress {
                            firstName
                            lastName
                            title
                            salutation
                            street
                            street2
                            streetNumber
                            company
                            contactPerson
                            city
                            zip
                            countryCode
                          }
                          billingAddress {
                            firstName
                            lastName
                            title
                            salutation
                            street
                            street2
                            streetNumber
                            company
                            contactPerson
                            city
                            zip
                            countryCode
                          }
                          invitationCode
                          orderNumber
                          billingEmail
                          createdAt
                          updatedAt
                          userTicketsId
                          ticketEventId
                          ticketCompanyId
                          ticketPaymentId
                        }
                        nextToken
                      }
                    }
                  `,
                  variables: { 
                    limit: 1000, 
                    nextToken: nextToken,
                    filter: {
                      ticketEventId: {
                        eq: selectedEventId
                      }
                    }
                   },
                })
                // Füge die erhaltenen Tickets der Liste aller Tickets hinzu
                allTickets = allTickets.concat(data.searchTickets.items);
                // Aktualisiere den nextToken für die nächste Iteration
                nextToken = data.searchTickets.nextToken;
              } catch (error) {
                console.error(error)
                // Füge die erhaltenen Tickets der Liste aller Tickets hinzu
                allTickets = allTickets.concat(error.data.searchTickets.items);
                // Aktualisiere den nextToken für die nächste Iteration
                nextToken = error.data.searchTickets.nextToken;

              }
              
            } while (nextToken);
              //console.log(data)
            setTickets(allTickets.filter(Boolean).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
            // const allEmails = ([...new Set(allTickets.filter(Boolean).map(ticket => ticket.user.email))])
            // console.log(allEmails.join("\r\n"))
            // console.log(allEmails.length)
            setIsLoading(false)
          } catch (error) {
          console.error(error)
          }
      }
  
      useEffect(() => {   
        getEvents()
          
      }, [])

      useEffect(() => {
        if (selectedEventId) {
          getTickets()
        }
      }, [selectedEventId])
  
  
      const handleDeleteTicket = async (id) => {
          try {
            const data = await client.graphql({
              query: deleteTicket,
              variables: { input: { id } },
              })
              console.log(data)
              setTickets(tickets.filter(ticket => ticket.id !== id))
          }
          catch (error) {
            console.error(error)
          }
      }
  
      const [viewTicketModal, setViewTicketModal] = useState(false);
      const toggleViewTicketModal = () => setViewTicketModal(!viewTicketModal);
  
      const [selectedTicket, setSelectedTicket] = useState(null);
  
    return (
  <>

  
  <MDBCard>
      <MDBCardBody>
          <MDBCardTitle>
              <h2>{t("Tickets")}</h2>
          </MDBCardTitle>
          {/* Temp info on total tickets, total tickets each day (23.04.24, 24.04.24, 25.04.24) */}
          <MDBRow className="mb-2">
            <MDBCol size="12" md="6" xl="3">
            <MDBSelect label={t("Event")}
                onChange={event => setSelectedEventId(event.value)}
                value={selectedEventId}
                data={
                    [
                        { text: t("All"), value: null },
                        ...events.map(event => ({ text: event.name.find(n => n.language === i18n.language)?.text || event.name[0]?.text, value: event.id }))
                    ]
                }
            />
            </MDBCol>
          </MDBRow>
          <MDBCardText>
          <h5>{t("Total tickets")}: {tickets.length}</h5>
          <span>23.04.24: {tickets.filter(ticket => ticket.eventDays.includes("2024-04-23")).length}</span><br />
          <span>24.04.24: {tickets.filter(ticket => ticket.eventDays.includes("2024-04-24")).length}</span><br />
          <span>25.04.24: {tickets.filter(ticket => ticket.eventDays.includes("2024-04-25")).length}</span><br />
          <span>
            Besuche aller Tage: {tickets.filter(ticket => ticket.eventDays.includes("2024-04-23")).length + tickets.filter(ticket => ticket.eventDays.includes("2024-04-24")).length + tickets.filter(ticket => ticket.eventDays.includes("2024-04-25")).length}
          </span>
          </MDBCardText>
            

          <MDBTable>
              <MDBTableHead>
                  <tr>
                  <th>{t("User")}</th>
                     
                      <th>{t("Details")}</th>
                      <th>{t("Booking date")}</th>
                      <th>{t("Actions")}</th>
                  </tr>
              </MDBTableHead>
              <MDBTableBody>
              {isLoading && <tr><td colSpan="7" className="text-center"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></td></tr>}
                  {tickets?.map((ticket, index) => (
                      <tr key={index}>
                        <td>
                        {tickets.length - index} - {ticket.personalAddress?.firstName} {ticket.personalAddress?.lastName}
                        <br />
                        {ticket.personalAddress?.company}
                        </td>
                         
                        
                          <td>{ticket.eventDays.map((day, index) => (index !== 0 ? ", " : "" ) +isoToGermanDate(day))}</td>
                          <td>{isoToGermanDate(ticket.createdAt)}</td>
                          
                          <td>
                              <MDBBtnGroup>
                                  <MDBBtn color="primary" size="sm"
                                      onClick={() => {
                                          setSelectedTicket(ticket)
                                          toggleViewTicketModal()
                                      }}
                                      >{t("View")}</MDBBtn>
                              
                                      {/* <MDBBtn color="danger" size="sm"
                                      onClick={() => {
                                          if (window.confirm(t("Are you sure you want to delete this ticket?"))) {
                                              handleDeleteTicket(ticket.id)
                                          }
                                      }}
                                      >{t("Delete")}</MDBBtn> */}
  
                              </MDBBtnGroup>
                          </td>
                      </tr>
                  ))
                  }
              </MDBTableBody>
          </MDBTable>
      </MDBCardBody>
  </MDBCard>
  
  <MDBModal open={viewTicketModal} onClose={() => setViewTicketModal(false)} position="top">
      <MDBModalDialog>
          <MDBModalContent>
          <MDBModalHeader>
              <MDBModalTitle>{t("Your ticket")}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleViewTicketModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
          <ShowTicket ticket={selectedTicket} handleDeleteTicket={handleDeleteTicket} />
             
          </MDBModalBody>
          </MDBModalContent>
      </MDBModalDialog>
  </MDBModal>
  
  </>
    )
  }
  