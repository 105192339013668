
import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBInput, MDBSelect, MDBCol, MDBCheckbox, MDBDatepicker } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";
import { Link, useSearchParams } from "react-router-dom";
import usePrevious from "../utils/usePrevious";
import { isoToGermanDate } from "../utils/dateTools";


const client = generateClient();
export default function AllLectures() {
    const { t, i18n } = useTranslation();
    const [basicActive, setBasicActive] = useState('tab1');

    const handleBasicClick = (value) => {
        if (value === basicActive) {
            return;
        }

        setBasicActive(value);
    };

    const [lectures, setLectures] = useState([]);
    const [shownLectures, setShownLectures] = useState([]);
    const [totalLectures, setTotalLectures] = useState(0);




    const [isLoading, setIsLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const extractExistingParams = (searchParams) => {
        return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
            if (value === 'true') {
                acc[key] = true;
            } else if (value === 'false') {
                acc[key] = false;
            } else {
                acc[key] = value;
            }
            return acc;
        }, {});
    }

    const [filters, setFilters] = useState({
        approved: null,
        createdAtStart: "2023-12-01T00:00:00.000Z",
        eventLecturesId: "882c49b6-ba4b-4c66-8a35-987d04af08a5",
        createdAtEnd: null,
        createdAtSort: "desc",
        status: null,
        type: null
    });

    const getLectures = async () => {
        try {
            setIsLoading(true);
            const searchLectureFilter = {};
            const searchLectureSort = {};
            if (typeof filters.approved === "boolean") {
                searchLectureFilter.approved = { eq: filters.approved };
            }
            if (filters.createdAtStart && filters.createdAtEnd) {
                searchLectureFilter.and = [{createdAt: {gt: filters.createdAtStart}}, {createdAt: {lt: filters.createdAtEnd}}];
            } else if (filters.createdAtStart) {
                searchLectureFilter.createdAt = { gt: filters.createdAtStart };
            } else if (filters.createdAtEnd) {
                searchLectureFilter.createdAt = { lt: filters.createdAtEnd };
            }
            if (filters.createdAtSort) {
                searchLectureSort.field = "createdAt";
                searchLectureSort.direction = filters.createdAtSort;
            }
            if (filters.updatedAtSort) {
                searchLectureSort.field = "updatedAt";
                searchLectureSort.direction = filters.updatedAtSort;
            }
            if (filters.status) {
                searchLectureFilter.status = { eq: filters.status };
            }
            if (filters.type) {
                searchLectureFilter.type = { eq: filters.type };
            }
            searchLectureFilter.eventLecturesId = { eq: "882c49b6-ba4b-4c66-8a35-987d04af08a5" };

            const lectures = await client.graphql({
                variables: {
                    filter: searchLectureFilter,
                    sort: searchLectureSort,
                    limit: 1000
                },
                query: /* GraphQL */ `
                query SearchLectures(
                  $filter: SearchableLectureFilterInput
                  $sort: [SearchableLectureSortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableLectureAggregationInput]
                ) {
                  searchLectures(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                        title {
                            language
                            text
                        }
                        speakers {
                            items {
                                employee {
                                    image {
                                    fileName
                                    alt
                                    }
                                }
                            }
                        }
                        subjects {
                            id
                            name {
                                language
                                text
                            }
                        } 
                        topics {
                            items {
                              topic {
                                id
                                name {
                                  language
                                  text
                                }
                              }
                            }
                        }        
                        content {
                            language
                            text
                        }
                        conditions {
                            name
                            accepted
                        }
                        image {
                            fileName
                            alt
                        }
                        pdfDe {
                            url
                            fileName
                            s3Path
                          }
                        pdfEn {
                            url
                            fileName
                            s3Path
                        }
                        company {
                            id
                            name
                            image {
                                main {
                                    fileName
                                    alt
                                }
                            }
                        }
                        conditions {
                            name
                            accepted
                        }
                        id
                        adminComment
                        companyBooth
                        session
                        room
                        type
                        presentationLanguage
                        createdAt
                        updatedAt
                        companyLecturesId
                        eventLecturesId
                        lectureMainSpeakerId
                        approved
                        status
                        startTime
                        endTime
                        __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `
            });
            setLectures(lectures.data.searchLectures.items.map(lecture => ({...lecture, topics: lecture.topics.items.map(topic => topic.topic)})));
            setShownLectures(lectures.data.searchLectures.items.map(lecture => ({...lecture, topics: lecture.topics.items.map(topic => topic.topic)})));
            setTotalLectures(lectures.data.searchLectures.total);
            setIsLoading(false);
        } catch (error) {
            console.log("error on fetching lectures", error);
            if (error.data?.searchLectures?.items) {
                setLectures(error.data.searchLectures.items);
                setShownLectures(error.data.searchLectures.items);
            }
            setIsLoading(false);
        }
    };

    const [filtersInitialized, setFiltersInitialized] = useState(false);

useEffect(() => {
    // Beim ersten Render die Filter aus den searchParams extrahieren und setzen
    const extractedParams = extractExistingParams(searchParams);
    setFilters(oldFilters => ({ ...extractedParams, createdAtStart: extractedParams.createdAtStart || oldFilters.createdAtStart , createdAtSort: extractedParams.createdAtSort || oldFilters.createdAtSort }));
    setFiltersInitialized(true); // Setze auf true, sobald die Initialisierung abgeschlossen ist
}, []); // Leere Abhängigkeiten, um den Hook nur einmal auszuführen

useEffect(() => {
    if (filtersInitialized) {
        // Aktualisiere die searchParams und rufe getLectures auf, nachdem die Filter initialisiert wurden
        const params = Object.entries(filters).reduce((acc, [key, value]) => {
            if (value !== null) {
                acc[key] = value;
            }
            return acc;
        }, {});

        setSearchParams(params);
        getLectures();
    }
}, [filters, filtersInitialized]); // Abhängigkeit von den Filters und filtersInitialized

    

    const searchLectures = (searchTerm) => {
        if (searchTerm.length > 0) {
            setShownLectures(lectures.filter(lecture => {
                if (lecture.company?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
                    return true;
                }
                if (lecture.title?.find(t => t.language === i18n.language)?.text?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
                    return true;
                }
                return false;
            }));
        } else {
            setShownLectures(lectures);
        }
    }



    const [selectedConditions, setSelectedConditions] = useState([]); // selectedConditions = ["ECV", "Podcast"
    const prevConditions = usePrevious(selectedConditions);

    const filterByConditions = async () => { // selectedConditions = ["ECV", "Podcast"]
        console.log(selectedConditions);
       
        if (selectedConditions.length > 0) {
           
            setShownLectures(shownLectures.filter(lecture => {
                if(lecture.conditions?.length > 0) {
                if (selectedConditions.includes("ECV") && selectedConditions.includes("Podcast")) {
                    return (lecture.conditions?.find(condition => condition.name === "ecv")?.accepted && lecture.conditions?.find(condition => condition.name === "podcast")?.accepted);
                } else if (selectedConditions.includes("ECV")) {
                    return lecture.conditions?.find(condition => condition.name === "ecv")?.accepted;
                } else if (selectedConditions.includes("Podcast")) {
                    return lecture.conditions?.find(condition => condition.name === "podcast")?.accepted;
                }
                return false;
            } else {
                return false;
            }
            }));
        } else {
            setShownLectures(lectures);
        }
    }

    useEffect(() => {
       // If something changed in the selectedConditions, filter the lectures
         if (prevConditions !== selectedConditions) {
        filterByConditions(selectedConditions);
        }
    }, [selectedConditions, shownLectures]);

    const downloadFile = async (fileName, path) => {
        const regex = /protected\/(eu-central-1:[a-z0-9-]+)\//i;
        const match = path.match(regex);
        const identity = match[1];
        const getUrlResult = await getUrl({
          key: fileName,
          options: {
            accessLevel: 'protected' ,
            targetIdentityId: identity,
          },
        });
        console.log('signed URL: ', getUrlResult.url);
        window.open(getUrlResult.url, '_blank');
      }


    return (
        <><MDBCard className="w-auto">
            <MDBCardBody className="flex-column">
                <MDBCardTitle>
                    {t("Lectures")}
                   
                </MDBCardTitle>
                <MDBTabs className='mb-3'>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                            Lounges 2024 Karlsruhe ({totalLectures})
                        </MDBTabsLink>
                    </MDBTabsItem>
                </MDBTabs>

                <MDBTabsContent>
                    <MDBTabsPane open={basicActive === 'tab1'}>
                    <MDBRow>
                        <MDBCol md="6">
                        <MDBInput type="text" onChange={e => searchLectures(e.target.value)} label={t("Search")} />
                        </MDBCol>
                        {/* <MDBCol size="6" md="3">
                        <MDBDatepicker
                            label={t("Start Date")}
                            inline
                            disableFuture
                            defaultValue={filters.createdAtStart && new Date(filters.createdAtStart).toLocaleDateString('en-GB')}
                            value={filters.createdAtStart && new Date(filters.createdAtStart).toLocaleDateString('en-GB')}
                            onChange={date => date && setFilters({ ...filters, createdAtStart: new Date(date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')).toISOString()})}
                          />
                        </MDBCol>
                        <MDBCol size="6" md="3">
                        <MDBDatepicker
                            label={t("End Date")}
                            inline
                            disableFuture
                            defaultValue={filters.createdAtEnd && new Date(filters.createdAtEnd).toLocaleDateString('en-GB')}
                            value={filters.createdAtEnd && new Date(filters.createdAtEnd).toLocaleDateString('en-GB')}
                            onChange={date => date && setFilters({ ...filters, createdAtEnd: new Date(date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')).toISOString()})}
                          />
                        </MDBCol> */}
                        <MDBCol md="3" className="mt-3 mt-lg-0">
                            <MDBSelect label={t("Type")}
                                onChange={type => type && setFilters({ ...filters, type: type.value})}
                                value={filters.type}
                                data={
                                    [
                                        { text: t("All"), value: null },
                                        { text: t("Marketing"), value: "marketing" },
                                        { text: t("Technical"), value: "technical" },
                                        { text: t("Educational"), value: "educational" },
                                        { text: t("Recruiting"), value: "recruiting" },
                                        { text: t("Promotion Marketing"), value: "promotion_marketing" },
                                        { text: t("Promotion Technical"), value: "promotion_technical" }
                                    ]
                                }
                            />
                        </MDBCol>
                       
                       
                        <MDBCol md="1">
                        <MDBIcon className="float-end" fas icon="sync-alt" onClick={() =>  getLectures()} />
                        </MDBCol>
                    </MDBRow>
                    
                    
                    <MDBRow className="mt-2 mt-xl-3">
                       

                        
                    </MDBRow>
                    
                        <MDBTable>
                            <MDBTableHead>
                                <tr>
                                    <th style={{minWidth: "125px"}}>{t("Date & Time")} 
                                    </th>
                                    <th>{t("Company")}</th>
                                    <th>{t("Type")}</th>
                                    <th>{t("Title")}</th>
                                    <th>{t("Speakers")}</th>
                                    <th>{t("Documents")}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                { isLoading ? <tr><td colSpan="8" className="text-center"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></td></tr>
                                : shownLectures.length > 0 ?
                                    shownLectures.map((lecture, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{lecture.startTime ? <>
                                                    {isoToGermanDate(lecture.startTime)}<br></br>
                                                    {lecture.startTime.substring(11, 16)} - {lecture.endTime.substring(11, 16)}<br></br>
                                                    {lecture.room ? t("Room") + ": " + lecture.room : t("not yet determined")}
                                                </> : t("not yet determined")}</td>
                                                <td width="300"><>
                                                    {lecture.company?.image?.main?.fileName ?
                                                        <div className='d-flex align-items-center'>
                                                            {lecture.company.image?.main?.fileName ?
                                                                <img
                                                                    src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+lecture.company.image.main.fileName}
                                                                    className="rounded-circle border"
                                                                    style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                                                    alt={lecture.company.image.alt}
                                                                />
                                                            :
                                                                <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                                                        
                                                            }
                                                            <div className='ms-3'>
                                                            <p className='fw-bold mb-1'>{lecture.company.name}</p>
                                                            <p className='text-muted mb-0'>{lecture.companyBooth}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <MDBIcon
                                                            className="rounded-circle border"
                                                            style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                textAlign: "center",
                                                                paddingTop: "12px",
                                                                fontSize: "20px",
                                                            }}
                                                            fas
                                                            icon="user-alt"
                                                        />
                                                    }
                                                </>
                                                </td>
                                                <td>{t(lecture.type)}</td>
                                                <td>{lecture.title?.find(t => t.language === i18n.language)?.text || lecture.title?.[0]?.text}</td>
                                                <td>{lecture.speakers?.items?.map((speaker, index) => <>
                                                    {speaker?.employee?.image?.fileName ?
                                                        <img
                                                            key={index}
                                                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.employee.image.fileName}`}
                                                            className="rounded-circle border"
                                                            style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                objectFit: "cover",
                                                            }}
                                                            alt={speaker.employee?.image.alt}
                                                        />
                                                        :
                                                        <MDBIcon
                                                        key={index}
                                                            className="rounded-circle border"
                                                            style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                textAlign: "center",
                                                                paddingTop: "12px",
                                                                fontSize: "20px",
                                                            }}
                                                            fas
                                                            icon="user-alt"
                                                        />
                                                    }
                                                    <br></br>
                                                </>)}</td>
                                          
                                                <td>
                                                {
                                                    lecture?.pdfDe?.fileName && 
                                                    <div className="text-center" style={{maxWidth: "200px"}}>
                                                    <MDBBtn color="link" onClick={() => downloadFile(lecture.pdfDe.fileName, lecture.pdfDe.s3Path)} size="sm" className="mb-0">
                                                        {lecture.pdfDe.fileName}
                                                    </MDBBtn>
                                                    </div>
                                                    
                                                    }
                                                    {
                                                    lecture?.pdfEn?.fileName &&
                                                    <div className="text-center" style={{maxWidth: "200px"}}>
                                                    <MDBBtn color="link" onClick={() => downloadFile(lecture.pdfEn.fileName, lecture.pdfEn.s3Path)} size="sm" className="mb-0">
                                                        {lecture.pdfEn.fileName}
                                                    </MDBBtn>
                                                    </div>
                                                    }
                                                </td>

                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan="7">
                                            {t("No lectures found")}
                                        </td>
                                    </tr>
                                }
                            </MDBTableBody>
                        </MDBTable>
                    </MDBTabsPane>
                </MDBTabsContent>
            </MDBCardBody>
        </MDBCard>

           
        </>
    )
}
