import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
const client = generateClient();
export default function RemoveEmployee({ companyId, employee, isActive, toggleModal }) {
    const { t, i18n } = useTranslation();

    const removeEmployee = async () => {
        try {
          // Find department (listEmployeeDepartments) and lecture (listLecturesSpeakers) connections and delete them first
        const departmentConnections = await client.graphql({
            variables: {filter: {employeeId: {eq: employee.id}} },
            query: /* GraphQL */ `
            query ListEmployeeDepartments(
              $filter: ModelEmployeeDepartmentsFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listEmployeeDepartments(
                filter: $filter
                limit: $limit
                nextToken: $nextToken
              ) {
                items {
                  id
                  employeeId
                  departmentId
                }
              }
            }
          `
            });
            console.log(departmentConnections);
            if (departmentConnections.data.listEmployeeDepartments.items.length > 0) {
                for (let i = 0; i < departmentConnections.data.listEmployeeDepartments.items.length; i++) {
                    const departmentConnection = departmentConnections.data.listEmployeeDepartments.items[i];

                    const deleteDepartmentConnection = await client.graphql({
                        variables: {input: {id: departmentConnection.id}},
                        query: /* GraphQL */ `
                        mutation DeleteEmployeeDepartments(
                          $input: DeleteEmployeeDepartmentsInput!
                          $condition: ModelEmployeeDepartmentsConditionInput
                        ) {
                          deleteEmployeeDepartments(input: $input, condition: $condition) {
                            id
                            employeeId
                            departmentId
                          }
                        }
                      `
                        });
                        console.log(deleteDepartmentConnection);
                }
            }

            const lectureConnections = await client.graphql({
                variables: {filter: {employeeId: {eq: employee.id}} },
                query: /* GraphQL */ `
                query ListLecturesSpeakers(
                  $filter: ModelLecturesSpeakersFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listLecturesSpeakers(
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                  ) {
                    items {
                      id
                      lectureId
                      employeeId
                    }
                  }
                }
              `
                });
                console.log(lectureConnections);
                if (lectureConnections.data.listLecturesSpeakers.items.length > 0) {
                    for (let i = 0; i < lectureConnections.data.listLectureSpeakers.items.length; i++) {
                        const lectureConnection = lectureConnections.data.listLectureSpeakers.items[i];
    
                        const deleteLectureConnection = await client.graphql({
                            variables: {input: {id: lectureConnection.id}},
                            query: /* GraphQL */ `
                            mutation DeleteLecturesSpeakers(
                              $input: DeleteLecturesSpeakersInput!
                              $condition: ModelLecturesSpeakersConditionInput
                            ) {
                              deleteLecturesSpeakers(input: $input, condition: $condition) {
                                id
                                lectureId
                                employeeId
                              }
                            }
                          `
                            });
                            console.log(deleteLectureConnection);
                    }
                }

            // Delete userEmployeeProfile

         const response = await client.graphql({
            variables: {input: {id: employee.id}},
            query: /* GraphQL */ `
            mutation DeleteEmployee(
              $input: DeleteEmployeeInput!
              $condition: ModelEmployeeConditionInput
            ) {
              deleteEmployee(input: $input, condition: $condition) {
                id
              }
            }
          `
            });
            console.log(response);
            if(toggleModal && typeof(toggleModal) === 'function') toggleModal();
        } catch (error) {
            console.log(error);
        }
    }


  return (
    <div className="text-center">
    <p>
        {t("Are you sure you want to remove this employee?")}
    </p>
    {employee && 
      <div className='d-flex align-items-center'>
    {employee?.image?.fileName ?
      <img
          src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+employee.image.fileName}
          className="rounded-circle border"
          style={{ height: "50px", width: "50px", objectFit: "contain" }}
          alt={employee.image.alt}
      />
      :
      <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
      
    }
      <div className='ms-3'>
      <p className='fw-bold mb-1'>{employee.user.title ? employee.user.title + " " : ""}{employee.user.firstName} {employee.user.middleName} {employee.user.lastName}</p>
      <p className='text-muted mb-0'>{employee.user.email}</p>
      </div>
    </div>
    }
     <MDBBtn onClick={removeEmployee} color="danger" size="sm" className="mt-4">
        {t("Remove Employee")}
      </MDBBtn>
    </div>
  )
}
