import { useState, useEffect, useContext, useRef } from "react"
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBFooter, MDBIcon,  MDBRow, MDBStepper, MDBStepperStep,  } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { UserContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import { hotjar } from "react-hotjar";

import SideCart from "./SideCart";
import Address from "./Address";
import Payment from "./Payment";
import Confirmation from "./Confirm";
import { fetchAuthSession } from "aws-amplify/auth";

async function getIdToken() {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  console.log("idToken", idToken)
  return idToken;
}

const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

export default function Checkout() {
    const client = generateClient()
    const { t, i18n } = useTranslation()
    const { user } = useContext(UserContext)
    const navigate = useNavigate()

    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const [isPlacingOrder, setIsPlacingOrder] = useState(false);
    const [params, setParams] = useState({})

    const [products, setProducts] = useState([]);
    const [orderData, setOrderData] = useState({
      buyerOrderNumber: null,
      billingEmail: null,
      vatInfo: {
        vatNumber: null
      },
      countryCode: null,
    });
    const [taxRate, setTaxRate] = useState(19);
    const [customerType, setCustomerType] = useState("private");
    const [eventBooth, setEventBooth] = useState({});
    const [billingAddress, setBillingAddress] = useState({});
    const [personalAddress, setPersonalAddress] = useState({});
    const [useProfileAddressForBilling, setUseProfileAddressForBilling] = useState(true);
    useEffect(() => {
      // Set Tax rate, if customer is business - if country is Germany, set 19% tax rate, otherwise 0%. country is personalAddress.countryCode or billingAddress.countryCode if useProfileAddressForBilling is false
      if(customerType === "business") {
        const country = useProfileAddressForBilling ? personalAddress.countryCode : billingAddress.countryCode;
        if(country === "DE") {
          setTaxRate(19);
        } else {
          setTaxRate(0);
        }
      } else {
        // If customer is private and country is in EU, set 19% tax rate, otherwise 0%
        const country = useProfileAddressForBilling ? personalAddress.countryCode : billingAddress.countryCode;
        const euCountries = ["AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GR", "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PL", "PT", "RO", "SE", "SI", "SK"];
        if(euCountries.includes(country)) {
          setTaxRate(19);
        } else {
          setTaxRate(0);
        }
      }
    }, [customerType, personalAddress, billingAddress, useProfileAddressForBilling]);

    useEffect(() => {
        if(user) {
          setPersonalAddress({
            salutation: user.salutation,
            title: user.title,
            firstName: user.firstName,
            lastName: user.lastName,
            street: user.contact?.address?.street || "",
            streetNumber: user.contact?.address?.streetNumber || "",
            zip: user.contact?.address?.zip || "",
            city: user.contact?.address?.city || "",
            countryCode: user.contact?.address?.countryCode || "",
            company: user.contact?.address?.company || ""
          });
        }
      }, [user]);

    const handleClickNext = () => {
    nextRef.current.click();
  }

  const handleClickPrev = () => {
    prevRef.current.click();
  }


    // Handle Query Params
    const [paramsInitialized, setParamsInitialized] = useState(false);
    const [routerParams, setRouterParams] = useSearchParams();
    const extractExistingParams = (searchParams) => {
        return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
            if (value === 'true') {
                acc[key] = true;
            } else if (value === 'false') {
                acc[key] = false;
            } else {
              if(acc.hasOwnProperty(key) && Array.isArray(acc[key])) {
                acc[key].push(value);
              } else if(acc.hasOwnProperty(key)) {
                acc[key] = [acc[key], value];
              } else {
                acc[key] = value;
              }
            }
            return acc;
        }, {});
    }
    useEffect(() => {
        if(!paramsInitialized) {
          console.log("routerParams", routerParams.getAll('productId'));
        const paramsQuery = extractExistingParams(routerParams);
        console.log("paramsQuery", paramsQuery);
        setParams(paramsQuery);
        setParamsInitialized(true);
        hotjar.event("Checkout initialized");
        }
    }, [routerParams]);
    useEffect(() => {
        if(paramsInitialized) {
        setRouterParams(params);
        } 
    }, [params, paramsInitialized]);

    // Get Product
    const getProduct = async (id, eventId) => {
        try {
            const response = await client.graphql({
                query: /* GraphQL */ `
                query GetProduct($id: ID!, $eventId: ID) {
                  getProduct(id: $id) {
                    id
                    name {
                      language
                      text
                    }
                    description {
                      language
                      text
                    }
                    shortDescription {
                      language
                      text
                    }
                    sku
                    type
                    price
                    eventProducts(filter: {eventEventProductsId: {eq: $eventId}}) {
                      items {
                        price
                      }
                    }
                    image {
                      main {
                        fileName
                        alt
                      }
                      additional {
                        fileName
                        alt
                      }
                      __typename
                    }
                    features {
                      name {
                        language
                        text
                      }
                      description {
                        language
                        text
                      }
                    }
                    files {
                      url
                      s3Path
                      fileName
                      type
                      name
                    }
                    allowedBuyerGroups
                    icon
                    createdAt
                    updatedAt
                    bundleBoothAvaiableAccessoriesId
                    bundleBoothIncludedAccessoriesId
                  }
                }
              `,
                variables: { 
                  id,
                  eventId
                 }
            });
            return response.data.getProduct;
        } catch (error) {
            console.error("Error fetching product", error);
            hotjar.event("Error fetching product");
            return null;
        }
    }

    const getEventBooth = async (id) => {
      try {
        const response = await client.graphql({
            query: /* GraphQL */ `
            query GetBookedBooth($id: ID!) {
              getBookedBooth(id: $id) {
                id
                event {
                  id
                  name {
                    language
                    text
                  }
                  image {
                    fileName
                    alt
                  }
                  date
                  startDate
                  endDate
                }
                boothNumber
                status
                createdAt
                updatedAt
              }
            }
          `,
            variables: {
                id
            }
        });
        console.log('getBookedBooth response:', response);
        setEventBooth(response.data.getBookedBooth);
        return response.data.getBookedBooth;
    } catch (error) {
        console.error('getBookedBooth error:', error);
    }
  }

  const fetchProducts = async () => {
    let eventId = null;
    if(params.boothId) {
      const eventBooth = await getEventBooth(params.boothId);
      eventId = eventBooth.event.id;
    }
    console.log("params.productId", params.productId)
    if(!Array.isArray(params.productId)) {
      const fetchProduct = async () => {
          let product = await getProduct(params.productId, eventId);
          if(eventId) product.price = product.eventProducts.items[0].price;
          if(product.allowedBuyerGroups && product.allowedBuyerGroups.length === 1 && product.allowedBuyerGroups.includes("business")) {
              setCustomerType("business");
          }
          setProducts([product]);
      }
      fetchProduct();
    } else {
      const fetchProducts = async () => {
          const products = await Promise.all([...new Set(params.productId)].map(async id => {
              let product = await getProduct(id, eventId);
              if(eventId) product.price = product.eventProducts.items[0].price;
              product.qty = countOccurrences(params.productId, id);
              return product;
          }));
          setProducts(products);
      }
      fetchProducts();
    }
  }

    // Get Data 
    useEffect(() => {
        if(params.productId) {
            fetchProducts();
        }
    }, [params.productId]);



    const placeOrder = async () => {
      const idToken = await getIdToken();
      setIsPlacingOrder(true);
      try {
          const productsForOrder = products.map(product => {
              return {
                  id: product.id,
                  qty: product.qty || 1,
                  discount: 0,
                  discountType: "",
                  taxRate
              }
          });

          const totalAmount = products.reduce((acc, product) => {
              return acc + (product.qty && product.qty > 1 ? product.price * product.qty : product.price);
          }, 0);

          let data = {};
          if(params.boothId) {
              data.eventBoothId = params.boothId;
          }
          if(params.eventId) {
              data.eventId = params.eventId;
          }

          const response = await fetch('https://y3vayhw2i5bqrm6fpwqz7fzfwe.appsync-api.eu-central-1.amazonaws.com/graphql', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': idToken
              },
              body: JSON.stringify({
                  query: /* GraphQL */ `
                  mutation PlaceOrder(
                    $order: String
                  ) {
                    placeOrder(
                      order: $order
                    )
                  }
                `,
                  variables: {
                      order: JSON.stringify({
                          buyerOrderNumber: orderData.buyerOrderNumber ? (orderData.buyerOrderNumber === "not required" ? "" : orderData.buyerOrderNumber) : null,
                          notes: orderData.notes,
                          deliveryAddress: personalAddress,
                          billingAddress: useProfileAddressForBilling ? personalAddress : billingAddress,
                          billingEmail: orderData.billingEmail  ? (orderData.billingEmail === "not required" ? "" : orderData.billingEmail) : null,
                          products: productsForOrder,
                          isBusiness: customerType === "business",
                          vatInfo: orderData.vatInfo.vatNumber ? orderData.vatInfo : null,
                          currency: "EUR",
                          totalAmount: totalAmount + (totalAmount * taxRate / 100),
                          invoiceAmount: totalAmount + (totalAmount * taxRate / 100),
                          invoiceAmountNet: totalAmount,
                          taxRate,
                          paymentProvider: "invoice",
                          ...(params.companyId && { companyOrdersId: params.companyId }),
                          ...(params.adminId && { adminOrdersId: params.adminId }),
                          ...(params.notes && { notes: params.notes }),
                          ...(Object.keys(data).length > 0 && { data: JSON.stringify(data) }),
                      })
                  }
              })
          });
      
          const responseData = await response.json();
          hotjar.event("Order placed");
          return responseData.data.placeOrder;
          

      } catch (error) {
          console.log("error", error)
          hotjar.event("Error creating order");
      }
      setIsPlacingOrder(false);
  }

    const handleConfirmOrder = async () => {
        const orderId = await placeOrder();
        if(orderId) {
            navigate(`/order/confirmation/${orderId}`);
        }
    }




  return (
<>
<div className="d-none">
<MDBBtn ref={prevRef}>{t("Previous")}</MDBBtn>
<MDBBtn ref={nextRef}>{t("Next")}</MDBBtn>
</div>
<MDBRow>
    <MDBCol md="8" xl="8">
    <MDBCard>
        <MDBCardBody className="px-0 px-lg-3">
            <MDBStepper linear type="horizontal" externalNext={nextRef} externalPrev={prevRef}>
                <MDBStepperStep itemId={1} headText={t("Your information")} headIcon={<MDBIcon fas icon="user" />}  >
                    <Address
                    products={products}
                    user={user}
                    customerType={customerType}
                    setCustomerType={setCustomerType}
                    params={params}
                    setParams={setParams}
                    orderData={orderData}
                    setOrderData={setOrderData}
                    useProfileAddressForBilling={useProfileAddressForBilling}
                    setUseProfileAddressForBilling={setUseProfileAddressForBilling}
                    billingAddress={billingAddress}
                    setBillingAddress={setBillingAddress}
                    personalAddress={personalAddress}
                    setPersonalAddress={setPersonalAddress}
                    handleClickPrev={handleClickPrev}
                    handleClickNext={handleClickNext}
                    // validations={validations}
                    // showValidations={showValidations}
                    // setShowValidations={setShowValidations}

                    />
                </MDBStepperStep>
                <MDBStepperStep itemId={2} headText={t("Payment")} headIcon={<MDBIcon fas icon="credit-card" />} >
                    <Payment
                        handleClickPrev={handleClickPrev}
                        handleClickNext={handleClickNext}
                    />
                </MDBStepperStep>
                <MDBStepperStep itemId={3} headText={t("Confirmation")} headIcon={<MDBIcon fas icon="check" />} >
                <Confirmation
                user={user}
                customerType={customerType}
                products={products}
                taxRate={taxRate}
                params={params}
                personalAddress={personalAddress}
                setPersonalAddress={setPersonalAddress}
                useProfileAddressForBilling={useProfileAddressForBilling}
                setUseProfileAddressForBilling={setUseProfileAddressForBilling}
                billingAddress={billingAddress}
                setBillingAddress={setBillingAddress}
                handleConfirmOrder={handleConfirmOrder}
                bookingInProgress={false}
                handleClickPrev={handleClickPrev}
                handleClickNext={handleClickNext}
                isPlacingOrder={isPlacingOrder}
                // validations={validations}
                // showValidations={showValidations}
                // setShowValidations={setShowValidations}
                // errors={errors}
                />
                </MDBStepperStep>
            </MDBStepper>
        </MDBCardBody>
    </MDBCard>
        
    </MDBCol>
    <MDBCol md="4" xl="4">
        <SideCart params={params} customerType={customerType} taxRate={taxRate} products={products} hideDescription={true} />
    </MDBCol>
</MDBRow>
 <MDBFooter className='text-center text-white border-top mt-5'>
      <MDBContainer className='pt-2'>
        <section>
          <MDBBtn
            rippleColor="dark"
            color='link'
            className='text-dark m-1'
            href='https://cleanroom-processes.de/widerrufsrecht/'
            target="_blank"
            role='button'
          >
            {t("Right of withdrawal")}
          </MDBBtn>

          <MDBBtn
            rippleColor="dark"
            color='link'
            className='text-dark m-1'
            href='https://cleanroom-processes.de/datenschutzerklarung/'
            target="_blank"
            role='button'
          >
             {t("Data protection")}
          </MDBBtn>

          <MDBBtn
            rippleColor="dark"
            color='link'

            className='text-dark m-1'
            href='https://cleanroom-processes.de/agb-cleanroom-processes/'
            target="_blank"
            role='button'
          >
             {t("Terms and Conditions")}
          </MDBBtn>

          <MDBBtn
            rippleColor="dark"
            color='link'
            className='text-dark m-1'
            href='https://cleanroom-processes.de/impressum/'
            target="_blank"
            role='button'
          >
             {t("Imprint")}
          </MDBBtn>
        </section>
      </MDBContainer>

    </MDBFooter>
</>
  )
}
