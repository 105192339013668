
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCheckbox, MDBCol, MDBRow } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next"
import { isoToGermanDate } from "../../../utils/dateTools";
import { useState } from "react";
import Cart from "./SideCart";

export default function Confirmation({user, event, params, personalAddress, useProfileAddressForBilling, setUseProfileAddressForBilling, 
    billingAddress, handleConfirmOrder, bookingInProgress, customerType, handleClickNext, errors, products, taxRate, isPlacingOrder}) {
    const { t, i18n } = useTranslation()

    const [tocAccepted, setTocAccepted] = useState(false);

    const handleClickButton = () => {
        if (tocAccepted) handleClickNext();
    }

  return (
<>
<h4>{t("Confirmation")}</h4>
<MDBRow className="mx-1">
    <MDBCol size="12">
        {/* Addresses */}
        <MDBCard className="mt-2 mt-lg-4">
            <MDBCardBody>
                <MDBRow>
                    <MDBCol size="12" lg="4">
                    <h6>{t("Personal information")}</h6>
                    {personalAddress?.company}
                    <br></br>
                    {personalAddress?.title ? personalAddress.title + " " : ""}{personalAddress?.firstName} {personalAddress?.middleName} {personalAddress?.lastName}
                    <br></br>
                    {personalAddress?.street} {personalAddress?.streetNumber}
                    <br></br>
                    {personalAddress?.zip} {personalAddress?.city}
                    <br></br>
                    {personalAddress?.countryCode}
                    </MDBCol>
                    <MDBCol size="12" lg="4">
                    <h6>{t("Billing address")}</h6>
                    {useProfileAddressForBilling ?
                        <>
                        {personalAddress?.company}
                        <br></br>
                        {personalAddress?.title ? personalAddress?.title + " " : ""}{personalAddress?.firstName} {personalAddress?.middleName} {personalAddress?.lastName}
                        <br></br>
                        {personalAddress?.street} {personalAddress?.streetNumber}
                        <br></br>
                        {personalAddress?.zip} {personalAddress?.city}
                        <br></br>
                        {personalAddress?.countryCode}
                        </>
                        :
                        <>
                        {billingAddress.company}
                        <br></br>
                        {billingAddress.firstName} {billingAddress.lastName}
                        <br></br>
                        {billingAddress.street} {billingAddress.streetNumber}
                        <br></br>
                        {billingAddress.zip} {billingAddress.city}
                        <br></br>
                        {billingAddress.countryCode}
                        </>
                    }
                    </MDBCol>
                    <MDBCol size="12" lg="4">
                    <h6>{t("Payment method")}</h6>
                    {t("Invoice")}
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
   <MDBCol size="12" className="mt-3">
   <Cart taxRate={taxRate} customerType={customerType} products={products} />
    </MDBCol>
</MDBRow>
<MDBRow>
    <MDBCol size="12">
        <div className="mt-2 mt-lg-4 text-start mx-auto" style={{width: "fit-content"}}>
            <MDBCheckbox label={
                <>
                {t("I accept the ")}
                <a href={products.find(p => p.type === "event_booth") ? "https://cleanroom-processes.de/agb-lounges-aussteller/" : "https://cleanroom-processes.de/agb/"} target="_blank" rel="noreferrer">{t("terms and conditions")}</a>
                {t(" and the ")}
                <a href="https://cleanroom-processes.de/datenschutzerklarung/" target="_blank" rel="noreferrer">{t("privacy policy")}</a>
                </>}
                checked={tocAccepted}
                onChange={e => setTocAccepted(e.target.checked)}
                />
        </div>
    </MDBCol>
    <MDBCol size="12" className="mx-auto" style={{maxWidth: "500px"}}>
    {isPlacingOrder ?
        <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    :       
        <MDBBtn disabled={!tocAccepted} color="success" className="mt-2 mt-lg-4" style={{width: "100%"}} onClick={() => handleConfirmOrder()}>{t("Book now")}</MDBBtn>
    }
    {errors?.length > 0 && (
        <div className="alert alert-danger mt-2 mt-lg-4">
            {errors.map((error, index) => {
                return (
                    <p key={index}>{error}</p>
                )
            })}
        </div>
    )}
    </MDBCol>
</MDBRow>
</>
  )
}
