import { useContext, useState, useEffect } from "react"
import { UserContext } from "../App"
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBCardText, MDBCheckbox, MDBListGroup, MDBListGroupItem, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBBtn, MDBModalBody, MDBModalFooter, MDBCardImage, MDBDropdownItem } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { signOut } from "aws-amplify/auth";

import EditProfile from "../components-user/EditProfile";
import EditEmployeeProfile from "../components-user/employee-profile/EditEmployeeProfile";
import EditCompanyProfile from "../components-user-admin/manage-company/Profile";
import EditCompanyBilling from "../components-user-admin/manage-company/Billing";
import EditCompanyEmployees from "../components-user-admin/manage-company/Persons";
import { Link } from "react-router-dom";

const client = generateClient();


function isoToGermanDate(isoString) {
    // Parse the ISO string into a Date object
    const date = new Date(isoString);

    // Format the date as DD.MM.YYYY
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

export default function Dashboard() {
    const { t, i18n } = useTranslation();
    const { user, setUserCompletedOnboarding} = useContext(UserContext);

    // Onboarding Modal
    const [openOnboardingModal, setOpenOnboardingModal] = useState(false);
    const toggleOnboardingModal = () => setOpenOnboardingModal(!openOnboardingModal);
    const [savingStep, setSavingStep] = useState(false);
    const [activeOnboardingStep, setActiveOnboardingStep] = useState("personalProfile");
    const [completedOnboardingSteps, setCompletedOnboardingSteps] = useState({
        personalProfile: false,
        companyProfile: false,
        employeeProfile: false
    });
    const [toggleSaveOnboardingSteps, setToggleSaveOnboardingSteps] = useState({
        personalProfile: false,
        companyProfile: false,
        employeeProfile: false
    });

    // useEffect(() => {
    //     if(user.hasCompletedOnboarding) {
    //         console.log("user has completed onboarding", user.hasCompletedOnboarding);
    //         setOpenOnboardingModal(false);
    //     } else if (user.id) {
    //         console.log("user has not completed onboarding", user.hasCompletedOnboarding);
    //         setOpenOnboardingModal(true);
    //              if(user.hasCompletedOnboarding) {
    //                 console.log("user has completed onboarding", user.hasCompletedOnboarding);
    //                 setOpenOnboardingModal(false);
    //                 // setUserCompletedOnboarding(true);
    //             } else {
    //                 setOpenOnboardingModal(true);
    //             }

    //           if(user.employeeProfiles?.items?.length === 0) {
    //             setCompletedOnboardingSteps(old => ({
    //                 ...old,
    //                 employeeProfile: true,
    //                 companyProfile: true
    //             }));
    //           }
    //           if((user.employeeProfiles?.items?.length > 0) && (user.employeeProfiles?.items?.findIndex(i => i.role === "admin") < 0)) {
    //             setCompletedOnboardingSteps(old => ({
    //                 ...old,
    //                 companyProfile: true
    //             }));
    //           }
    //     }
    // }, [user])
    

   

    const updateUserCompletedOnboarding = async () => {
        try {
            const userRes = await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateUser(
                  $input: UpdateUserInput!
                  $condition: ModelUserConditionInput
                ) {
                  updateUser(input: $input, condition: $condition) {
                    id
                    hasCompletedOnboarding
                  }
                }
              `,
                variables: { input: {
                    id: user.id,
                    hasCompletedOnboarding: true
                } }
            });
        setUserCompletedOnboarding(true);
        } catch (error) {
            console.warn("error updating user", error);
        }
    }

    useEffect(() => {
        if(completedOnboardingSteps.personalProfile
        && completedOnboardingSteps.employeeProfile
        && completedOnboardingSteps.companyProfile) {
            updateUserCompletedOnboarding()
            window.scrollTo(0, 0);
        }
    }, [completedOnboardingSteps])
    
    
    const handleChangeComplete = (section) => {
        setCompletedOnboardingSteps({
            ...completedOnboardingSteps,
            [section]: true
        });
        setToggleSaveOnboardingSteps({
            ...toggleSaveOnboardingSteps,
            [section]: false
        })
        window.scrollTo(0, 0);
        setSavingStep(false);
    }

    const handleClickNextButton = () => {
        if(completedOnboardingSteps.personalProfile === false) {
            setToggleSaveOnboardingSteps({
                ...toggleSaveOnboardingSteps,
                personalProfile: true
            });
            setSavingStep(true);

        }
        if(completedOnboardingSteps.personalProfile === true && completedOnboardingSteps.companyProfile === false) {
            setToggleSaveOnboardingSteps({
                ...toggleSaveOnboardingSteps,
                companyProfile: true
            });
            setSavingStep(true);

        }
        if(completedOnboardingSteps.personalProfile === true && completedOnboardingSteps.employeeProfile === false && completedOnboardingSteps.companyProfile === true) {
            setToggleSaveOnboardingSteps({
                ...toggleSaveOnboardingSteps,
                employeeProfile: true
            });
            setSavingStep(true);
            
        }
        // if(completedOnboardingSteps.personalProfile === true && completedOnboardingSteps.employeeProfile === true && completedOnboardingSteps.companyProfile === true && completedOnboardingSteps.employees === false) {
        //     setToggleSaveOnboardingSteps({
        //         ...toggleSaveOnboardingSteps,
        //         employees: true
        //     });
        //     setCompletedOnboardingSteps({
        //         ...completedOnboardingSteps,
        //         employees: true
        //     });
        // }
    }



    const handleClickPersonalProfile = () => {
        setCompletedOnboardingSteps({
            ...completedOnboardingSteps,
            personalProfile: true
        });
    }

    const [news, setNews] = useState([]);

    const fetchNews = async () => {
        try {
            const newsData = await client.graphql({
                query: /* GraphQL */ `
                query ListCMSNews(
                  $filter: ModelCMSNewsFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listCMSNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                        id
                        type
                        dateTime
                        topic
                        isHero
                        createdAt
                        updatedAt
                        companyNewsId
                        employeeNewsId
                        cMSNewsCompanyId
                        cMSNewsEmployeeId
                        images {
                            main {
                                alt
                                fileName
                            }
                        }
                        name {
                            language
                            text
                        }
                        excerpt {
                            language
                            text
                        }
                        content {
                            language
                            text
                        }
                        __typename
                  }
                }
            }
              `,
                variables: { limit: 10 }
            });
            const newsList = newsData.data.listCMSNews.items;
            console.log('news list', newsList);
            setNews(newsList);
        } catch (error) {
            console.log('error on fetching news', error);
        }
    };

    const [events, setEvents] = useState([]);

    const fetchEvents = async () => {
        try {
            const eventData = await client.graphql({
                query: /* GraphQL */ `
                query ListEvents(
                  $filter: ModelEventFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                      id
                      description
                      image {
                        fileName
                        alt
                      }
                      location {
                        address {
                            city
                        }
                      }
                      status
                      date
                      startDate
                      endDate
                      x4Id
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                }
              `,
                variables: { filter: { date: { gt: new Date().toISOString() }} }
            });
            const eventList = eventData.data.listEvents.items;
            console.log('event list', eventList);
            setEvents(eventList);
        } catch (error) {
            console.log('error on fetching events', error);
        }
    }

    const [todos, setTodos] = useState([]);

    const fetchTodos = async () => {
        try {
            const todoData = await client.graphql({
                query: /* GraphQL */ `
                query ListToDos(
                  $filter: ModelToDoFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listToDos(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                            id
                            user {
                                id
                            }
                            company {
                                id
                                name
                                image {
                                    main {
                                        fileName
                                        alt
                                    }
                                }
                            }
                            title
                            description
                            status
                            dueDate
                            startDate
                            priority
                            createdBy {
                                id
                            }
                            createdAt
                            updatedAt
                            __typename
                        }
                    }
                }
                `,
                variables: { filter: { 
                    or : [
                        { userTodosId: { eq: user.id } },
                        ...user.employeeProfiles?.items?.map(e => ({ companyTodosId: { eq: e.company.id } }))
                    ]
                 } }
            });
            const todoList = todoData.data.listToDos.items.filter(t => t.company.id).sort((a, b) => a.priority - b.priority);;
            console.log('todo list', todoList);
            setTodos(todoList);
        } catch (error) {
            console.log('error on fetching todos', error);
        }
    }

    // Update Todo in backend when checked or unchecked
    const updateTodo = async (todo) => {
        try {
            const todoData = await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateToDo(
                  $input: UpdateToDoInput!
                  $condition: ModelToDoConditionInput
                ) {
                  updateToDo(input: $input, condition: $condition) {
                        id
                        user {
                            id
                        }
                        company {
                            id
                            name
                            image {
                                main {
                                    fileName
                                    alt
                                }
                            }
                        }
                        title
                        description
                        status
                        dueDate
                        priority
                        createdBy {
                            id
                        }
                        createdAt
                        updatedAt
                        __typename
                    }
                }
                `,
                variables: {
                    input: {
                        id: todo.id,
                        status: todo.status
                    }
                }
            });
            const todoList = [...todos];
            const todoIndex = todoList.findIndex(t => t.id === todo.id);
            todoList[todoIndex] = todoData.data.updateToDo.sort((a, b) => a.priority - b.priority);
            setTodos(todoList);
        } catch (error) {
            console.log('error on updating todo', error);
        }
    }

    const handleCheckTodo = (i) => {
        const newTodos = [...todos];
        newTodos[i].status = newTodos[i].status === "completed" ? "open" : "completed";
        setTodos(newTodos);
        updateTodo({
            id: newTodos[i].id,
            status: newTodos[i].status
        });
    }

    useEffect(() => {
        if (user && todos.length === 0) {
            fetchTodos();
        }
    }
    , [user]);
            

    useEffect(() => {
        if(events.length === 0) {
            fetchEvents();
        }
        
    }, []);

    // Todos

  
  return <>
  <span data-hj-allow className="float-end text-muted" style={{marginTop: "-50px"}}>{user.username}</span>
    <MDBRow>
        <MDBCol size="12" md="8">
            
                    {/* <MDBCardTitle>{t("News")}</MDBCardTitle>
                    <MDBRow>
                        {news && news.length > 0 && news.map((newsItem, i) => (
                            <MDBCol size="12" key={i}>
                                <MDBCard className="mb-2">
                                    <MDBCardBody>
                                        <MDBCardTitle>{newsItem.name.find(n => n.language === i18n.language).text}</MDBCardTitle>
                                        <MDBCardText>{newsItem.content.find(c => c.language === i18n.language).text.substring(0, 200)}</MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        ))}
                    </MDBRow> */}

                    <MDBCard>
                        <MDBCardBody>
                            <MDBCardText>
                            {/* <MDBBtn color="success" onClick={() => setOpenOnboardingModal(true)}>{t("Onboarding Starten")}</MDBBtn> */}
                            <h4>Willkommen bei Cleanroom Processes!</h4>
                           
                            <p>Schön, dass du hier bist.</p>
                            <p>
                            Upgrade jetzt dein Ticket um Zugriff zu den Vortrags-Videos zu erhalten:
                            </p>
                            {/* <ul>
                                    <li>Vorträge können vom 11. Dezember 2023 bis 24. Januar 2024 eingereicht werden, danach leider nicht mehr
                                    </li>
                                    <li>Für deine Vorträge benötigst du:
                                    <ul>
                                        <li>Titel (max. 60 Zeichen), 5 Unterpunkte (je max. 60 Zeichen), Textbeschreibung (min. 500 Zeichen), themenbezogenes Bild, Zuordnung des Referenten.</li>
                                        <li>Alles weitere siehst du im Eingabebereich der Vorträge</li>
                                    </ul>
                                    </li>
                                   
                                    
                                </ul> */}
                                <Link to={"/bookings"}>
                                        <MDBBtn color="secondary" className="mb-3">Ticket upgraden</MDBBtn>
                                    </Link>
                                {/* <ul>
                                <li>Newsartikel einreichen
                                    </li>
                                    <li>Für deine Newsartikel benötigst du:
                                    <ul>
                                        <li>Titel (max. 60 Zeichen), Themenbereich, Textbeschreibung (min. 1000 Zeichen), themenbezogenes Bild, Zuordnung des Ansprechpartners.</li>
                                    </ul>
                                    </li>
                                </ul>
                                {
                                    user.employeeProfiles?.items?.[0]?.company?.id &&
                                    <Link to={"/mycompany/"+ user.employeeProfiles?.items[0].company.id +"/news"}>
                                        <MDBBtn color="secondary" className="mb-3">News einreichen</MDBBtn>
                                    </Link>
                                }
                                <ul>
                                <li>Aktionen einreichen
                                    </li>
                                    <li>Für deine Aktionen benötigst du:
                                    <ul>
                                    <li>Titel (max. 60 Zeichen), 3 Unterpunkte (je max. 60 Zeichen), Textbeschreibung (min. 500 Zeichen), themenbezogenes Bild, Zuordnung der vortragenden Person.</li>
                                    </ul>
                                    </li>
                                </ul>
                                {
                                    user.employeeProfiles?.items?.[0]?.company?.id &&
                                    <Link to={"/mycompany/"+ user.employeeProfiles?.items[0].company.id +"/actions"}>
                                        <MDBBtn color="secondary" className="mb-3">Aktionen einreichen</MDBBtn>
                                    </Link>
                                }
                                
                                
                            <p className="fw-bold">
                            Bald kannst du auf der neuen Plattform:
                            </p>
                            <ul>
                                <li>Recruiting-Vorträge einreichen.</li>
                            </ul> */}
                            {/* <p className="fw-bold">Das gibt es für dich zu tun:</p>
                            <MDBListGroup style={{ minWidth: '22rem' }} light>
                                <MDBListGroupItem noBorders>
                                <MDBRow>
                                    <MDBCol size="2" className="text-center">
                                        <div class="badge bg-secondary bg-opacity-25 p-3 rounded-circle" style={{width: "45px", height: "45px"}}>
                                            1
                                        </div>
                                    </MDBCol>
                                    <MDBCol size="10">
                                        <p className="fw-bold">Dein Persönliches Profil</p>
                                        <p>Prüfe ob alle Informationen korrekt sind und ergänze oder korrigiere falsche oder fehlende Angaben.</p>
                                    <p className="text-muted">Klicke dazu links unter <span className="fw-bold">Meine Profile</span> auf <span className="fw-bold">Persönliches Profil</span>.</p>
                                    </MDBCol>
                                </MDBRow>
                               
                                </MDBListGroupItem>
                                <MDBListGroupItem noBorders>
                                <MDBRow>
                                    <MDBCol size="2" className="text-center">
                                        <div class="badge bg-secondary bg-opacity-25 p-3 rounded-circle" style={{width: "45px", height: "45px"}}>
                                           2
                                        </div>
                                    </MDBCol>
                                    <MDBCol size="10">
                                        <p className="fw-bold">Das Profil deines Unternehmens</p>
                                        <p>Ebenso ist es wichtig, das Profil deines Unternehmens zu überprüfen und sicherzustellen, dass alle Informationen korrekt sind. </p>
                                        <p className="text-muted">Klicke dazu links unter dem Name deiner Firma auf <span className="fw-bold">Unternehmensprofil</span>.</p>
                                    </MDBCol>
                                </MDBRow>
                               
                                </MDBListGroupItem>
                                <MDBListGroupItem noBorders>
                                <MDBRow>
                                    <MDBCol size="2" className="text-center">
                                        <div class="badge bg-secondary bg-opacity-25 p-3 rounded-circle" style={{width: "45px", height: "45px"}}>
                                           3
                                        </div>
                                    </MDBCol>
                                    <MDBCol size="10">
                                        <p className="fw-bold">Ist dein Team vollständig?</p>
                                        <p>Zuletzt empfehlen wir, die Mitarbeiter deines Unternehmens auf Vollständigkeit zu überprüfen und falls notwendig fehlende Mitarbeiter einzuladen.</p>
                                        <p className="text-muted">Klicke dazu links unter dem Name deiner Firma auf <span className="fw-bold">Mitarbeiter</span>.</p>
                                    </MDBCol>
                                </MDBRow>
                               
                                </MDBListGroupItem>
                                <MDBListGroupItem noBorders>
                                <MDBRow>
                                    <MDBCol size="2" className="text-center">
                                        <div class="badge bg-secondary bg-opacity-25 p-3 rounded-circle" style={{width: "45px", height: "45px"}}>
                                            4
                                        </div>
                                    </MDBCol>
                                    <MDBCol size="10">
                                        <p className="fw-bold">Vorträge einreichen</p>
                                        <p>Sehr gut! Jetzt kann dein Unternehmen Vorträge für die LOUNGES 2024 in Karlsruhe einreichen. </p>
                                        <p className="text-muted">Klicke dazu links unter dem Name deiner Firma auf <span className="fw-bold">Vorträge</span>.</p>
                                    </MDBCol>
                                </MDBRow>
                               
                                </MDBListGroupItem>
                            </MDBListGroup> */}

                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                
        </MDBCol>
        <MDBCol size="12" md="4">
            <MDBRow>
            {
                todos && todos.length > 0 &&
                <MDBCol size="12" className="mt-2 mt-md-0 px-0">
                <h5>{t("To do's")}</h5>
                    <MDBCard>
                        <MDBCardBody>
                            
                            <MDBRow>
                                {todos && todos.length > 0 && todos.map((todo, i) => (
                                    <MDBCol size="12" key={i}>
                                        <MDBCard className="mb-2 bg-opacity-10" background={todo.status === "completed" ? "success" : todo.startDate ? "grey" : "danger"}>
                                            <MDBCardBody className="p-2">
                                            <MDBRow>
                                            <MDBCol size="10">
                                                <MDBCardText>{todo.startDate ? "Ab " +  isoToGermanDate(todo.startDate) + " - " : ""}{todo.title}</MDBCardText>
                                                </MDBCol>
                                                <MDBCol size="2">
                                                {!todo.startDate && <MDBCheckbox checked={todo.status === "completed"} onChange={() => handleCheckTodo(i)} />}
                                                </MDBCol>
                                                </MDBRow>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                ))}
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            }
                
                <MDBCol size="12" className="mt-2 mt-md-3">
                <h5>{t("Upcoming Events")}</h5>
                            <MDBRow>
                                {events && events.length > 0 && events.map((event, i) => (
                                    <MDBCol size="12" key={i}>
                                    <a href={i === 0 ? "https://cleanroom-processes.de/lounges-karlsruhe-2024/" : ""} target="_blank" rel="noreferrer">
                                        <MDBCard className="mb-2">
                                        {event.image?.fileName &&<MDBCardImage src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/eventImages/" + event.image.fileName} position='top' alt='...' />}
                                            <MDBCardBody>
                                                <MDBCardTitle>{event.description}</MDBCardTitle>
                                                <MDBCardText>
                                                {isoToGermanDate(event.startDate)} - {isoToGermanDate(event.endDate)}<br></br>
                                                {event.location?.address?.city}</MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </a>
                                    </MDBCol>
                                ))}
                            </MDBRow>
                </MDBCol>
            </MDBRow>
        </MDBCol>
    </MDBRow>

<MDBModal staticBackdrop open={openOnboardingModal} setOpen={setOpenOnboardingModal}> 
  <MDBModalDialog centered size="fullscreen">
      <MDBModalContent style={{backgroundColor: "#fafafa"}}>
          <MDBModalHeader>

              <MDBModalTitle>Willkommen bei Cleanroom Processes!</MDBModalTitle>
                <MDBBtn onClick={() => signOut()}>Logout</MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            {
                completedOnboardingSteps.personalProfile === false &&
                <>
                <p>Wir freuen uns, dich auf unserer Plattform zu begrüßen.</p>
                <p className="fw-bold">
                Bitte vervollständige dein Profil.
                </p>
                <div className="mx-xxl-5">
                    <EditProfile presetUserId={user.id} handleChangeComplete={handleChangeComplete} toggleSaveChanges={toggleSaveOnboardingSteps.personalProfile} />
                </div>
                </>
            }
            {
                completedOnboardingSteps.personalProfile === true
                && completedOnboardingSteps.employeeProfile === false
                && completedOnboardingSteps.companyProfile === false
                && user.employeeProfiles?.items?.length > 0 && user.employeeProfiles?.items?.[0].role === "admin"
                
                &&
                <>
                <p className="fw-bold">
                Du wurdest als Administrator eines Unternehmens angegeben. Bitte überprüfe die Angaben deines Unternehmens:
                </p>
                {/* <p>Du bist nicht der Administrator dieses Unternehmens? Klicke hier.</p> */}
                <div className="mx-xxl-5">

                        <EditCompanyProfile handleChangeComplete={handleChangeComplete} toggleSaveChanges={toggleSaveOnboardingSteps.companyProfile} isActive={true} presetCompanyId={user.employeeProfiles.items[0].company?.id} />

                    <div className="mt-3">
                    <h5>Rechnungsstellung</h5>
                    <EditCompanyBilling toggleSaveChanges={toggleSaveOnboardingSteps.companyProfile} isActive={true} presetCompanyId={user.employeeProfiles.items[0].company?.id} />
                    </div>
                    
                    <EditCompanyEmployees isOnboarding={true} toggleSaveChanges={toggleSaveOnboardingSteps.companyProfile} isActive={true} presetCompanyId={user.employeeProfiles.items[0].company?.id} />

                </div>
                </>
            }
            {
                completedOnboardingSteps.personalProfile === true 
                && completedOnboardingSteps.companyProfile === true 
                && completedOnboardingSteps.employeeProfile === false
                && user.employeeProfiles?.items?.length > 0
                &&
                <>
                <p className="fw-bold">
                Wir haben zu deinem Account ein Mitarbeiterprofil gefunden. Mit deinem Mitarbeiterprofil stellst du dich im Gegensatz zu deinem persönlichen Profil als Mitarbeiter des Unternehmens vor. Du kannst mehrere Mitarbeiterprofile besitzten, solltest du mehrere Firmen vertreten.
                Bitte überprüfe deine Angaben:
                </p>
                <div className="mx-xxl-5">
                <MDBCard className="mx-xxl-5">
                    <MDBCardBody className="px-3 px-lg-5 mx-xxl-5">
                        <EditEmployeeProfile isOnboarding={true} handleChangeComplete={handleChangeComplete} toggleSaveChanges={toggleSaveOnboardingSteps.employeeProfile} employeeId={user.employeeProfiles?.items?.[0].id} isActive={true} />
                    </MDBCardBody>
                </MDBCard>
                    
                </div>
                </>
            }
           
           
                            
               
          </MDBModalBody>
          <MDBModalFooter className="justify-content-center">

            {/* <MDBBtn color="primary" className="mb-3" onClick={() => setCompletedOnboardingSteps({ personalProfile: false,employeeProfile: false,companyProfile: false,employees: false})}>Reset</MDBBtn> 
            <MDBBtn color="primary" className="mb-3" onClick={() => setOpenOnboardingModal(false)}>{t("CloseModal")}</MDBBtn>  */}
            {
                savingStep ? 
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                :
                <MDBBtn color="success" className="mb-3" onClick={() => handleClickNextButton()}>{t("Save")}</MDBBtn>
            }
           
          </MDBModalFooter>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
</>
    
  
}
