import React from "react";
import { useState, useEffect } from "react";
import {
  MDBCol,
  MDBRow,
  MDBTypography,
  MDBIcon,
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBCheckbox,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInputGroup,
  MDBTable,
  MDBTableBody,
  MDBAlert,
  MDBSwitch,
  MDBInput,
} from "mdb-react-ui-kit";

import AddEmployees from "../../components-user-admin/manage-company/AddEmployees"
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

//props: isMarketing: boolean

export default function SelectEmployee({ companyId, selectedEmployees, setSelectedEmployees, maxSelect }) {
  const { t, i18n } = useTranslation();

  const [employees, setEmployees] = useState([]);
  const [alertDanger, setAlertDanger] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [employeeSearchInput, setEmployeeSearchInput] = useState("");
  const [addEmployeesModal, setAddEmployeesModal] = useState(false);


  const getEmployees = async () => {
    try {
      const employees = await client.graphql({
        query: /* GraphQL */ `
        query SearchEmployees(
          $filter: SearchableEmployeeFilterInput
          $sort: [SearchableEmployeeSortInput]
          $limit: Int
          $nextToken: String
          $from: Int
          $aggregates: [SearchableEmployeeAggregationInput]
        ) {
          searchEmployees(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
            from: $from
            aggregates: $aggregates
          ) {
            items {
              id
              user {
                id
                firstName
                lastName
                middleName
              }
              department {
                items {
                  department {
                    name {
                      language
                      text
                    }
                  }
                }
              }
              role
              status
              image {
                fileName
                alt
              }
            }
            nextToken
            total
            }
          }
        
        `,
        variables: {
          filter: {
            companyEmployeesId: {eq: companyId}
          },
          limit: 5000
        },
      });
      setEmployees(employees.data.searchEmployees.items);
    } catch (error) {
      console.log("error on fetching employees", error)
    }
  };

  useEffect(() => {
    if(companyId) getEmployees();
  }, [companyId]);

  const filterEmployeesBySearchInput = () => {
    if (employeeSearchInput === "") {
      setFilteredEmployees([])
    } else {
      const filteredEmployees = employees.filter((employee) => {
        const fullName = `${employee.user.firstName} ${employee.user.middleName || ""} ${employee.user.lastName}`;
        return fullName.toLowerCase().includes(employeeSearchInput.toLowerCase());
      });
      setFilteredEmployees(filteredEmployees);
    }
  };


  const handleChangeSearchEmployeeInput = (e) => {
    setEmployeeSearchInput(e.target.value);
    filterEmployeesBySearchInput()
  };

  const toggleAddEmployeesModal = () => {
    setAddEmployeesModal(!addEmployeesModal);
    // If the modal gets closed, fetch the employees again
    if(addEmployeesModal) getEmployees();
  }



  //onClick -> selectEmployee
  const selectEmployee = (employeeId) => {
    const currentSelectedEmployees = selectedEmployees;
    setSelectedEmployees([...currentSelectedEmployees, employeeId]);
    }

  //onClick -> unselectEmployee
  const unselectEmployee = (employeeId) => {
    setSelectedEmployees(selectedEmployees.filter((id) => id !== employeeId));
  };



  //create selected employee HTML elements
  const selectedEmployeeElements = selectedEmployees => selectedEmployees.map(
    (selectedEmployeeId, index) => {
      const employee = employees.find((employee) => employee.id === selectedEmployeeId);
      if(!employee) return null;
      const fullName = `${employee.user.firstName} ${
        employee.user.middleName || ""
      } ${employee.user.lastName}`;
      const department = employee.department?.items?.[0]?.department.name[0].text;

      return (
        <MDBListGroupItem
          key={index}
          noBorders
          className="d-flex justify-content-between align-items-center p-4"
        >
          <div className="d-flex gap-4 align-items-center">
            {employee.image?.fileName ? (
              <img
                src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${employee.image.fileName}`}
                className="rounded-circle border"
                style={{ height: "50px", width: "50px", objectFit: "cover" }}
                alt={employee.image.alt}
              />
            ) : (
              <MDBIcon
                className="rounded-circle border"
                style={{
                  height: "50px",
                  width: "50px",
                  textAlign: "center",
                  paddingTop: "12px",
                  fontSize: "20px",
                }}
                fas
                icon="user-alt"
              />
            )}
            <div>
              <h6>{fullName}</h6>
              <span>{department}
              </span>
            </div>
          </div>
          <MDBBtn color="tertiary" onClick={() => unselectEmployee(employee.id)} >
            <MDBIcon fas icon="trash-alt" color="danger" style={{fontSize: "1.2rem"}} />
          </MDBBtn>
        </MDBListGroupItem>
      );
    }
  );


  return (
    <>
      <MDBContainer style={{minHeight: "700px"}}>
        <MDBTypography tag="h3">{t("Select Employee")}</MDBTypography>
        <p className="mb-5 text-muted">{t("You can select ")+maxSelect+t(" employee. If the employee is not listed in this list, you have to add them to your company first.")}</p>
        <MDBRow className="justify-content-evenly">
          <MDBCol size="12" lg="6" xxl="5" className="bg-white p-3 rounded-4 shadow-4">
            <MDBInputGroup className="mb-3">
              <MDBIcon fas icon="search" className="input-group-text pt-2" />
              <MDBInput
                placeholder={t("Search employee")}
                type="text"
                onChange={handleChangeSearchEmployeeInput}
              />
              
            </MDBInputGroup>
            <MDBTable responsive style={{overflow: "scroll", maxHeight: "500px"}}>
              <MDBTableBody>{employees?.length > 0 && 
              (filteredEmployees.length > 0 ? 
                filteredEmployees.map((employee) => {
                  if (selectedEmployees?.find(selectedEmployeeId => selectedEmployeeId === employee.id)) {
                    return null;
                  }

                  return (
                    <tr key={employee.id} onClick={() => selectEmployee(employee.id)}>
                      <td  className="d-flex gap-3">
                        <div className="d-flex gap-4 align-items-center">
                          {employee.image?.fileName ? (
                            <img
                              src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${employee.image.fileName}`}
                              className="rounded-circle border"
                              style={{
                                height: "50px",
                                width: "50px",
                                objectFit: "cover",
                              }}
                              alt={employee.image.alt}
                            />
                          ) : (
                            <MDBIcon
                              className="rounded-circle border"
                              style={{
                                height: "50px",
                                width: "50px",
                                textAlign: "center",
                                paddingTop: "12px",
                                fontSize: "20px",
                              }}
                              fas
                              icon="user-alt"
                            />
                          )}
                          <div>
                            <h6>{`${employee.user?.title ? employee.user?.title + " ": ""}${employee.user?.firstName} ${employee.user?.middleName || ""} ${employee.user?.lastName}`}</h6>
                            <span>{employee.department?.items?.[0]?.department.name[0].text}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <MDBBtn color="tertiary">{t("Select")}</MDBBtn>
                      </td>
                    </tr>
                  );
                })
                .filter(Boolean)
               : 
               employees.map((employee) => {
                if (selectedEmployees?.find(selectedEmployeeId => selectedEmployeeId === employee.id)) {
                    return null;
                  }

                  return (
                    <tr key={employee.id} onClick={() => selectEmployee(employee.id)}>
                      <td key={employee.id} className="d-flex gap-3">
                        <div className="d-flex gap-4 align-items-center">
                          {employee.image?.fileName ? (
                            <img
                              src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${employee.image.fileName}`}
                              className="rounded-circle border"
                              style={{
                                height: "50px",
                                width: "50px",
                                objectFit: "cover",
                              }}
                              alt={employee.image.alt}
                            />
                          ) : (
                            <MDBIcon
                              className="rounded-circle border"
                              style={{
                                height: "50px",
                                width: "50px",
                                textAlign: "center",
                                paddingTop: "12px",
                                fontSize: "20px",
                              }}
                              fas
                              icon="user-alt"
                            />
                          )}
                          <div>
                            <h6>{`${employee.user.firstName} ${employee.user.middleName || ""} ${employee.user.lastName}`}</h6>
                            <span>{employee?.department?.items?.[0]?.department.name[0].text}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <MDBBtn color="tertiary">{t("Select")}</MDBBtn>
                      </td>
                    </tr>
                  );
                })
                .filter(Boolean)
              )
              }</MDBTableBody>
            </MDBTable>

            <MDBBtn
              onClick={toggleAddEmployeesModal}
              className="m-2"
              color="tertiary"
              rippleColor="light"
            >
              {t("Add new employee")}
            </MDBBtn>
            
          </MDBCol>
          <MDBCol size="12" lg="6" xxl="5" className="bg-white p-3 rounded-4 shadow-4">
            <MDBTypography tag="h4">
              {t("Selected Speakers")}
            </MDBTypography>
            <MDBListGroup style={{ minWidthL: "22rem" }}>
              {selectedEmployees?.length > 0 && selectedEmployeeElements(selectedEmployees)}
            </MDBListGroup>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBModal open={addEmployeesModal} onClose={() => setAddEmployeesModal(false)} staticBackdrop>
          <MDBModalDialog centered size="lg" style={{zIndex: "9"}}>
              <MDBModalContent>
              <MDBModalHeader>
                  <MDBModalTitle>{t("Add Person")}</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={toggleAddEmployeesModal}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
              <AddEmployees companyId={companyId} isActive={addEmployeesModal} toggleAddEmployeesModal={toggleAddEmployeesModal} />
              </MDBModalBody>
              </MDBModalContent>
          </MDBModalDialog>
      </MDBModal>
    </>
  );
}
