// Lecture Type
// type Lecture
//   @model
//   @auth(rules: [
//     { allow: private, operations: [read] },
//     { allow: groups, groups: ["Admin, Moderator"], operations: [create, read, update, delete] }])
// {
//   id: ID!
//   startTime: AWSDateTime!
//   endTime: AWSDateTime!
//   event: Event @belongsTo
//   company: Company @hasOne
//   speakers: [Employee] @hasMany
//   session: String
//   room: String
//   title: [MultiLangString!]!
//   subjects: [MultiLangStringList]
//   content: MultiLangString
//   presentationLanguage: String
//   image: Image
//   pdfDe: File
//   pdfEn: File
// }

import { generateClient, get } from 'aws-amplify/api';
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBInput, MDBSelect, MDBCol, MDBCheckbox, MDBDatepicker, MDBProgress, MDBProgressBar } from "mdb-react-ui-kit";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import lectureSchema from '../../validation/LectureValidation';
import {onCreateLecture, onUpdateLecture, onDeleteLecture} from '../../graphql/subscriptions';
import { type } from "@testing-library/user-event/dist/type";
import usePrevious from "../../utils/usePrevious";
import { getUrl, remove, uploadData } from "aws-amplify/storage";
import ReactPlayer from 'react-player'

const client = generateClient();
export default function AllLectures() {
    const { t, i18n } = useTranslation();
    const [basicActive, setBasicActive] = useState('tab1');

    const handleBasicClick = (value) => {
        if (value === basicActive) {
            return;
        }

        setBasicActive(value);
    };

    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [lectures, setLectures] = useState([]);
    const [shownLectures, setShownLectures] = useState([]);
    const [totalLectures, setTotalLectures] = useState(0);


    const [validationErrors, setValidationErrors] = useState([]);

    const validateLectures = (lectures) => {
        let errors = lectures.map((lecture) => {

            let lectureErrors = lectureSchema.validate(lecture).map((error) => ({
                field: error.path,
                message: error.message
            })).filter(e => e.message)

            // remove conditions
            lectureErrors = lectureErrors.filter((error) => error.message !== "Bedingungen");

            // Custom validation for conditions
            if(lecture.type === "marketing") {
                // Check image and speaker conditions
                // Image
                if(lecture.image?.fileName) {
                    const imageCondition = lecture.conditions?.find(c => c.name === "image");
                    if(!imageCondition || !imageCondition.accepted) {
                        lectureErrors.push({field: "image", message: "Bildrechte"})
                    }
                }
                // Speaker
                if(lecture.speakers?.length > 0) {
                    const speakerCondition = lecture.conditions?.find(c => c.name === "speakers");
                    if(!speakerCondition || !speakerCondition.accepted) {
                        lectureErrors.push({field: "speaker", message: "Referenten-Bedingung"})
                    }
                }
               
                } else if (lecture.type === "technical" || lecture.type === "educational") {
                    if(!lecture.conditions) {
                        console.log("no conditions", lecture.id, lecture.conditions)

                    }
                    // Check image and speaker conditions and adCondition, recordCondition, rightsCondition, deadlineCondition
                    // Image
                    if(lecture.image?.fileName) {
                        const imageCondition = lecture.conditions?.find(c => c.name === "image");
                        if(!imageCondition || !imageCondition.accepted) {
                            lectureErrors.push({field: "image", message: "Bildrechte"})
                        }
                    }
                    // Speaker
                    if(lecture.speakers?.items?.length > 0) {
                        const speakerCondition = lecture.conditions?.find(c => c.name === "speakers");
                        if(!speakerCondition || !speakerCondition.accepted) {
                            lectureErrors.push({field: "speaker", message: "Referenten-Bedingung"})
                        }
                    }
                    // Ad
                    const adCondition = lecture.conditions?.find(c => c.name === "adCondition");
                    if(!adCondition || !adCondition.accepted) {
                        lectureErrors.push({field: "ad", message: "Bedingungen-Werbefrei"})
                    }
                    // Record
                    const recordCondition = lecture.conditions?.find(c => c.name === "recordCondition");
                    if(!recordCondition || !recordCondition.accepted) {
                        lectureErrors.push({field: "record", message: "Bedingungen-Aufzeichnung"})
                    }
                    // Rights
                    const rightsCondition = lecture.conditions?.find(c => c.name === "rightsContition");
                    if(!rightsCondition || !rightsCondition.accepted) {
                        lectureErrors.push({field: "rights", message: "Bedingungen-Aufzeichnungsrechte"})
                    }
                    // Deadline
                    const deadlineCondition = lecture.conditions?.find(c => c.name === "deadlineContition");
                    if(!deadlineCondition || !deadlineCondition.accepted) {
                        lectureErrors.push({field: "deadline", message: "Bedingungen-Deadline"})
                    }
                   
                }
            


            return ({
                    id: lecture.id,
                    errors: lectureErrors
                })
            });
        
        // errors.forEach((error) => {
        //     console.log(error)
        // })

        setValidationErrors(errors);
    }

    useEffect(() => {
        if(lectures.length > 0) {
            setTimeout(() => {
                validateLectures(lectures)
            }, 500);
        }
    }, [lectures]);

    const [isLoading, setIsLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const extractExistingParams = (searchParams) => {
        return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
            if (value === 'true') {
                acc[key] = true;
            } else if (value === 'false') {
                acc[key] = false;
            } else {
                acc[key] = value;
            }
            return acc;
        }, {});
    }

    const [filters, setFilters] = useState({
        approved: null,
        createdAtStart: "2023-12-01T00:00:00.000Z",
        createdAtEnd: null,
        createdAtSort: "desc",
        status: null,
        type: null
    });

    const getLectures = async (eventId) => {
        try {
            setIsLoading(true);
            const searchLectureFilter = {
                eventLecturesId: { eq: eventId }
            };
            const searchLectureSort = {};
            if (typeof filters.approved === "boolean") {
                searchLectureFilter.approved = { eq: filters.approved };
            }
            if (filters.createdAtStart && filters.createdAtEnd) {
                searchLectureFilter.and = [{createdAt: {gt: filters.createdAtStart}}, {createdAt: {lt: filters.createdAtEnd}}];
            } else if (filters.createdAtStart) {
                searchLectureFilter.createdAt = { gt: filters.createdAtStart };
            } else if (filters.createdAtEnd) {
                searchLectureFilter.createdAt = { lt: filters.createdAtEnd };
            }
            if (filters.createdAtSort) {
                searchLectureSort.field = "createdAt";
                searchLectureSort.direction = filters.createdAtSort;
            }
            if (filters.updatedAtSort) {
                searchLectureSort.field = "updatedAt";
                searchLectureSort.direction = filters.updatedAtSort;
            }
            if (filters.status) {
                searchLectureFilter.status = { eq: filters.status };
            }
            if (filters.type) {
                searchLectureFilter.type = { eq: filters.type };
            }

            const lectures = await client.graphql({
                variables: {
                    filter: searchLectureFilter,
                    sort: searchLectureSort,
                    limit: 1000
                },
                query: /* GraphQL */ `
                query SearchLectures(
                  $filter: SearchableLectureFilterInput
                  $sort: [SearchableLectureSortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableLectureAggregationInput]
                ) {
                  searchLectures(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                        title {
                            language
                            text
                        }
                        speakers {
                            items {
                                employee {
                                    image {
                                    fileName
                                    alt
                                    }
                                }
                            }
                        }
                        subjects {
                            id
                            name {
                                language
                                text
                            }
                        } 
                        topics {
                            items {
                              topic {
                                id
                                name {
                                  language
                                  text
                                }
                              }
                            }
                        }        
                        content {
                            language
                            text
                        }
                        conditions {
                            name
                            accepted
                        }
                        image {
                            fileName
                            alt
                        }
                        pdfDe {
                            url
                            name   
                        }
                        pdfEn {
                            url
                            name   
                        }
                        videoS3Key {
                            s3Path
                        }
                        company {
                            id
                            name
                            image {
                                main {
                                    fileName
                                    alt
                                }
                            }
                        }
                        conditions {
                            name
                            accepted
                        }
                        id
                        adminComment
                        companyBooth
                        session
                        room
                        startTime
                        type
                        presentationLanguage
                        createdAt
                        updatedAt
                        companyLecturesId
                        eventLecturesId
                        lectureMainSpeakerId
                        approved
                        status
                        __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `
            });
            const lecturesData = lectures.data.searchLectures.items.map(lecture => ({...lecture, topics: lecture.topics.items.map(topic => topic.topic)})).sort((a, b) => {
                // Behandlung von leeren oder fehlenden Werten
                if (!a.room && !b.room) {
                    return 0;
                } else if (!a.room) {
                    return 1; // a nach unten
                } else if (!b.room) {
                    return -1; // b nach unten
                }
            
                // Zuerst nach Raum sortieren
                if (a.room !== b.room) {
                    return a.room.localeCompare(b.room);
                } else {
                    // Wenn die Räume gleich sind, nach Startzeit sortieren
                    if (!a.startTime && !b.startTime) {
                        return 0;
                    } else if (!a.startTime) {
                        return 1; // a nach unten
                    } else if (!b.startTime) {
                        return -1; // b nach unten
                    }
                    return new Date(a.startTime) - new Date(b.startTime);
                }
            });
            setLectures(lecturesData);
            setShownLectures(lecturesData);
            setTotalLectures(lectures.data.searchLectures.total);
            setIsLoading(false);
        } catch (error) {
            console.log("error on fetching lectures", error);
            if (error.data?.searchLectures?.items) {
                setLectures(error.data.searchLectures.items);
                setShownLectures(error.data.searchLectures.items);
            }
            setIsLoading(false);
        }
    };

    const getEvents = async () => {
        try {
            const events = await client.graphql({
                query: /* GraphQL */ `
                query ListEvents(
                  $filter: ModelEventFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                            id
                            name {
                                language
                                text
                            }
                            startDate
                            lectureRegistrationStartDate
                            endDate
                            status
                        }
                    }
                }
                `,
                variables: {
                    filter: {
                        lectureRegistrationStartDate: { le: new Date().toISOString().substring(0, 10) },
                        // status: { eq: "active" }
                    }
                }
            });
            // Sort events by start date
            const sortedEvents = events.data.listEvents.items.sort((a, b) =>  new Date(b.startDate) - new Date(a.startDate));
            setEvents(sortedEvents);
            return sortedEvents;
        } catch (error) {
            console.log("error on fetching events", error);
        }
    };


    const [filtersInitialized, setFiltersInitialized] = useState(false);

useEffect(() => {
    // Beim ersten Render die Filter aus den searchParams extrahieren und setzen
    const extractedParams = extractExistingParams(searchParams);
    setFilters(oldFilters => ({ ...extractedParams, createdAtStart: extractedParams.createdAtStart || oldFilters.createdAtStart , createdAtSort: extractedParams.createdAtSort || oldFilters.createdAtSort }));
    setFiltersInitialized(true); // Setze auf true, sobald die Initialisierung abgeschlossen ist
}, []); // Leere Abhängigkeiten, um den Hook nur einmal auszuführen


const loadData = async () => {
    const eventsData = events.length > 0 ? events : await getEvents();
    
    if (eventsData.length > 0) {
        if(!selectedEvent) setSelectedEvent(eventsData[0].id);
        getLectures(eventsData[0].id);
    }
}

useEffect(() => {
    if (filtersInitialized) {
        // Aktualisiere die searchParams und rufe getLectures auf, nachdem die Filter initialisiert wurden
        const params = Object.entries(filters).reduce((acc, [key, value]) => {
            if (value !== null) {
                acc[key] = value;
            }
            return acc;
        }, {});

        setSearchParams(params);
        loadData()

    }
}, [filters, filtersInitialized]); // Abhängigkeit von den Filters und filtersInitialized

useEffect(() => {
  if(selectedEvent) {
    getLectures(selectedEvent);
  }
}, [selectedEvent])

    // subscribe to lecture changes


// Subscribe to update of Todo
// const updateSub = client
//   .graphql({ query: onUpdateLecture })
//   .subscribe({
//     next: ({ data }) => console.log("update", data),
//     error: (error) => console.warn(error)
//   });

const updateLectureOnSubscription = (lecture) => {
    if (!lecture) {
        return;
    }
    setLectures(currentLectures => currentLectures.map(l => l.id === lecture.id ? lecture : l));
    setShownLectures(currentShownLectures => currentShownLectures.map(l => l.id === lecture.id ? lecture : l));
};

const subscribeUpdateLecture = () => {
    console.log("subscribe update lecture")
    const subscription = client.graphql({
        query: /* GraphQL */ `
        subscription OnUpdateLecture($filter: ModelSubscriptionLectureFilterInput) {
          onUpdateLecture(filter: $filter) {
            title {
                language
                text
            }
            speakers {
                items {
                    employee {
                        image {
                        fileName
                        alt
                        }
                    }
                }
            }
            subjects {
                id
                name {
                    language
                    text
                }
            } 
            topics {
                items {
                  topic {
                    id
                    name {
                      language
                      text
                    }
                  }
                }
            }        
            content {
                language
                text
            }
            conditions {
                name
                accepted
            }
            image {
                fileName
                alt
            }
            pdfDe {
                url
                name   
            }
            pdfEn {
                url
                name   
            }
            company {
                id
                name
                image {
                    main {
                        fileName
                        alt
                    }
                }
            }
            conditions {
                name
                accepted
            }
            id
            adminComment
            session
            companyBooth
            room
            type
            presentationLanguage
            createdAt
            updatedAt
            companyLecturesId
            eventLecturesId
            lectureMainSpeakerId
            approved
            status
            __typename
          }
        }
        `
    }).subscribe({
        next: ({ data }) => {
            // Update the lecture in the list of lectures, found by data.onUpdateLecture.id
            console.log("update", data);
            //getLectures();
            updateLectureOnSubscription(data.onUpdateLecture);

        },
        error: (error) => console.warn(error)
    });

    return {
        unsubscribe: () => subscription.unsubscribe()
    };
};

useEffect(() => {
    // Starten des Abonnements
    const subscription = subscribeUpdateLecture();

    // Bereinigungsfunktion
    return () => {
        // Beenden des Abonnements
        subscription.unsubscribe();
    };
}, []);


  // Stop receiving data updates from the subscription

    


    const [deleteLectureModal, setDeleteLectureModal] = useState(false);
    const toggleDeleteLectureModal = () => setDeleteLectureModal(!deleteLectureModal);

    const [selectedLectureRemove, setSelectedLectureRemove] = useState(null);

    const deleteLecture = async (id) => {
        try {
            const deletedLecture = await client.graphql({
                variables: {
                    input: {
                        id: id
                    }
                },
                query: /* GraphQL */ `
                mutation DeleteLecture(
                  $input: DeleteLectureInput!
                  $condition: ModelLectureConditionInput
                ) {
                  deleteLecture(input: $input, condition: $condition) {
                    id
                  }
                }
              `
            });
            console.log("deleted lecture", deletedLecture);
            setLectures(lectures.filter((lecture) => lecture.id !== id));
            setShownLectures(shownLectures.filter((lecture) => lecture.id !== id));
            setSelectedLectureRemove(null);
            toggleDeleteLectureModal();
        } catch (error) {
            console.log("error on deleting lecture", error);
        }
    }

    const searchLectures = (searchTerm) => {
        if (searchTerm.length > 0) {
            setShownLectures(lectures.filter(lecture => {
                if (lecture.company?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
                    return true;
                }
                if (lecture.title?.find(t => t.language === i18n.language)?.text?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
                    return true;
                }
                return false;
            }));
        } else {
            setShownLectures(lectures);
        }
    }


    const filterbyHasErrors = (selectedErrors) => { // selectedErrors = ["Titel", "Referenten"]
        console.log(selectedErrors);
        if (selectedErrors.length > 0) {
            setShownLectures(lectures.filter(lecture => {
                if (validationErrors.find(error => error.id === lecture.id)) {
                    return validationErrors.find(error => error.id === lecture.id).errors.some(error => selectedErrors.includes(error.message));
                }
                return false;
            }));
        } else {
            setShownLectures(lectures);
        }
       
       
    }

    const [selectedConditions, setSelectedConditions] = useState([]); // selectedConditions = ["ECV", "Podcast"
    const prevConditions = usePrevious(selectedConditions);

    const filterByConditions = async () => { // selectedConditions = ["ECV", "Podcast"]
        console.log(selectedConditions);
       
        if (selectedConditions.length > 0) {
           
            setShownLectures(shownLectures.filter(lecture => {
                if(lecture.conditions?.length > 0) {
                if (selectedConditions.includes("ECV") && selectedConditions.includes("Podcast")) {
                    return (lecture.conditions?.find(condition => condition.name === "ecv")?.accepted && lecture.conditions?.find(condition => condition.name === "podcast")?.accepted);
                } else if (selectedConditions.includes("ECV")) {
                    return lecture.conditions?.find(condition => condition.name === "ecv")?.accepted;
                } else if (selectedConditions.includes("Podcast")) {
                    return lecture.conditions?.find(condition => condition.name === "podcast")?.accepted;
                }
                return false;
            } else {
                return false;
            }
            }));
        } else {
            setShownLectures(lectures);
        }
    }

    useEffect(() => {
       // If something changed in the selectedConditions, filter the lectures
         if (prevConditions !== selectedConditions) {
        filterByConditions(selectedConditions);
        }
    }, [selectedConditions, shownLectures]);



    // Video Upload, View and Remove Modal
    const [videoModal, setVideoModal] = useState(false);
    const [selectedLectureVideo, setSelectedLectureVideo] = useState(null);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
    const [lectureVideosUploadStatus, setLectureVideosUploadStatus] = useState({});
    const hanleOpenVideoModal = async (lectureId) => {
        setSelectedLectureVideo(lectureId);
        const videoKey = lectures.find(l => l.id === lectureId)?.videoS3Key?.s3Path;
        if (videoKey) {
            const videoUrl = await getVideoUrl(videoKey);
            setSelectedVideoUrl(videoUrl);
        }
        toggleVideoModal();
    }
    const toggleVideoModal = () => setVideoModal(!videoModal);

    const inputFieldRef = useRef();

    const uploadVideo = async (file, lectureId) => {
        try {
            let lastTimeStamp = Date.now(); // Initial timestamp
            let lastTransferredBytes = 0; // Initial transferred bytes

            const result = await uploadData({
              key: `lectureVideos/${lectureId}.mp4`, 
              data: file,
              options: {
                onProgress: ({ transferredBytes, totalBytes }) => {
                  if (totalBytes) {
                    const currentTime = Date.now();
                    const timeElapsed = (currentTime - lastTimeStamp) / 1000; // Convert ms to seconds
                    const bytesTransferred = transferredBytes - lastTransferredBytes;
                    const speedInMBps = (bytesTransferred / (1024 * 1024)) / timeElapsed; // Convert bytes to MB and divide by seconds
                    setLectureVideosUploadStatus(old => ({
                        ...old,
                      [lectureId]: {
                        transferredBytes,
                        totalBytes,
                        percent: Math.round((transferredBytes / totalBytes) * 100),
                        speed: speedInMBps.toFixed(2) + ' MB/s',
                      },
                    }));
                  }
                }
              }
            }).result;
            console.log('Path from Response: ', result.key);
            // Update the lecture with the video path on the field videoS3Key
            setLectures(lectures.map(l => l.id === lectureId ? {...l, videoS3Key: {s3Path: result.key}} : l));
            setShownLectures(shownLectures.map(l => l.id === lectureId ? {...l, videoS3Key: {s3Path: result.key}} : l));
            setSelectedVideoUrl(await getVideoUrl(result.key));
            await client.graphql({
                variables: {
                    input: {
                        id: lectureId,
                        videoS3Key: {
                            s3Path: result.key
                        }
                    }
                },
                query: /* GraphQL */ `
                mutation UpdateLecture(
                  $input: UpdateLectureInput!
                ) {
                  updateLecture(input: $input) {
                    id
                    videoS3Key {
                        s3Path
                    }
                  }
                }
              `
            });
          } catch (error) {
            console.log('Error : ', error);
          }
    }

    const getVideoUrl = async (videoKey) => {
        try {
            const result = await getUrl({
                key: videoKey,
                level: 'public'
            });
            console.log("video url", result.url.href);
            return result.url.href;
        } catch (error) {
            console.log("error on getting video url", error);
        }
    }

    const removeVideo = async (lectureId) => {
        try {
            const videoKey = lectures.find(l => l.id === lectureId)?.videoS3Key?.s3Path;
            if (videoKey) {
                await remove({
                    key: videoKey
                });
                setLectures(lectures.map(l => l.id === lectureId ? {...l, videoS3Key: null} : l));
                setShownLectures(shownLectures.map(l => l.id === lectureId ? {...l, videoS3Key: null} : l));
                await client.graphql({
                    variables: {
                        input: {
                            id: lectureId,
                            videoS3Key: null
                        }
                    },
                    query: /* GraphQL */ `
                    mutation UpdateLecture(
                      $input: UpdateLectureInput!
                    ) {
                      updateLecture(input: $input) {
                        id
                        videoS3Key {
                            s3Path
                        }
                      }
                    }
                  `
                });
                setSelectedVideoUrl(null);
            }
        } catch (error) {
            console.log("error on removing video", error);
        }
    }




    return (
        <><MDBCard className="w-auto">
            <MDBCardBody className="flex-column">
                <MDBCardTitle>
                    {t("Lectures")}
                   
                </MDBCardTitle>
                <MDBTabs className='mb-3'>
                    {
                        events.length > 0 &&
                        events.map((event, index) => (
                            <MDBTabsItem>
                            <MDBTabsLink key={index} onClick={() => setSelectedEvent(event.id)} active={selectedEvent === event.id}>
                                {event.name.find(n => n.language === i18n.language)?.text || event.name[0].text}
                            </MDBTabsLink>
                            </MDBTabsItem>
                        ))
                    }   
                </MDBTabs>

                <MDBTabsContent>
                    <MDBTabsPane open={basicActive === 'tab1'}>
                    <MDBRow>
                        <MDBCol md="6">
                        <MDBInput type="text" onChange={e => searchLectures(e.target.value)} label={t("Search")} />
                        </MDBCol>
                        <MDBCol size="6" md="3">
                        <MDBDatepicker
                            label={t("Start Date")}
                            inline
                            disableFuture
                            defaultValue={filters.createdAtStart && new Date(filters.createdAtStart).toLocaleDateString('en-GB')}
                            value={filters.createdAtStart && new Date(filters.createdAtStart).toLocaleDateString('en-GB')}
                            onChange={date => date && setFilters({ ...filters, createdAtStart: new Date(date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')).toISOString()})}
                          />
                        </MDBCol>
                        <MDBCol size="6" md="3">
                        <MDBDatepicker
                            label={t("End Date")}
                            inline
                            disableFuture
                            defaultValue={filters.createdAtEnd && new Date(filters.createdAtEnd).toLocaleDateString('en-GB')}
                            value={filters.createdAtEnd && new Date(filters.createdAtEnd).toLocaleDateString('en-GB')}
                            onChange={date => date && setFilters({ ...filters, createdAtEnd: new Date(date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')).toISOString()})}
                          />
                        </MDBCol>
                    </MDBRow>
                    
                    
                    <MDBRow className="mt-2 mt-xl-3">
                        <MDBCol md="3">
                            <MDBSelect label={t("Type")}
                                onChange={type => type && setFilters({ ...filters, type: type.value})}
                                value={filters.type}
                                data={
                                    [
                                        { text: t("All"), value: null },
                                        { text: t("Marketing"), value: "marketing" },
                                        { text: t("Technical"), value: "technical" },
                                        { text: t("Educational"), value: "educational" },
                                        { text: t("Recruiting"), value: "recruiting" },
                                        { text: t("Promotion Marketing"), value: "promotion_marketing" },
                                        { text: t("Promotion Technical"), value: "promotion_technical" }
                                    ]
                                }
                            />
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBSelect label={t("Status")}
                                onChange={selected => selected && setFilters({ ...filters, status: selected.value})}
                                value={filters.status}
                                data={
                                    [   
                                        { text: t("All"), value: null },
                                        { text: t("Open"), value: "created" },
                                        { text: t("Approved"), value: "checked" },
                                        { text: t("Not Approved"), value: "not_approved" },
                                        { text: t("In Planning"), value: "in_planning" },
                                        { text: t("Committed"), value: "committed" },
                                    ]
                                }
                            />
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBSelect label={t("Errors")}
                                onChange={selected => filterbyHasErrors(selected.map(s => s.value))}
                                multiple
                                data={
                                    [
                                        { text: "Titel", value: "Titel" },
                                        { text: "Referenten", value: "Referenten" },
                                        { text: "Unterpunkte", value: "Unterpunkte" },
                                        { text: "Beschreibung", value: "Beschreibung" },
                                        { text: "Bedingungen", value: "Bedingungen" },
                                        { text: "Bild", value: "Bild" },
                                        { text: "Themenbereich", value: "Themenbereich" }
                                    ]
                                }
                            />
                        </MDBCol>
                        <MDBCol md="1">
                            <MDBCheckbox label="ECV" onChange={e => setSelectedConditions(e.target.checked ? [...selectedConditions, "ECV"] : selectedConditions.filter(c => c !== "ECV"))} />
                        </MDBCol>
                        <MDBCol md="1">
                            <MDBCheckbox label="Podcast" onChange={e => setSelectedConditions(e.target.checked ? [...selectedConditions, "Podcast"] : selectedConditions.filter(c => c !== "Podcast"))} />
                        </MDBCol>
                        <MDBCol md="1">
                        <MDBIcon className="float-end" fas icon="sync-alt" onClick={() =>  getLectures(selectedEvent)} />
                        </MDBCol>

                        
                    </MDBRow>
                    
                        <MDBTable>
                            <MDBTableHead>
                                <tr>
                                    <th width="125">{t("Datum")} 
                                    <br />
                                    <span className="text-muted">
                                    {t("Erstellt")} <MDBIcon fas icon="long-arrow-alt-up" size='sm' onClick={() => setFilters({...filters, createdAtSort: "desc", updatedAtSort: null})} /> <MDBIcon fas icon="long-arrow-alt-down" size='sm' onClick={() => setFilters({...filters, createdAtSort: "asc", updatedAtSort: null})} />
                                    </span>
                                    <br />
                                    <span className="text-muted">
                                    {t("Geändert")} <MDBIcon fas icon="long-arrow-alt-up" size='sm' onClick={() => setFilters({...filters, updatedAtSort: "desc", createdAtSort: null})} /> <MDBIcon fas icon="long-arrow-alt-down" size='sm' onClick={() => setFilters({...filters, updatedAtSort: "asc", createdAtSort: null})} />
                                    </span>
                                    </th>
                                    <th>{t("Company")}</th>
                                    <th width="100">{t("Type")}</th>
                                    <th>{t("Title")}</th>
                                    <th>{t("Speakers")}</th>
                                    {/* <th>{t("Errors")}</th>
                                    <th>{t("Status")}</th> */}
                                    <th>{t("Room & Time")}</th>
                                    <th>{t("Video")}</th>
                                    <th>{t("Actions")}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                { isLoading ? <tr><td colSpan="8" className="text-center"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></td></tr>
                                : shownLectures.length > 0 ?
                                    shownLectures.map((lecture, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="px-1">
                                                {new Date(lecture.createdAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}
                                                {new Date(lecture.createdAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' }) !== new Date(lecture.updatedAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' }) && <>
                                                    <br />
                                                <span className="text-success" style={{fontSize: "smaller"}}>{new Date(lecture.updatedAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}</span>
                                                </>}
                                                </td>
                                                <td width="300" className="px-1"><>
                                                    {lecture.company?.image?.main?.fileName ?
                                                        <div className='d-flex align-items-center'>
                                                            {lecture.company.image?.main?.fileName ?
                                                                <img
                                                                    src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+lecture.company.image.main.fileName}
                                                                    className="rounded-circle border"
                                                                    style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                                                    alt={lecture.company.image.alt}
                                                                />
                                                            :
                                                                <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                                                        
                                                            }
                                                            <div className='ms-3'>
                                                            <p className='fw-bold mb-1'>{lecture.company.name}</p>
                                                            <p className='text-muted mb-0'>{lecture.companyBooth}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <MDBIcon
                                                            className="rounded-circle border"
                                                            style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                textAlign: "center",
                                                                paddingTop: "12px",
                                                                fontSize: "20px",
                                                            }}
                                                            fas
                                                            icon="user-alt"
                                                        />
                                                    }
                                                </>
                                                </td>
                                                <td className="px-1">{t(lecture.type)}</td>
                                                <td className="px-1">{lecture.title?.find(t => t.language === i18n.language)?.text || lecture.title?.[0]?.text}</td>
                                                <td className="px-1">{lecture.speakers?.items?.map((speaker, index) => <>
                                                    {speaker?.employee?.image?.fileName ?
                                                        <img
                                                            key={index}
                                                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.employee.image.fileName}`}
                                                            className="rounded-circle border"
                                                            style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                objectFit: "cover",
                                                            }}
                                                            alt={speaker.employee?.image.alt}
                                                        />
                                                        :
                                                        <MDBIcon
                                                        key={index}
                                                            className="rounded-circle border"
                                                            style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                textAlign: "center",
                                                                paddingTop: "12px",
                                                                fontSize: "20px",
                                                            }}
                                                            fas
                                                            icon="user-alt"
                                                        />
                                                    }
                                                </>)}</td>
                                                {/* <td width="300">{
                                                    validationErrors.find(error => error.id === lecture.id) ?
                                                        [...new Set(validationErrors.find(error => error.id === lecture.id).errors.map(error => error.message))].join(", ")
                                                        :
                                                        <p className="text-success">{t("No errors")}</p>
                                                }
                                                {lecture.adminComment && lecture.adminComment.length > 2 &&
                                                <>
                                                <br />
                                                <span className="text-warning fw-bold">{t("Admin Comment")}:</span>
                                                <br />
                                                {lecture.adminComment}
                                                </>
                                                   
                                                }
                                                </td>
                                                <td>
                                                {lecture.status === "created" ? null : t(lecture.status === "checked" ? "Approved" : lecture.status)}
                                                </td> */}
                                                <td className="px-1">
                                                    {lecture.room && <p className="mb-0">{t("Room") + " " + lecture.room}</p>}
                                                    {lecture.startTime && <p className="mb-0">{new Date(lecture.startTime).toLocaleDateString('de-DE')}</p>}
                                                    {lecture.startTime && <p className="mb-0">{new Date(lecture.startTime).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })} Uhr</p>}
                                                </td>
                                                <td>
                                                    <MDBIcon fas icon="edit" style={{ color: "link", cursor: "pointer" }} onClick={() => hanleOpenVideoModal(lecture.id)} />
                                                     <br></br>
                                                    {
                                                        lecture.videoS3Key?.s3Path &&
                                                        <MDBIcon fas style={{ color: "green" }} icon="video" />
                                                    }

                                               </td>
                                                <td>
                                                    <MDBBtnGroup shadow="0">
                                                        {/* <MDBBtn color='link' style={{color: "black"}} className="px-2" >{t("Upload Documents")}</MDBBtn> */}
                                                        <div className="justify-content-between">
                                                        <Link to={`/admin/lecture/${lecture.company?.id}/${lecture.id}`}>
                                                            <MDBIcon color='link' fas icon="edit" />
                                                        </Link>
                                                        <MDBIcon
                                                            fas
                                                            icon="trash"
                                                            style={{ color: "red", cursor: "pointer" }} className="ms-3" onClick={() => { setSelectedLectureRemove(lecture.id); toggleDeleteLectureModal() }}
                                                        />
                                                       
                                                        </div>
                                                        
                                                    </MDBBtnGroup>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan="7">
                                            {t("No lectures found")}
                                        </td>
                                    </tr>
                                }
                            </MDBTableBody>
                        </MDBTable>
                    </MDBTabsPane>
                </MDBTabsContent>
            </MDBCardBody>
        </MDBCard>

            <MDBModal open={deleteLectureModal} onClose={() => setDeleteLectureModal(false)}>
                <MDBModalDialog centered size="sm">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{t("Remove Lecture")}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleDeleteLectureModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className="text-center">
                            <p>{t("Are you sure you want to remove this lecture?")}</p>
                            <p className="fw-bold">{selectedLectureRemove && (lectures.find(l => l.id === selectedLectureRemove)?.title.find(t => t.language === i18n.language)?.text || lectures.find(l => l.id === selectedLectureRemove).title[0].text)}</p>
                            <MDBBtn color='danger' onClick={() => deleteLecture(selectedLectureRemove)}>{t("Remove")}</MDBBtn>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

<MDBModal open={videoModal} onClose={() => setVideoModal(false)}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Upload Video")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => {
                    setLectureVideosUploadStatus({});
                    setSelectedLectureVideo(null);
                    inputFieldRef.current.value = "";
                    setSelectedVideoUrl(null);
                    toggleVideoModal();
                }}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
                <h6>
                    {selectedLectureVideo && (lectures.find(l => l.id === selectedLectureVideo)?.title.find(t => t.language === i18n.language)?.text || lectures.find(l => l.id === selectedLectureVideo).title[0].text)}
                </h6>
                {
                    selectedVideoUrl &&
                    <ReactPlayer
                        url={selectedVideoUrl}
                        controls
                        width="100%"
                        height="auto"
                    />

                }
                <MDBRow>
                    <MDBCol size="11">
                        <MDBInput
                        type="file" 
                        onChange={e => uploadVideo(e.target.files[0], selectedLectureVideo)}
                        ref={inputFieldRef}
                        />
                    </MDBCol>
                    <MDBCol size="1">
                        <MDBIcon icon="trash" color="danger" className="mt-2" style={{cursor: "pointer"}} onClick={() => removeVideo(selectedLectureVideo)} />
                    </MDBCol>

                </MDBRow>
               
               
                {lectureVideosUploadStatus[selectedLectureVideo] && <>
                    <MDBProgress height="20" className="mt-4">
                        <MDBProgressBar striped bgColor='success' width={lectureVideosUploadStatus[selectedLectureVideo].percent} valuemin={0} valuemax={100}>
                            
                        </MDBProgressBar>
                    </MDBProgress>
                    <p>
                        {t("Uploaded")}: {lectureVideosUploadStatus[selectedLectureVideo].percent}% ({lectureVideosUploadStatus[selectedLectureVideo].speed})
                    </p>
                </>}
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>

        </>
    )
}
