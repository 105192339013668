
import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBInput, MDBSelect, MDBCol, MDBCheckbox, MDBDatepicker, MDBChip, MDBCardImage, MDBCardText, MDBModalFooter } from "mdb-react-ui-kit";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import usePrevious from "../utils/usePrevious";
import { isoToGermanDate } from "../utils/dateTools";
import { exists } from "i18next";
import { UserContext } from "../App";


const client = generateClient();
export default function AllLectures() {
    const { t, i18n } = useTranslation();
    const { user, addUserCondition } = useContext(UserContext);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [lectures, setLectures] = useState([]);
    const [shownLectures, setShownLectures] = useState([]);
    const [topicGroups, setTopicGroups] = useState([]);
    const [topics, setTopics] = useState([]);
    

    const getLectures = async () => {
        try {
            setIsLoading(true);

            const lectures = await client.graphql({
                variables: {
                    filter: {eventLecturesId : {eq: "882c49b6-ba4b-4c66-8a35-987d04af08a5"}, status: {eq: "checked"}, startTime: {exists: true}},
                    limit: 2000
                },
                query: /* GraphQL */ `
                query SearchLectures(
                  $filter: SearchableLectureFilterInput
                  $sort: [SearchableLectureSortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableLectureAggregationInput]
                ) {
                  searchLectures(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                        title {
                            language
                            text
                        }
                        speakers {
                            items {
                                employee {
                                    image {
                                    fileName
                                    alt
                                    }
                                }
                            }
                        }
                        subjects {
                            id
                            name {
                                language
                                text
                            }
                        } 
                        topics {
                            items {
                              topic {
                                id
                                name {
                                  language
                                  text
                                }
                              }
                            }
                        }        
                        content {
                            language
                            text
                        }
                        conditions {
                            name
                            accepted
                        }
                        image {
                            fileName
                            alt
                        }
                        pdfDe {
                            url
                            fileName
                            s3Path
                          }
                        pdfEn {
                            url
                            fileName
                            s3Path
                        }
                        company {
                            id
                            name
                            image {
                                main {
                                    fileName
                                    alt
                                }
                            }
                        }
                        conditions {
                            name
                            accepted
                        }
                        id
                        approvedInsight
                        room
                        type
                        presentationLanguage
                        createdAt
                        updatedAt
                        companyLecturesId
                        eventLecturesId
                        lectureMainSpeakerId
                        adminTopic {
                            id
                        }
                        approved
                        status
                        startTime
                        endTime
                        __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `
            });
            // Filter Lectuers with videoS3Key
            const resLectures = lectures.data.searchLectures.items.filter(l => l.approvedInsight).sort((a, b) => a.startTime.localeCompare(b.startTime));
            setLectures(resLectures);
            setShownLectures(resLectures);
            setIsLoading(false);
        } catch (error) {
            console.log("error on fetching lectures", error);
            if (error.data?.searchLectures?.items) {
                setLectures(error.data.searchLectures.items.filter(l => l?.approvedInsight));
                setShownLectures(error.data.searchLectures.items.filter(l => l?.approvedInsight));
            }
            setIsLoading(false);
        }
    };

    const getTopics = async () => {
        try {
          const topicGroupsWithTopics = await client.graphql({
            query: /* GraphQL */ `
            query ListTopicGroups(
              $filter: ModelTopicGroupFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listTopicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                  id
                  sortKey
                  color
                  name {
                    language
                    text
                  }
                  topics {
                    items {
                      id
                      name {
                        language
                        text
                      }
                      topicGroup {
                        color
                      }
                      topicGroupTopicsId
                    }
                  }
                }
                nextToken
                __typename
              }
            }
          `
            });
            setTopicGroups(topicGroupsWithTopics.data.listTopicGroups.items);
            const topics = topicGroupsWithTopics.data.listTopicGroups.items.map((topicGroup) => topicGroup.topics.items).flat();
            setTopics(topics);
    
        } catch (error) {
            console.log("error on fetching topics", error);
        }
    }
    
useEffect(() => {
    getLectures();
    if (topics.length === 0) {
        getTopics();
    }
}, []);

 
// Filters
const [filterTopicGroup, setFilterTopicGroup] = useState(null);
const [searchTerm, setSearchTerm] = useState("");
const [selectedDates, setSelectedDates] = useState([]);


// Filter if values changed
useEffect(() => {
    let filteredLectures = lectures;
    if (filterTopicGroup) {
        filteredLectures = filteredLectures.filter(lecture => topics.find(topic => topic.id === lecture.adminTopic?.id)?.topicGroupTopicsId === filterTopicGroup);
    }
    if (searchTerm.length > 0) {
        filteredLectures = filteredLectures.filter(lecture => {
            if (lecture.company?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
                return true;
            }
            if (lecture.title?.find(t => t.language === i18n.language)?.text?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
                return true;
            }
            return false;
        });
    }
    if (selectedDates.length > 0) {
        filteredLectures = filteredLectures.filter(lecture => selectedDates.includes(lecture.startTime.substring(0, 10)));
    }
    setShownLectures(filteredLectures);
}, [filterTopicGroup, searchTerm, selectedDates]);

// Modal buy access
const [buyModal, setBuyModal] = useState(false);

// Conditions
const [conditionsModal, setConditionsModal] = useState(false);

useEffect(() => {
    if(user.hasInsightAccess) {
        if ((!user.conditions || !user.conditions.find(c => c.id === "communication")) && !conditionsModal) {
            setTimeout(() => {
                setConditionsModal(true);
                setBuyModal(false);
            }, 500);
        }
    } else {
        setTimeout(() => {
            setBuyModal(true);
        }, 500);
    }
        
}, [user.conditions]);

const acceptConditions = async () => {
    // Update User context
    const updatedUser = await client.graphql({
        query: /* GraphQL */ `
        mutation UpdateUser($input: UpdateUserInput!) {
          updateUser(input: $input) {
            id
            conditions {
              id
              accepted
              acceptedAt
            }
          }
        }
      `,
        variables: {
            input: {
                id: user.id,
                conditions: [
                    ...(user.conditions || []),
                    { id: "communication", accepted: true, acceptedAt: new Date().toISOString() }
                ]
            }
        }
    });
    // Update local user context
    addUserCondition({id: "communication", accepted: true, acceptedAt: new Date().toISOString()});
    setConditionsModal(false);
}




    return (
<>
<MDBCard className="w-auto mx-auto" style={{maxWidth: "1200px"}}>
    <MDBCardBody className="flex-column">
            <MDBRow>
                <MDBCol md="6">
                <MDBInput type="text" onChange={e => setSearchTerm(e.target.value)} label={t("Search")} />
                </MDBCol>
                <MDBCol md="6">
                    <div className="d-flex justify-content-center">
                    <MDBCheckbox
                    label="23.04.2024"
                    inline
                    checked={selectedDates.includes("2024-04-23")}
                    onChange={() => selectedDates.includes("2024-04-23") ? setSelectedDates(selectedDates.filter(date => date !== "2024-04-23")) : setSelectedDates([...selectedDates, "2024-04-23"]) }
                    />
                    <MDBCheckbox
                    label="24.04.2024"
                    inline
                    checked={selectedDates.includes("2024-04-24")}
                    onChange={() => selectedDates.includes("2024-04-24") ? setSelectedDates(selectedDates.filter(date => date !== "2024-04-24")) : setSelectedDates([...selectedDates, "2024-04-24"]) }
                    />
                    <MDBCheckbox
                    label="25.04.2024"
                    inline
                    checked={selectedDates.includes("2024-04-25")}
                    onChange={() => selectedDates.includes("2024-04-25") ? setSelectedDates(selectedDates.filter(date => date !== "2024-04-25")) : setSelectedDates([...selectedDates, "2024-04-25"]) }
                    />
                    </div>
               
                </MDBCol>
            </MDBRow>
            
            
         
            <div className="d-flex justify-content-between mt-3">
            <MDBChip key="actions" className="me-2 mb-2" style={{backgroundColor: !filterTopicGroup ? "#C8E6C9" : ""}} onClick={() => setFilterTopicGroup(null)} >
                {t("All")}
                </MDBChip>
            {topicGroups.sort((a, b) => a.sortKey - b.sortKey).map((topicGroup, index) => (
                    <MDBChip key={index} className="me-2 mb-2" style={{backgroundColor: filterTopicGroup === topicGroup.id ? "#C8E6C9" : ""}} onClick={() => setFilterTopicGroup(topicGroup.id)} >
                    {topicGroup.name.find((name) => name.language === i18n.language)?.text || topicGroup.name[0].text}
                    </MDBChip>
                ))}
                
            </div>
    </MDBCardBody>
</MDBCard>
        
        
<MDBRow className="mt-4">
{ isLoading ? <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>
    : shownLectures.length > 0 ?
        shownLectures.map((lecture, index) => {
            return (
                <MDBCol size="6" md="4" lg="3" className="mb-4">
                    <MDBCard className="h-100">
                    <Link to={`/insight/lecture-video/${lecture.id}`}>
                        <MDBCardImage src={
                            lecture.image?.fileName ?
                                `https://iec-cp-public.s3.eu-central-1.amazonaws.com/lectureImages/${lecture.image.fileName}`
                                :
                                "https://mdbootstrap.com/img/new/standard/nature/184.jpg"
                        } 
                        position='top'
                        style={{height: "200px", objectFit: "cover"}}
                         />
                        <MDBCardBody className="text-body py-3">
                            <div style={{height: "35px"}}>
                            <MDBCardTitle style={{fontSize: "17px"}}>{lecture.title?.find(t => t.language === i18n.language)?.text || lecture.title?.[0]?.text}</MDBCardTitle>
                            </div>
                            
                            {/* <MDBCardText>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                            </MDBCardText> */}
                            <hr></hr>
                            <>
                            {lecture.company?.image?.main?.fileName ?
                                    <div className='d-flex align-items-center'>
                                        {lecture.company.image?.main?.fileName ?
                                            <img
                                                src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+lecture.company.image.main.fileName}
                                                className="rounded-circle border"
                                                style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                                alt={lecture.company.image.alt}
                                            />
                                        :
                                            <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                                    
                                        }
                                        <span className='fw-bold ms-3'>{lecture.company.name}</span>
                                    </div>
                                    :
                                    <MDBIcon
                                        className="rounded-circle border"
                                        style={{
                                            height: "50px",
                                            width: "50px",
                                            textAlign: "center",
                                            paddingTop: "12px",
                                            fontSize: "20px",
                                        }}
                                        fas
                                        icon="user-alt"
                                    />
                                }
                            </>
                            {/* <MDBBtn href='#'>Button</MDBBtn> */}
                        </MDBCardBody>
                        </Link>
                    </MDBCard>
                    
                </MDBCol>
                // <tr key={index}>
                //     <td>{lecture.startTime ? <>
                //         {isoToGermanDate(lecture.startTime)}<br/></br>
                //         {lecture.startTime.substring(11, 16)} - {lecture.endTime.substring(11, 16)}<br/></br>
                //         {lecture.room ? t("Room") + ": " + lecture.room : t("not yet determined")}
                //     </> : t("not yet determined")}</td>
                //     <td width="300"><>
                //         {lecture.company?.image?.main?.fileName ?
                //             <div className='d-flex align-items-center'>
                //                 {lecture.company.image?.main?.fileName ?
                //                     <img
                //                         src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+lecture.company.image.main.fileName}
                //                         className="rounded-circle border"
                //                         style={{ height: "50px", width: "50px", objectFit: "contain" }}
                //                         alt={lecture.company.image.alt}
                //                     />
                //                 :
                //                     <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                            
                //                 }
                //                 <div className='ms-3'>
                //                 <p className='fw-bold mb-1'>{lecture.company.name}</p>
                //                 <p className='text-muted mb-0'>{lecture.companyBooth}</p>
                //                 </div>
                //             </div>
                //             :
                //             <MDBIcon
                //                 className="rounded-circle border"
                //                 style={{
                //                     height: "50px",
                //                     width: "50px",
                //                     textAlign: "center",
                //                     paddingTop: "12px",
                //                     fontSize: "20px",
                //                 }}
                //                 fas
                //                 icon="user-alt"
                //             />
                //         }
                //     </>
                //     </td>
                //     <td>{t(lecture.type)}</td>
                //     <td>{lecture.title?.find(t => t.language === i18n.language)?.text || lecture.title?.[0]?.text}</td>
                //     <td>{lecture.speakers?.items?.map((speaker, index) => <>
                //         {speaker?.employee?.image?.fileName ?
                //             <img
                //                 key={index}
                //                 src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.employee.image.fileName}`}
                //                 className="rounded-circle border"
                //                 style={{
                //                     height: "50px",
                //                     width: "50px",
                //                     objectFit: "cover",
                //                 }}
                //                 alt={speaker.employee?.image.alt}
                //             />
                //             :
                //             <MDBIcon
                //             key={index}
                //                 className="rounded-circle border"
                //                 style={{
                //                     height: "50px",
                //                     width: "50px",
                //                     textAlign: "center",
                //                     paddingTop: "12px",
                //                     fontSize: "20px",
                //                 }}
                //                 fas
                //                 icon="user-alt"
                //             />
                //         }
                //         <br/></br>
                //     </>)}</td>
                
                //     <td>
                //     {
                //         lecture?.pdfDe?.fileName && 
                //         <div className="text-center" style={{maxWidth: "200px"}}>
                //         <MDBBtn color="link" onClick={() => downloadFile(lecture.pdfDe.fileName, lecture.pdfDe.s3Path)} size="sm" className="mb-0">
                //             {lecture.pdfDe.fileName}
                //         </MDBBtn>
                //         </div>
                        
                //         }
                //         {
                //         lecture?.pdfEn?.fileName &&
                //         <div className="text-center" style={{maxWidth: "200px"}}>
                //         <MDBBtn color="link" onClick={() => downloadFile(lecture.pdfEn.fileName, lecture.pdfEn.s3Path)} size="sm" className="mb-0">
                //             {lecture.pdfEn.fileName}
                //         </MDBBtn>
                //         </div>
                //         }
                //     </td>

                // </tr>
            )
        })
        :
        <tr>
            <td colSpan="7">
                {t("No lectures found")}
            </td>
        </tr>
    }
</MDBRow>
{/* ConditionsModal */}
<MDBModal open={conditionsModal} onClose={() => setConditionsModal(false)} staticBackdrop>
    <MDBModalDialog centered size="xl">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>Ihr Zugang zu den Vortragvideos - Wir benötigen Ihre Zustimmung.</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
<p>Einiges ist <span className="fw-bold">NEU</span> für Sie, wir fassen es nachfolgend kurz zusammen:</p>
<ul>
  <li>Tauschen Sie sich aus und kommunizieren Sie mit anderen Personen, die sich für das gleiche Thema interessieren wie Sie. Nutzen Sie die vielen und unkomplizierten Möglichkeiten, die dadurch entstehen.</li>
  <li>Wir erstellen eine "Teilnehmerliste" separat zu jedem Vortrag.</li>
  <li>Sobald Sie einen Vortrag mindestens 3 Minuten lang angeschaut haben, zeigen wir Ihren Titel, Vorname, Namen und das Unternehmen dem Sie angehören, keine Kontaktdaten. Die Teilnehmerliste sehen Sie, die anderen teilnehmenden Personen, die Referent*innen und die Admins der Unternehmen, zu welchem die Referent*innen gehören.</li>
</ul>

<p><span className="fw-bold">Ab dem 25. Mai 2025</span> - Neue Möglichkeiten der Kommunikation zwischen Ihnen und Referent*innen sowie den Admins der Unternehmen, zu welchem die Referent*innen gehören:</p>

<ul>
  <li>Sie können Referent*innen anschreiben und sich austauschen.</li>
  <li>Sie können die Admins der Unternehmen zu denen die Referent*innen gehören anschreiben und sich austauschen.</li>
  <li>Die Referent*innen und Admins können Ihnen eine Kommunikations-Anfrage stellen, die Sie annehmen oder ablehnen können.</li>
  <li>Eine direkte Nachricht an Sie, durch Referent*innen oder Admins des jeweiligen Unternehmens, ist nicht möglich.</li>
</ul>

<p><span className="fw-bold">Ab dem 25. Mai 2025</span> - Neue Möglichkeiten der Kommunikation zwischen Ihnen und anderen teilnehmenden Personen:</p>

<ul>
  <li>Sie können andere teilnehmende Personen anschreiben und sich mit diesen austauschen.</li>
  <li>Zunächst erfolgt eine Kommunikations-Anfrage, die angenommen oder abgelehnt werden kann.</li>
  <li>Nach einer Annahme der Kommunikations-Anfrage ist ein uneingeschränkter Austausch möglich.</li>
</ul>

<p><span className="fw-bold">Datenschutz:</span> Sie entscheiden selbst - Ihre Daten sind bei uns sicher</p>

<ul>
  <li>Sie können Ihren Kommunikationspartner*innen Ihre kompletten Daten freigeben, wenn Sie möchten.</li>
  <li>Wenn Sie Ihre Daten freigeben, dann sehen Ihre Kommunikationspartner*innen Anrede, Titel, Vorname, Name, Unternehmen und Adresse, Telefonnummer, E-Mail-Adresse.</li>
  <li>Wenn Sie Ihre Daten nicht freigeben, dann zeigen wir, wie in der Teilnehmerliste, lediglich Ihren Titel, Vorname, Namen und das Unternehmen.</li>
  <li>Sie müssen keine Daten freigeben, um kommunizieren zu können.</li>
  <li>Sie müssen mit niemandem kommunizieren, wenn Sie dies nicht möchten.</li>
  <li>Andere Personen können Sie nicht ungefragt mit Informationen belästigen, da Sie selbst entscheiden, ob Sie Anfragen annehmen oder ablehnen möchten.</li>
  <li>Massen-Benachrichtigungen sind nicht möglich.</li>
</ul>
<p><span className="fw-bold">Rechtlicher Hinweis:</span> Jegliche unerlaubte Vervielfältigung oder Weiterverbreitung des Ton-und Bildmaterials ist untersagt und wird bei Verstoß rechtlich verfolgt werden. Die Rechte an jeglichem gezeigten Ton-und Bildmaterial liegen ausschließlich bei der Inspire GmbH.</p>

<p>Für Fragen wenden Sie sich bitte an: <a href="mailto:support@cleanroom-processes.de">support@cleanroom-processes.de</a></p>
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="secondary"
                onClick={() => navigate("/")}
                >
                    Abbrechen (Zurück zu Startseite)</MDBBtn>
                <MDBBtn color="success" onClick={acceptConditions}>Zustimmen</MDBBtn>
            </MDBModalFooter>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
{/* BuyModal - Tell user that the can see the lectures but has no access to the videos. show button to buy access link */}
<MDBModal open={buyModal} onClose={() => setBuyModal(false)} nonInvasive={true}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("No access to the lecture videos")}</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <div className="text-center">
                    <h5>{t("You do not have access to the lecture videos")}</h5>
                    <p>{t("To gain access to the lecture videos, you can upgrade your ticket to a Premium Ticket or purchase CP-Insight")}</p>
                    <p>{t("You can view the lectures, but you do not have access to the videos.")}
                    </p>
                    <MDBBtn color="success" onClick={() => navigate("/bookings")}>{t("Purchase access")}</MDBBtn>
                    <MDBBtn className="ms-2" color='primary' rippleColor='light' onClick={() => setBuyModal(false)}>{t("Discover Lectures")}</MDBBtn>
                </div>
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
    )
}
