import React, { useState, useEffect, useCallback } from "react";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBChip, MDBTextArea,MDBSelect, MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtnGroup,
    MDBCardText,  } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';

import AddEmployees from "./AddEmployees";
import EditEmployeeProfile from "../../components-user/employee-profile/EditEmployeeProfile";
import RemoveEmployee from "./RemoveEmployee";
import CancelInvitation from "./CancelInvitation";
import ResendInvitation from "./ResendInvitation";
import { Link } from "react-router-dom";
import BookTicket from "../../pages-user/event-management/tickets/BookTicket";
import BookTicketEmployee from "./BookTicketEmployee";
import { isoToGermanDate } from "../../utils/dateTools";
import { deleteTicket } from "../../graphql/mutations";
import ShowTicket from "../../components-user/tickets/ShowTicket";
const client = generateClient();
export default function Persons({ company, handleChange, loading, presetCompanyId, isOnboarding, adminActions }) {
    const { t, i18n } = useTranslation();

    const companyId = presetCompanyId || (window.location.pathname.includes("mycompany") ? window.location.pathname.split("/")[2] : window.location.pathname.split("/")[3]);
    // Load Employees
    const [employees, setEmployees] = useState([]);
    const [admins, setAdmins] = useState(null);

    // Get employees with company id
    const getEmployees = async () => {
        try {
            console.log("company", companyId)
            const employees = await client.graphql({
                variables: { filter: { companyEmployeesId: { eq: companyId } }, limit: 5000 },
                query: /* GraphQL */ `
                query SearchEmployees(
                    $filter: SearchableEmployeeFilterInput
                    $sort: [SearchableEmployeeSortInput]
                    $limit: Int
                    $nextToken: String
                    $from: Int
                    $aggregates: [SearchableEmployeeAggregationInput]
                  ) {
                    searchEmployees(
                      filter: $filter
                      sort: $sort
                      limit: $limit
                      nextToken: $nextToken
                      from: $from
                      aggregates: $aggregates
                    ) {
                        nextToken
                        items {
                            id
                            user {
                                    email
                                    lastName
                                    middleName
                                    title
                                    salutation
                                    image {
                                    fileName
                                    alt
                                    }
                                    id
                                    firstName
                                    personalDepartment
                                    personalIndustry
                                    contact {
                                        address {
                                            company
                                            street
                                            streetNumber
                                            zip
                                            city
                                            countryCode
                                        }
                                    }
                                    tickets {
                                        items {
                                            id
                                            eventDays
                                            type
                                            createdAt
                                          event {
                                            id
                                            name {
                                              language
                                              text
                                            }
                                          }
                                          personalAddress {
                                            firstName
                                            lastName
                                          }
                                        }
                                      }
                            }
                            department {
                                items {
                                    department {
                                        name {
                                            language
                                            text
                                        }
                                    }
                                }
                            }
                            image {
                                alt
                                fileName
                              }
                            role
                            status
                            companyEmployeesId
                            contact {
                                address {
                                    company
                                    street
                                    streetNumber
                                    zip
                                    city
                                    countryCode
                                }
                            }

                          }
                    }
                }
                `
            });
            //console.log("employees", employees)
            let employeesArray = employees.data.searchEmployees.items;
            // find employee with role admin and remove from array and store in variable
            let admins = employeesArray.filter(employee => employee.role === "admin");
            console.log("admin", admins)
            // remove admin from employees array
            employeesArray = employeesArray.sort((a, b) => a.role === "admin" ? -1 : 1)
            // add admin to the beginning of the array
            setAdmins(admins);
            setEmployees(employeesArray);
            console.log("employees", employees.data.searchEmployees.items)
        } catch (err) {
            console.log("error fetching employees", err);
        }
    };

   

    useEffect(() => {
        if (companyId) {
            getEmployees();

        }
    }, [company]);

    // Change Roles and Departments


                        

    const handleChangeRole = async (employeeId, newRole) => {
        // Check if previous role was admin and changes to another role or if previous role was not admin and changes to admin
        let companyAdminEmployeesId = null;
        if (employees.find(employee => employee.id === employeeId).role === "admin" && newRole !== "admin") {
            if (admins.length < 2) {
                alert("You need at least one admin in your company.");
                return;
            }
           
        } else if (employees.find(employee => employee.id === employeeId).role !== "admin" && newRole === "admin") {
            companyAdminEmployeesId = companyId;
        } else {
            
        }
        // Change role
        try {
            const data = await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateEmployee($input: UpdateEmployeeInput!) {
                    updateEmployee(input: $input) {
                        id
                        role
                        companyAdminEmployeesId
                    }
                }
                `,
                variables: {
                    input: {
                        id: employeeId,
                        role: newRole,
                        companyAdminEmployeesId
                    }
                }
            });
            //console.log("updateEmployeeRole", data);
            setEmployees(old => old.map(employee => employee.id === employeeId ? { ...employee, role: newRole, companyAdminEmployeesId } : employee));
            setAdmins(old => old.map(employee => employee.id === employeeId ? { ...employee, role: newRole, companyAdminEmployeesId } : employee));
        } catch (err) {
            console.log("error updating employee role", err);
        }
    };



    // Add employee modal
    const [addEmployeesModal, setAddEmployeesModal] = useState(false);
    const toggleAddEmployeesModal = () => {
        console.log("toggleAddEmployeesModal")
        setAddEmployeesModal(!addEmployeesModal);
    }

    // Edit employee modal
    const [editEmployeeModal, setEditEmployeeModal] = useState(false);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
    const toggleEditEmployeeModal = () => setEditEmployeeModal(!editEmployeeModal);

    const handleClickEditEmployee = (employeeId) => {
        setSelectedEmployeeId(employeeId);
        setEditEmployeeModal(true);
    }

    // Remove employee modal
    const [removeEmployeeModal, setRemoveEmployeeModal] = useState(false);
    const [selectedEmployeeRemove, setSelectedEmployeeRemove] = useState(null);
    const toggleRemoveEmployeeModal = () => setRemoveEmployeeModal(!removeEmployeeModal);

    const handleClickRemoveEmployee = (employeeId) => {
        setSelectedEmployeeRemove(employeeId);
        setRemoveEmployeeModal(true);
    }

    // Cancel invitation modal
    const [cancelInvitationModal, setCancelInvitationModal] = useState(false);
    const [selectedInvitationId, setSelectedInvitationId] = useState(null);
    const toggleCancelInvitationModal = () => setCancelInvitationModal(!cancelInvitationModal);

    const handleClickCancelInvitation = (invitationId) => {
        setSelectedInvitationId(invitationId);
        setCancelInvitationModal(true);
    }

    // Resend invitation modal
    const [resendInvitationModal, setResendInvitationModal] = useState(false);
    const [selectedInvitationResend, setSelectedInvitationResend] = useState(null);
    const toggleResendInvitationModal = () => setResendInvitationModal(!resendInvitationModal);

    const handleClickResendInvitation = (invitationId) => {
        setSelectedInvitationResend(invitationId);
        setResendInvitationModal(true);
    }

    // Book ticket modal
    const [bookTicketModal, setBookTicketModal] = useState(false);
    const toggleBookTicketModal = () => setBookTicketModal(!bookTicketModal);
    const [selectedEmployeeForTicket, setSelectedEmployeeForTicket] = useState(null);

    const handleClickBookTicket = (employee) => {
        setSelectedEmployeeForTicket(employee);
        setBookTicketModal(true);
    }

    // View ticket modal
    const [viewTicketModal, setViewTicketModal] = useState(false);
    const toggleViewTicketModal = () => setViewTicketModal(!viewTicketModal);
    const [selectedTicket, setSelectedTicket] = useState(null);

    const handleClickViewTicket = (ticket) => {
        setSelectedTicket(ticket);
        setViewTicketModal(true);
    }

    const handleDeleteTicket = (id) => {
        setViewTicketModal(false);
        setEmployees(employees.map(employee => {
            employee.user.tickets.items = employee.user.tickets.items.filter(ticket => ticket.id !== id);
            return employee;
        }))
    }





    return (
<>

<MDBCard>
    <MDBCardBody>
    <MDBCardTitle>{t("Persons")} <MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light' onClick={() => toggleAddEmployeesModal()}>{t("Add Person")}</MDBBtn></MDBCardTitle>
        <MDBTable responsive align='middle'>
            <MDBTableHead className="text-center">
                <tr>
                    <th>{t("Person")}</th>
                    {/* <th>{t("Department")}</th> */}
                    <th>{t("Role")}</th>
                    <th>{t("Status")}</th>
                    <th>{t("Tickets")}</th>
                    <th width="100px">{t("Actions")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody className="text-center">
                {employees.filter(e => e.status !== "pending").map((employee, index) => {
                    if(!employee) return null;
                    const user = employee.user;
                    if(!user) console.log("no user", employee)
                    const departmentName = employee.department?.items?.map(department => department?.department?.name.find(text => text.language === i18n.language)?.text || department?.department?.name[0].text || "");
                    //console.log("departmentName", departmentName)
                    return (
                    <tr key={index}>
                        <td>
                            <div className='d-flex align-items-center text-start'>
                            {employee.image?.fileName || employee.user?.image?.fileName ?
                                        <img
                                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+ (employee.image?.fileName ||employee.user.image.fileName)}
                                            className="rounded-circle border"
                                            style={{ height: "50px", width: "50px", objectFit: "cover" }}
                                            alt={employee.image?.alt || employee.user.image.alt}
                                        />
                                        :
                                        <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                                        
                            }
                            <div className='ms-3'>
                            <p className='fw-bold mb-1'>{user.title ? user.title +" " : ""}{user.firstName} {user.middleName} {user.lastName}</p>
                            <p className='text-muted mb-0'>{user.email}</p>
                            </div>
                            </div>
                        </td>
                        
                        <td>
                        {/* {employee.role === "admin" ? t("Administrator") :employee.role === "employee" ? t("Employee") : employee.role} */}
                            <MDBSelect
                            onChange={ input => input?.value && input?.value !== employee.role && handleChangeRole(employee.id, input.value)}
                            value={employee.role}
                                data={[
                                    { text: t("Role"), value: "", disabled: true },
                                    { text: 'Admin', value: "admin" },
                                    { text: 'Moderator', value: "moderator" },
                                    { text: 'Autor', value: "writer" },
                                    { text: 'Mitarbeiter', value: "employee" },
                                ]}
                            />
                        </td>
                        <td>{employee.status === "active" ? <MDBChip className="bg-success text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Active")}</MDBChip> : employee.status === "deactivated" ? <MDBChip>{t("Deactivated")}</MDBChip> : employee.status}</td>
                        <td>
                        {employee.user?.tickets?.items?.length > 0 && employee.user?.tickets?.items.map((ticket, index) => 
                            <MDBChip key={index} className="bg-primary text-white bg-opacity-75 mx-auto my-0" style={{maxWidth: "fit-content"}} onClick={() => handleClickViewTicket(ticket)}>{ticket.event.name.find(name => name.language === i18n.language)?.text || ticket.event.name[0]?.text}</MDBChip>
                        )}
                        <MDBBtn color='link' onClick={() => handleClickBookTicket(employee)}>{t("Add Ticket")}</MDBBtn>
                        </td>
                        <td className="px-0 py-3">
                        {employee.role === "admin" && isOnboarding ? 
                        <span>{t("You can adjust your data in the next step.")}</span>
                         : 
                            <MDBBtnGroup shadow='0' aria-label='Actions'>
                            {adminActions ?
                                <Link to={"/admin/user/" + employee.user.id}>
                                <MDBBtn color='link'>{t("Edit")}</MDBBtn>
                                </Link>
                                :
                                <MDBBtn color='link' onClick={() => handleClickEditEmployee(employee.id)}>{t("Edit")}</MDBBtn>
                                }
                            
                            <MDBBtn color='link' style={{color: "red"}} onClick={() => handleClickRemoveEmployee(employee)}>{t("Remove")}</MDBBtn>

                            </MDBBtnGroup>
                        }
                        </td>
                    </tr>
                )
                })}

            </MDBTableBody>
            
        </MDBTable>
    </MDBCardBody>
</MDBCard>
{
!isOnboarding &&
<MDBCard className="mt-3">
    <MDBCardBody>
    <MDBCardTitle>{t("Invited Persons")} </MDBCardTitle>
        <MDBTable responsive>
            <MDBTableHead className="text-center">
                <tr>
                    <th>{t("Person")}</th>
                    {/* <th>{t("Department")}</th> */}
                    <th>{t("Role")}</th>
                    <th>{t("Status")}</th>
                    <th>{t("Tickets")}</th>
                    <th>{t("Actions")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody className="text-center">
                {employees.filter(e => e.status === "pending").map((employee, index) => {
                    const departmentName = employee.department?.items?.map(department => department?.department?.name.find(text => text.language === i18n.language)?.text || department?.department?.name[0].text || "");
                    return (
                    <tr key={index}>
                        <td>
                            <div className='d-flex align-items-center text-start'>
                            {employee.image?.fileName || employee.user?.image?.fileName ?
                                        <img
                                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+ (employee.image?.fileName ||employee.user.image.fileName)}
                                            className="rounded-circle border"
                                            style={{ height: "50px", width: "50px", objectFit: "cover" }}
                                            alt={employee.image?.alt || employee.user.image.alt}
                                        />
                                        :
                                        <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                                        
                            }
                            <div className='ms-3'>
                            <p className='fw-bold mb-1'>{employee.user.title ? employee.user.title +" " : ""}{employee.user?.firstName} {employee.user?.middleName} {employee.user?.lastName}</p>
                            <p className='text-muted mb-0'>{employee.user?.email}</p>
                            </div>
                            </div>
                        </td>
                       
                        <td>
                        
                            <MDBSelect
                            value={employee.role}
                            onChange={ input => input?.value && input?.value !== employee.role && handleChangeRole(employee.id, input.value)}
                                data={[
                                    { text: t("Role"), value: "", disabled: true },
                                    { text: 'Admin', value: "admin" },
                                    { text: 'Moderator', value: "moderator" },
                                    { text: 'Autor', value: "writer" },
                                    { text: 'Mitarbeiter', value: "employee" },
                                ]}
                            />
                        </td>
                        <td>{employee.status === "pending" ? <MDBChip className="bg-warning text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Pending")}</MDBChip> : employee.status === "accepted" ? <MDBChip className="bg-success text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Accepted")}</MDBChip> : employee.status === "declined" ? <MDBChip className="bg-danger text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Declined")}</MDBChip> : employee.status}
                        </td>
                        <td>
                        {employee.user?.tickets?.items?.length > 0 && employee.user?.tickets?.items.map((ticket, index) => 
                            <MDBChip key={index} className="bg-primary text-white bg-opacity-75 mx-auto" style={{maxWidth: "fit-content"}} onClick={() => handleClickViewTicket(ticket)}>{ticket.event.name.find(name => name.language === i18n.language)?.text || ticket.event.name[0]?.text}</MDBChip>
                        )}
                        <MDBBtn color='link' onClick={() => handleClickBookTicket(employee)}>{t("Add Ticket")}</MDBBtn>
                        </td>
                        <td>
                        {adminActions ?
                                <Link to={"/admin/user/" + employee.user.id}>
                                <MDBBtn color='link'>{t("Edit")}</MDBBtn>
                                </Link>
                                :
                                <MDBBtn color='link' onClick={() => handleClickEditEmployee(employee.id)}>{t("Edit")}</MDBBtn>
                                }
                        <MDBBtnGroup shadow='0' aria-label='Actions'>
                       
                        <MDBBtn color='link' size="sm"
                        onClick={() => handleClickResendInvitation(employee.id)}
                        >{t("Resend invitation")}</MDBBtn>
                        <MDBBtn color='link' size="sm" style={{color: "red"}}
                        onClick={() => handleClickCancelInvitation(employee.id)}
                        >{t("Cancel Request")}</MDBBtn>
                        </MDBBtnGroup>
                        </td>
                        
                    </tr>
                )
                })}
            </MDBTableBody>
        </MDBTable>
    </MDBCardBody>
</MDBCard>
}

<MDBModal open={addEmployeesModal} onClose={() => setAddEmployeesModal(false)} staticBackdrop>
    <MDBModalDialog centered size="md">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Add employee")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleAddEmployeesModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
        <AddEmployees companyId={companyId} isActive={addEmployeesModal} employeeId={selectedEmployeeId} reloadEmployees={() => getEmployees()} />
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>


<MDBModal open={editEmployeeModal} onClose={() => setEditEmployeeModal(false)} staticBackdrop>
    <MDBModalDialog centered size="xl">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Edit employee profile")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleEditEmployeeModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
        <EditEmployeeProfile companyId={companyId} isActive={editEmployeeModal} employeeId={selectedEmployeeId} toggle={toggleEditEmployeeModal} />
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={removeEmployeeModal} onClose={() => setRemoveEmployeeModal(false)} staticBackdrop>
    <MDBModalDialog centered size="sm">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Remove employee")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleRemoveEmployeeModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
        <RemoveEmployee companyId={companyId} isActive={removeEmployeeModal} toggleModal={toggleRemoveEmployeeModal} employee={selectedEmployeeRemove} />
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={cancelInvitationModal} onClose={() => setCancelInvitationModal(false)} staticBackdrop>
    <MDBModalDialog centered size="sm">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Cancel invitation")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleCancelInvitationModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
        <CancelInvitation companyId={companyId} isActive={cancelInvitationModal} invitationId={selectedInvitationId} />
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={resendInvitationModal} onClose={() => setResendInvitationModal(false)} staticBackdrop>
    <MDBModalDialog centered size="sm">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Resend invitation")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleResendInvitationModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
        <ResendInvitation companyId={companyId} isActive={resendInvitationModal} invitationId={selectedInvitationResend} />
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={bookTicketModal} onClose={() => setBookTicketModal(false)} staticBackdrop>
    <MDBModalDialog centered size="xl" scrollable >
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Book ticket")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleBookTicketModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
        <BookTicketEmployee isOpen={bookTicketModal} employee={selectedEmployeeForTicket} companyId={companyId} handleTicketBooked={() => getEmployees()} toggleBookTicketModal={toggleBookTicketModal} />
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={viewTicketModal} onClose={() => setViewTicketModal(false)} position="top">
    <MDBModalDialog>
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Your ticket")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleViewTicketModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
           <ShowTicket ticket={selectedTicket} handleDeleteTicket={handleDeleteTicket} />
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
  )
}
